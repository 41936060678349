import styled from "styled-components";
import { Pagination } from "antd";
import { flexbox } from "styles/mixins";

export const Wrapper = styled.div`
  ${flexbox("center", "center")};
  width: 100%;
  padding: 40px 0 0 0;
`;
export const StyledPagination = styled(Pagination)`
  .ant-pagination-item:hover {
    border-color: ${(props) => props.theme.borderActive};
  }

  .ant-pagination-item:hover a {
    color: ${(props) => props.theme.black};
  }

  .ant-pagination-item-active {
    border-color: ${(props) => props.theme.black};
  }
  .ant-pagination-item-active a {
    color: ${(props) => props.theme.black};
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${(props) => props.theme.black};
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${(props) => props.theme.black};
    border-color: ${(props) => props.theme.black};
  }
`;
