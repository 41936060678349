import styled from "styled-components";
import { StyledSelect } from "components/common/Select/styles";

export const CustomSelect = styled(StyledSelect)`
  width: 120px;
  height: 36px;
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
  }
`;
