import GNB from "layouts/GNB";
import Nav from "layouts/Nav";
import { Container, Main } from "./styles";

const Layout = ({ defaultMenu, defaultOpenKeys, children }) => {
  return (
    <Container>
      {/*<GNB />*/}
      <Nav defaultMenu={defaultMenu} defaultOpenKeys={defaultOpenKeys} />
      <Main>{children}</Main>
    </Container>
  );
};

export default Layout;
