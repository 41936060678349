import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";
import { iconBtnOrangeBase } from "styles/mixins";

const Button = styled.button`
  ${iconBtnOrangeBase};
`;

const DeleteBtn = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <DeleteOutlined style={{ fontSize: "14px" }} />
    </Button>
  );
};

export default DeleteBtn;
