import { StyledBreadcrumb } from "./styles";
import { Breadcrumb } from "antd";
const CustomBreadcrumb = ({ item }) => {
  return (
    <StyledBreadcrumb separator=">">
      {item.map((item, i) => (
        <Breadcrumb.Item key={i}>{item.name}</Breadcrumb.Item>
      ))}
    </StyledBreadcrumb>
  );
};

export default CustomBreadcrumb;
