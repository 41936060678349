import styled from "styled-components";
import { Select } from "antd";
import { flexbox, fontStyle13 } from "styles/mixins";

export const StyledSelect = styled(Select)`
  ${flexbox("center", "center")};
  color: ${(props) => props.theme.black};
  height: 42px;
  width: 320px;
  border-radius: 4px;
  ${fontStyle13};
  text-align: left;
  color: ${(props) => props.theme.black};

  .ant-select-selection-placeholder {
    font-weight: 500;
    ${fontStyle13};
    color: ${(props) => props.theme.placeholder};
  }

  .ant-select-selection-item {
    font-weight: 500;
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    ${flexbox("flex-start", "center")};
    height: 42px;
    padding: 0 11px 0 18px;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 4px;
    transition: border-color 200ms ease-in-out;
  }

  &:not(.ant-select-customize-input) .ant-select-selector:hover,
  &:not(.ant-select-customize-input) .ant-select-selector:active,
  &:not(.ant-select-customize-input) .ant-select-selector:focus {
    border: 1px solid ${(props) => props.theme.borderActive};
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${(props) => props.theme.borderActive};
    box-shadow: unset;
  }
`;
