import { atom, selector } from "recoil";

const initialState = {
  id: "",
  // email:'',
  // image:'',
  // type:'',
  // name:'',
  // nickname:'',
  // phone:'',
  // projectTotal:'',
  // createdAt:'',
  // intro:'',
  // projects:[],
};

export const recoilExpertState = atom({
  key: "recoilExpertState",
  default: initialState,
});

export const recoilExpertSelector = selector({
  key: "recoilExpertSelector",
  get: ({ get }) => {
    const id = get(recoilExpertState);
    return id;
  },
});
