// 벤치마킹 리소스 관리

// 현재 값, 정렬 array 받음 -> 다음 값 리턴

export const getSortingCriteria = (criteria, totalArr) => {
  const currentIndex = totalArr.findIndex((item) => item === criteria);
  if (currentIndex + 1 !== totalArr.length) {
    return totalArr[currentIndex + 1];
  } else {
    return totalArr[0];
  }
};
