import { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { recoilExpertSelector } from "states/recoilExpertState";
import { useRecoilValue } from "recoil";
import moment from "moment";
import Layout from "layouts/Layout";
import CustomBreadcrumb from "components/common/Breadcrumb";
import PeriodPicker from "components/common/PeriodPicker";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import DetailBtn from "components/common/DetailBtn";
import ExcelBtn from "components/common/ExcelBtn";
import Modal from "components/common/Modal";
import DetailModalContent from "components/common/DetailModalContent";
import Pagination from "components/common/Pagination";

import { Heading, SubHeadingWrapper, BtnWrapper, BaseInfoTable, PickerWrapper, CurrentHeadingWrapper, ResultHeadingWrapper } from "./styles";

import { expertDetailSearchOption } from "utils/data";
import { projectSearchDateType, projectSearchOption } from "utils/data";
import SearchBox from "components/common/SearchBox";
import { qsParses } from "utils/queryHandler";
// TODO
// 선택한 프로젝트 아이디로 백에서 데이터 받아와야함
import { projectMatchingMockData, projectMockData } from "utils/mockData";
import MatchingModalContent from "components/expert/MatchingModalContent";
import { ImageTd } from "components/common/SortableTable/SortableRow/styles";
import AlertModalContent from "components/common/AlertModalContent";
import axiosPrivate from "utils/axiosPrivate";

const ExpertDetail = () => {
  // TODO
  // 추후 백엔드에서 아이디 이용하여 전문가 데이터 받아올 것
  // 아래 location을 이용한 코드는 지울 것
  const selectedId = useRecoilValue(recoilExpertSelector);
  const { id: uid } = useParams();
  const navigate = useNavigate();
  const { data: expertData, error: expertDataError } = useSWR(`/api/v1/admin/experts/${uid}`, fetcherPrivate);
  const { search } = useLocation();
  // 현재 페이지 관리
  const [currentPage, setCurrentPage] = useState(1);
  const [resultData, setResultData] = useState([]);
  const role = window.localStorage.getItem("superAdmin");

  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  // 하단 search box
  // 검색 날짜 설정
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // 날짜 변경
  const onChangeDate = (values) => {
    setStartDate(values?.[0]);
    setEndDate(values?.[1]);
    mutate();
  };

  const {
    data: projectData,
    error: projectDataError,
    mutate,
  } = useSWR(`/api/v1/admin/experts/${uid}/projects?page=${currentPage}`, (url) =>
    fetcherPrivate(
      url,
      {
        params:
          startDate && endDate
            ? {
                sDate: new Date(startDate).toISOString().split("T")[0],
                eDate: new Date(endDate).toISOString().split("T")[0],
                page: currentPage,
                size: 10,
                direction: "DESC",
              }
            : { page: currentPage, size: 10, direction: "DESC" },
      },
      { revalidateOnFocus: false }
    )
  );

  console.log(projectData, "프로젝트 리스트");
  useEffect(() => {
    projectData && setResultData(projectData.result.content);
  }, [projectData]);

  useEffect(() => {
    if (search) {
      //mutate();

      const { page } = qsParses(search);

      if (page) setCurrentPage(+page);
    }
  }, [search]);

  // const { email, image, expertType, expertHeadshot, name, nick, phone, projectTotal, joinDate, intro, introduction, projects } = expertData?.result;

  const typeKR = expertData?.result.expertType === "MANAGER" ? "매니저" : expertData?.result.expertType === "DESIGNER" ? "디자이너" : "멘토";

  const breadcrumbItem = [
    {
      name: "전문가 관리",
    },
    {
      name: typeKR,
    },
    {
      name: `${typeKR} 상세`,
    },
  ];

  // ---------------------------------------------

  // 전문가 상세 조회

  // ---------------------------------------------

  // 엑셀 버튼 클릭
  const onClickExcel = () => {
    console.log("excel download clicked!");
  };

  // 프로젝트 매칭 모달
  const [isMatchingModalVisible, setIsMatchingModalVisible] = useState(false);
  const onClickProjectMatching = () => {
    setIsMatchingModalVisible(true);
  };
  const onClickCloseMatchingModal = () => setIsMatchingModalVisible(false);

  // 프로젝트 상세 모달
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const onClickCloseDetailModal = () => setIsDetailModalVisible(false);

  const [selectedProjectId, setSelectedProjectId] = useState("1");

  const onClickDetailBtn = (id) => {
    setIsDetailModalVisible(true);
    // selected project 정보 받아오기
    setSelectedProjectId(id);
  };

  // 검색
  const onSubmitSearch = (e) => {
    e.preventDefault();
    mutate();
  };

  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const onClickDelCancel = () => setIsDeleteModal(false);
  const onClickDelConfirm = async () => {
    try {
      const res = await axiosPrivate.delete(`/api/v1/admin/experts/${uid}`);
      if (res) {
        onClickDelCancel();
        navigate("/expert?page=1&size=20&direction=DESC&expertType=MANAGER");
      }
    } catch (e) {
      console.log(e);
    }
  };
  // 임시 에러 처리
  // if (expertDataError) return expertDataError.response.data.resultMsg;
  return (
    <>
      <Layout defaultMenu={["8"]}>
        <CustomBreadcrumb item={breadcrumbItem} />
        <Heading>{typeKR} 상세</Heading>
        {/* 상단 */}
        <SubHeadingWrapper>
          <h2>기본 정보</h2>
          {role === "true" ? (
            <BtnWrapper>
              <Link to={`/expert/edit/${uid}`}>편집</Link>
              <button type="button" onClick={() => setIsDeleteModal(true)}>
                삭제
              </button>
            </BtnWrapper>
          ) : (
            ""
          )}
        </SubHeadingWrapper>
        {expertData?.result && (
          <BaseInfoTable>
            <tbody>
              <Tr>
                <RowThTd th="이메일">{expertData?.result.email}</RowThTd>
              </Tr>

              <Tr>
                <RowThTd th="사진">
                  <ImageTd>
                    <img src={expertData?.result?.expertHeadshot} />
                  </ImageTd>
                </RowThTd>
                <RowThTd th="전문분야">{typeKR}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="이름">{expertData?.result.name}</RowThTd>
                <RowThTd th="닉네임">{expertData?.result.nick}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="연락처">{expertData?.result.phone}</RowThTd>
                <RowThTd th="등록일">{expertData?.result.joinDate}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="프로젝트 진행">{expertData?.result.participateProjectCount}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="소개글">{expertData?.result.introduction}</RowThTd>
              </Tr>
            </tbody>
          </BaseInfoTable>
        )}

        {/* 하단 */}
        <CurrentHeadingWrapper>
          <h3>프로젝트 현황</h3>
          {role === "true" ? (
            <button type="button" onClick={onClickProjectMatching}>
              프로젝트 매칭
            </button>
          ) : (
            ""
          )}
        </CurrentHeadingWrapper>
        <form onSubmit={onSubmitSearch}>
          <PickerWrapper>
            <PeriodPicker
              title=" "
              selectOptionList={expertDetailSearchOption}
              selectOptionDefault={expertDetailSearchOption[0]}
              startDate={startDate}
              endDate={endDate}
              onChangeDate={onChangeDate}
              isAlone
            />
          </PickerWrapper>
        </form>

        <ResultHeadingWrapper>
          <h4>
            검색 결과 총 <strong>{projectData?.result?.content?.length}</strong>개
          </h4>
          <ExcelBtn onClick={onClickExcel} />
        </ResultHeadingWrapper>
        <Table>
          <Thead>
            <tr>
              <th>프로젝트명</th>
              <th style={{ width: "100px" }}>상태</th>
              <th style={{ width: "80px" }}>버전</th>
              <th style={{ width: "120px" }}>회원명</th>
              <th>기업명</th>
              <th>기관명</th>
              <th>시작일/종료일</th>
            </tr>
          </Thead>
          <Tbody>
            {resultData &&
              resultData.map((item, i) => (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>
                    <div>{item.projectStatusType == "STOP" ? "진행 중단" : item.projectStatusType == "COMPLETION" ? "진행 완료" : "진행 중"}</div>
                  </td>
                  <td>
                    <div>{item.version}</div>
                  </td>
                  <td>{item.memberName}</td>
                  <td>{item.companyName}</td>
                  <td>{item.authCodeCompanyName}</td>
                  <td className="with-btn">
                    <div>
                      <div>
                        <p>{item.projectStartDate}</p>
                        <p>{item.projectEndDate}</p>
                      </div>
                      <DetailBtn onClick={() => onClickDetailBtn(item.id)} />
                    </div>
                  </td>
                </tr>
              ))}
          </Tbody>
        </Table>
        {projectData?.result ? <Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={projectData?.result?.totalElements} pageSize={10} /> : null}
      </Layout>
      <Modal isVisible={isDeleteModal}>
        <AlertModalContent onClickCancel={onClickDelCancel} onClickSave={onClickDelConfirm} confrimText="삭제">
          <h2>정말 삭제하시겠습니까?</h2>
          <p>그래도 삭제하시겠습니까?</p>
        </AlertModalContent>
      </Modal>
      {isMatchingModalVisible && (
        <Modal isVisible={isMatchingModalVisible}>
          <MatchingModalContent data={projectMatchingMockData} onClick={onClickCloseMatchingModal} />
        </Modal>
      )}
      {/* TODO - projectMockData가 아니라 백엔드 데이터로 수정 */}
      {projectMockData && isDetailModalVisible && (
        <Modal isVisible={isDetailModalVisible}>
          <DetailModalContent id={selectedProjectId} data={projectMockData} onClick={onClickCloseDetailModal} />
        </Modal>
      )}
    </>
  );
};

export default ExpertDetail;
