import { useState, useEffect } from "react";
import Layout from "layouts/Layout";
import TabMenu from "components/common/TabMenu";
import TemplateTab from "components/resource/template/TemplateTab";
import { PageHeading } from "./styles";

// TODO : delete
import { templateMockData } from "utils/mockData";

const Template = () => {
  const items = [
    {
      label: "비즈니스 모델",
      key: "model",
      children: <TemplateTab />,
    },
    {
      label: "앱 화면 유형",
      key: "app",
      disabled: true,
      children: <TemplateTab />,
    },
  ];

  // TODO : tab 바뀌면 새 테이블 data 받아와서 넣어주기
  // tab 상태 변화 관리

  const [currentTab, setCurrentTab] = useState("model");
  const onChangeCurrentKey = (key) => {
    setCurrentTab(key);
  };
  return (
    <Layout defaultOpenKeys={["sub2"]} defaultMenu={["6"]}>
      <PageHeading>템플릿 관리</PageHeading>
      <TabMenu type="card" items={items} activeKey={currentTab} onChange={onChangeCurrentKey} />
    </Layout>
  );
};

export default Template;
