import { useState, useEffect } from "react";
import { Image } from "antd";
import { Wrapper, ImageWrapper } from "./styles";
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const FileUpload = ({ id, file, setFile, isSmall, imgSrc, keywordType, disabled = false, isBig = false, isCode, deletePhoto }) => {
  const [fileDataURL, setFileDataURL] = useState(null);
  const [isImg, setIsImg] = useState(imgSrc);

  useEffect(() => {
    if (imgSrc) {
      setIsImg(imgSrc);
    }
  }, [imgSrc]);

  const onChange = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      // 파일 체크
      return;
    }
    setFile(file);
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.onabort = () => {
        console.log("abort!");
      };
      fileReader.onerror = () => {
        console.log("error!");
      };

      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  //const onClickDelete = () => setFile(null);
  //const setFileInput = () => setIsImg(false);

  const onClickDelete = () => {
    //이미지를 선택해서 받아왔을때 x버튼 호출
    console.log("delete");
    setFile(null);
    //deleteCustomerPhoto();
  };

  const setFileInput = () => {
    //서버에서 이미지를 받아왔을때 x버튼 호출
    setIsImg(false);
    deletePhoto(); //서버에 저장된 이미지 삭제호출 만들어지면 사용
  };
  return (
    <>
      {!file && isImg ? (
        <ImageWrapper isSmall={isSmall} isBig={isBig} isCode={isCode} keywordType={keywordType}>
          <Image src={isImg} />
          <button type="button" onClick={() => setFileInput()}></button>
        </ImageWrapper>
      ) : file ? (
        <ImageWrapper isSmall={isSmall} isBig={isBig} isCode={isCode} keywordType={keywordType}>
          <Image src={fileDataURL} />
          <button type="button" onClick={onClickDelete}></button>
        </ImageWrapper>
      ) : (
        <Wrapper isSmall={isSmall} disabled={disabled} isCode={isCode} isBig={isBig} keywordType={keywordType}>
          <input type="file" id={id} onChange={onChange} accept="image/*" />
          <label htmlFor={id}></label>
        </Wrapper>
      )}
    </>
  );
};

export default FileUpload;
