import styled from "styled-components";
import close_x_black from "assets/close_x_black.svg";
import radio_unchecked from "assets/radio_unchecked.svg";
import radio_checked from "assets/radio_checked.svg";
import { flexbox, flexboxColumn, fontStyle14, fontStyle24, iconBtnBlueBase, tableBase } from "styles/mixins";

export const Wrapper = styled.div`
  position: relative;
  padding: 20px 15px 40px 30px;

  h1 {
    ${fontStyle24};
    font-weight: 700;
    margin: 10px 0 25px 0;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(${close_x_black});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

export const TableWrapper = styled.div`
  height: 460px;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid #fff;
    background-clip: padding-box;
    border-radius: 6px;
    background-color: rgba(144, 144, 144, 0.3);
  }
`;

export const Table = styled.table`
  ${tableBase};
  width: 900px !important;
  margin: 0 10px 0 0;
`;

export const InputWrapper = styled.div`
  ${flexbox("space-between", "center")};
  width: 100%;
  margin: 0 0 10px 0;
  input {
    width: 100%;
    margin: 0 15px 0 0;
  }

  button {
    ${fontStyle14};
    flex-grow: 0;
    flex-shrink: 0;
    width: 90px;
    height: 34px;
    border-radius: 120px;
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    font-weight: 500;
    transition: background-color 300ms ease-in-out;

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.blueDark};
    }
  }
`;

export const CheckboxWrapper = styled.div`
  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"] + label {
    cursor: pointer;
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(${radio_unchecked});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
  input[type="checkbox"]:checked + label {
    background-image: url(${radio_checked});
  }
`;

export const Duration = styled.div`
  ${flexboxColumn("flex-start", "flex-start")};
`;

export const EmailBtn = styled.button`
  ${iconBtnBlueBase};
`;
