import styled from "styled-components";
import { Link } from "react-router-dom";
import { FileSearchOutlined } from "@ant-design/icons";
import { iconBtnBlueBase } from "styles/mixins";

const StyledLink = styled(Link)`
  ${iconBtnBlueBase};
`;

const DetailLink = ({ to, state }) => {
  return (
    <StyledLink to={to} state={state}>
      <FileSearchOutlined style={{ fontSize: "14px" }} />
    </StyledLink>
  );
};

export default DetailLink;
