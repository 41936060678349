import styled from "styled-components";
import { flexbox, fontStyle13 } from "styles/mixins";
import close_x_black from "assets/close_x_black.svg";

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const StyledInput = styled.input`
  ${flexbox("flex-start", "center")};
  display: block;
  width: 320px;
  height: 40px;
  padding: 0 18px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border};
  font-weight: 500;
  ${fontStyle13};
  color: ${(props) => props.theme.black};
  transition: border-color 300ms ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid ${(props) => props.theme.borderActive};
  }

  ::placeholder {
    font-weight: 500;
    ${fontStyle13};
    color: ${(props) => props.theme.placeholder};
  }
`;

export const DelBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.buttonBgGrey};
  background-image: url(${close_x_black});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 9px 9px;
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.buttonBgGreyHover};
  }
`;
