import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Layout from "layouts/Layout";
import CustomBreadcrumb from "components/common/Breadcrumb";
import PeriodPicker from "components/common/PeriodPicker";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import DetailBtn from "components/common/DetailBtn";
import Modal from "components/common/Modal";
import DetailModalContent from "components/common/DetailModalContent";
import { userProjectSearchOption } from "utils/data";

import { PageHeading, InfoHeading, BaseInfoTable, ProjectHeading, PickerWrapper, ResultHeadingWrapper } from "./styles";

// TODO
// 백엔드 데이터 넣을 것
import { projectMockData } from "utils/mockData";
import EditLink from "components/common/EditLink";
import axiosPrivate from "utils/axiosPrivate";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { businessType, companySize, series, subValue, teamSize } from "utils/changeValue";

const breadcrumbItem = [
  {
    name: "회원 관리",
  },
  {
    name: "회원 상세",
  },
];

const UserDetail = () => {
  const { id } = useParams();

  // TODO
  // mock data -> server data
  const [targetUser, setTargetUser] = useState();
  const [targetCompany, setTargetCompany] = useState();
  const [projects, setProjects] = useState([]);

  // 하단 search box
  // 검색 날짜 설정
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { data: userInfoData, error: userInfoError } = useSWR(`/api/v1/admin/members/${id}`, (url) => fetcherPrivate(url));
  /*
  const {
    data: projectData,
    error: projectDataError,
    mutate,
  } = useSWR(`/api/v1/admin/members/${id}/projects?page=2&size=10&direction=DESC&projectSearchDateType=START`, (url) =>
    fetcherPrivate(url, {
      params: startDate &&
        endDate && {
          sDate: new Date(startDate).toISOString().split("T")[0],
          eDate: new Date(endDate).toISOString().split("T")[0],
        },
    })
  );
*/

  const {
    data: projectData,
    error: projectDataError,
    mutate,
  } = useSWR(`/api/v1/admin/members/${id}/projects?`, (url) =>
    fetcherPrivate(
      url,
      {
        params:
          startDate && endDate
            ? {
                sDate: new Date(startDate).toISOString().split("T")[0],
                eDate: new Date(endDate).toISOString().split("T")[0],
                page: 1,
                size: 10,
                direction: "DESC",
              }
            : { page: 1, size: 20, direction: "DESC" },
      },
      { revalidateOnFocus: false }
    )
  );

  // useEffect(() => {
  //   mutate();
  // }, [startDate, endDate]);

  useEffect(() => {
    // axiosPrivate
    //   .get(`/api/v1/admin/members/${id}`)
    //   .then((res) => {
    //     console.log(res);
    //     const userData = res.data.result;
    //     const companyData = res.data.result.company;
    //     setTargetUser(userData);
    //     setTargetCompany(companyData);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  // 날짜 변경
  const onChangeDate = (values) => {
    setStartDate(values?.[0]);
    setEndDate(values?.[1]);
  };

  // 프로젝트 상세 모달
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const onClickCloseDetailModal = () => setIsDetailModalVisible(false);

  const [selectedProjectId, setSelectedProjectId] = useState("1");

  const onClickDetailBtn = (id) => {
    setIsDetailModalVisible(true);
    // selected project 정보 받아오기
    setSelectedProjectId(id);
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();

    mutate();
  };

  return (
    <>
      <Layout defaultMenu={["7"]}>
        {/* TODO - breadcrumb */}
        <CustomBreadcrumb item={breadcrumbItem} />
        <PageHeading>회원 상세</PageHeading>
        {/* 상단 */}
        <InfoHeading>회원 정보</InfoHeading>
        {userInfoData?.result && (
          <BaseInfoTable>
            <tbody>
              <Tr>
                <RowThTd th="인증코드">{userInfoData?.result.authCode?.authCode}</RowThTd>
                <RowThTd th="기관 명">{userInfoData?.result.authCode?.companyName}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="이메일">{userInfoData?.result.email}</RowThTd>
                <RowThTd th="회원명">{userInfoData?.result.name}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="연락처">{userInfoData?.result.phone}</RowThTd>
                <RowThTd th="가입일">{userInfoData?.result.joinDate}</RowThTd>
              </Tr>
            </tbody>
          </BaseInfoTable>
        )}

        <InfoHeading>회사 정보</InfoHeading>
        {userInfoData?.result && (
          <BaseInfoTable>
            <tbody>
              <Tr>
                <RowThTd th="기업명">{userInfoData?.result.company.companyName}</RowThTd>
                <RowThTd th="기업 유형">{subValue(companySize, userInfoData?.result.company.companySize)}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="투자 단계">{subValue(series, userInfoData?.result.company.series)}</RowThTd>
                <RowThTd th="메인 투자사">{userInfoData?.result.company.mainVc}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="비즈니스 분야">{userInfoData?.result.company.businessTypes?.map((value) => subValue(businessType, value)).join(", ")}</RowThTd>
                <RowThTd th="팀 규모">{subValue(teamSize, userInfoData?.result.company.teamSize)}</RowThTd>
              </Tr>
            </tbody>
          </BaseInfoTable>
        )}
        {/* 하단 */}

        <ProjectHeading>프로젝트 이용 목록</ProjectHeading>
        <form onSubmit={onSubmitSearch}>
          <PickerWrapper>
            <PeriodPicker title=" " startDate={startDate} endDate={endDate} onChangeDate={onChangeDate} isAlone />
          </PickerWrapper>
        </form>
        <ResultHeadingWrapper>
          <h4>
            검색 결과 총 <strong>{projectData?.result?.content?.length ?? 0}</strong>개
          </h4>
        </ResultHeadingWrapper>
        <Table>
          <Thead>
            <tr>
              <th>프로젝트 명</th>
              <th style={{ width: "90px" }}>상태</th>
              <th style={{ width: "80px" }}>버전</th>
              <th style={{ width: "90px" }}>회원명</th>
              <th>기업명</th>
              <th style={{ width: "130px" }}>기관 명</th>
              <th>진행 단계</th>
              <th>전문가</th>
              <th style={{ width: "100px" }}>벤치마킹 리소스</th>
              <th style={{ width: "120px" }}>시작일/종료일</th>
            </tr>
          </Thead>
          <Tbody>
            {projectData?.result?.content?.map((project) => (
              <tr key={project.id}>
                <td>{project.name}</td>
                <td>{project.projectStatusType}</td>
                <td>{project.version}</td>
                <td>{project.memberName}</td>
                <td>{project.companyName}</td>
                <td>{project.companyName}</td>
                <td>{project.projectStepType}</td>
                <td>
                  매니저 {project.expertManagerCount}, 디자이너 {project.expertDesignerCount}, 멘토 {project.expertMentorCount}
                </td>
                <td className="with-btn">
                  <div>
                    <p> {project.benchmarkingCount}</p>

                    <EditLink to="/resource/keyword?page=1&size=20&direction=DESC&keywordType=BUSINESS" />
                  </div>
                </td>
                <td className="with-btn">
                  <div>
                    <div>
                      <p>{project.projectStartDate}</p>
                      <p>{project.projectEndDate ?? "-"}</p>
                    </div>
                    <DetailBtn onClick={() => onClickDetailBtn(project.id)} />
                  </div>
                </td>
              </tr>
            ))}
          </Tbody>
        </Table>
      </Layout>
      {/* TODO - projectMockData가 아니라 백엔드 데이터로 수정 */}
      {projectMockData && isDetailModalVisible && (
        <Modal isVisible={isDetailModalVisible}>
          <DetailModalContent id={selectedProjectId} data={projectMockData} onClick={onClickCloseDetailModal} />
        </Modal>
      )}
    </>
  );
};

export default UserDetail;
