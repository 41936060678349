import React, { useContext, useEffect, useState } from "react";
import { Typography, Container, Box, Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ResourceModal from "../project/ResourceModal";

export const ResourceDialog = (props) => {
  const { resourceDialogState, setResourceDialogState, currentId } = props;
  const [data, setData] = React.useState(null);

  const handleDialogClose = () => {
    setData(null);
    setResourceDialogState(false);
  };

  return (
    <Dialog
      open={resourceDialogState}
      onClose={handleDialogClose}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
      maxWidth="lg"
    >
      <DialogContent>
        <ResourceModal handleDialogClose={handleDialogClose} currentId={currentId} />
      </DialogContent>
    </Dialog>
  );
};
