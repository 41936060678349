import { Btn } from "./styles";
import { newExcel } from "../../../utils/excel";

const ExcelBtn = ({ onClick, disabled }) => {
  return (
    <Btn type="button" onClick={onClick} disabled={disabled}>
      엑셀 다운로드
    </Btn>
  );
};

export default ExcelBtn;
