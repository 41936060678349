export const subValue = (arr, findVal) => arr.find((val) => val.code === findVal).value;

export const teamSize = [
  {
    code: "ONE",
    value: "1인",
  },
  {
    code: "LESS_FIVE",
    value: "5인 이하",
  },
  {
    code: "BETWEEN_SIX_TO_TEN",
    value: "6~10인",
  },
  {
    code: "MORE_ELEVEN",
    value: "11명 이상",
  },
  {
    code: "MORE_THIRTY",
    value: "30명 이상",
  },
];

export const companySize = [
  {
    code: "SU",
    value: "스타트업",
  },
  {
    code: "SB",
    value: "중소기업",
  },
  {
    code: "BB",
    value: "대기업",
  },
];

export const series = [
  {
    code: "SEED",
    value: "Seed",
  },
  {
    code: "PRE_A",
    value: "Pre-A",
  },
  {
    code: "SERIES_A",
    value: "Series A",
  },
  {
    code: "SERIES_B",
    value: "Series B",
  },
  {
    code: "SERIES_C",
    value: "Series C 이상",
  },
  {
    code: "IPO_MNA",
    value: "IPO/M&A",
  },
];

export const businessType = [
  {
    code: "EDUCATION",
    value: "교육",
  },
  {
    code: "MEDICAL",
    value: "의료",
  },
  {
    code: "FASHION_BEAUTY",
    value: "패션/뷰티",
  },
  {
    code: "FOOD",
    value: "식품",
  },
  {
    code: "FINANCE",
    value: "금융",
  },
  {
    code: "HEALTH_CARE",
    value: "헬스케어",
  },
  {
    code: "LEISURE",
    value: "레저/여행",
  },
  {
    code: "GAME",
    value: "게임",
  },
  {
    code: "TRAVEL",
    value: "관광",
  },
  {
    code: "INTERIOR",
    value: "인테리어",
  },
  {
    code: "ELECTRIC_IT",
    value: "전자/IT",
  },
  {
    code: "CONTENTS",
    value: "콘텐츠",
  },
];

export const keywordTypes = [
  {
    code: "BUSINESS",
    value: "비즈니스 유형",
  },
  {
    code: "PRODUCT",
    value: "프로덕트 분야 유형",
  },
  {
    code: "MAIN_POINT",
    value: "핵심 기능",
  },
  {
    code: "DESIGN_CONCEPT",
    value: "디자인 컨셉",
  },
];

export const templateTypes = [{ code: "BUSINESS_MODEL", value: "비즈니스 모델" }];
