import styled from "styled-components";
import { fontStyle28, flexbox, fontStyle14, tableBase, fontStyle24, fontStyle20, fontStyle16 } from "styles/mixins";

export const PageHeading = styled.h1`
  ${fontStyle28};
  font-weight: 700;
  margin: 0 0 35px 0;
`;

export const InfoHeading = styled.h2`
  ${fontStyle24};
  font-weight: 700;
  margin: 0 0 10px 0;
`;

// 기본 정보 테이블

export const BaseInfoTable = styled.table`
  ${tableBase};
  margin: 0 0 70px 0;
`;

// period picker

export const PickerWrapper = styled.div`
  width: 1050px;
  padding: 20px;
  border-top: 1px solid #181818;
  background-color: #f2f2f2;
`;

// 프로젝트 현황

export const ProjectHeading = styled.h3`
  ${fontStyle20};
  font-weight: 700;
  padding: 20px 0 0 0;
  margin: 0 0 10px 0;
`;

export const ResultHeadingWrapper = styled.div`
  ${flexbox("space-between", "center")};
  margin: 30px 0 10px 0;

  h4 {
    ${fontStyle16};
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }
`;
