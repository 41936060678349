import { atom, selector } from "recoil";

const initialState = {
  key: "ADMIN",
};

export const recoilBenchmarkTabKeyState = atom({
  key: "recoilBenchmarkTabKeyState",
  default: initialState,
});

export const recoilBenchmarkTabKeySelector = selector({
  key: "recoilBenchmarkTabKeySelector",
  get: ({ get }) => {
    const key = get(recoilBenchmarkTabKeyState);
    return key;
  },
});
