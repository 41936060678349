import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexbox, fontStyle16, fontStyle26, fontStyle14, tableBase, tableSortBtnWrapperBase } from "styles/mixins";
import avatar_mentor from "assets/avatar_mentor.svg";
import avatar_manager from "assets/avatar_manager.svg";
import avatar_designer from "assets/avatar_designer.svg";

export const SortBtnWrapper = styled.div`
  ${tableSortBtnWrapperBase};
`;
