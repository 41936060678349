import styled from "styled-components";
import { Layout, Menu } from "antd";
import { fontStyle14 } from "styles/mixins";

export const StyledSider = styled(Layout.Sider)`
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;

  padding: 24px 0;

  position: fixed;
  left: 0;
  top: 0px;
  bottom: 0;
  background-color: #fff;
  border-right: 1px solid ${(props) => props.theme.border};

  z-index: 99;
`;

export const Logo = styled.div`
  background-color: #fff;
  padding: 0 0 0 24px;
  margin: 0 0 15px 0;
`;

export const StyledMenu = styled(Menu)`
  height: calc(100vh - 40px);
  border-right: none;

  font-weight: 400;
  ${fontStyle14};
  color: #000000;

  .ant-menu-item a:hover,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: ${(props) => props.theme.black};
  }

  .ant-menu-item-selected a {
    font-weight: 700;
  }

  /* 현재 선택된 메뉴 아이템*/
  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e9e9e9;
    color: ${(props) => props.theme.black};
  }

  .ant-menu-item::after {
    border-right: 3px solid #181818;
  }

  .ant-menu-sub .ant-menu-item-selected {
    background-color: #e9e9e9;
  }

  /* 메뉴 아이템 active */
  .ant-menu-submenu-title:active,
  .ant-menu-item:active {
    background-color: #e9e9e9;
  }

  /* 메뉴 아이템 hover */

  .ant-menu-item-active:hover,
  .ant-menu-submenu-title:hover {
    color: ${(props) => props.theme.black};
  }

  .ant-menu-submenu:hover .ant-menu-submenu-arrow,
  .ant-menu-submenu:hover .ant-menu-submenu-title {
    color: ${(props) => props.theme.black};
  }

  .ant-menu-submenu-active,
  .ant-menu-submenu-selected {
    color: ${(props) => props.theme.black};

    .ant-menu-submenu-arrow {
      color: ${(props) => props.theme.black};
    }
  }
`;
