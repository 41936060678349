import styled from "styled-components";
import { FileSearchOutlined } from "@ant-design/icons";
import { iconBtnBlueBase } from "styles/mixins";

const Button = styled.button`
  ${iconBtnBlueBase};
`;

const DetailBtn = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <FileSearchOutlined style={{ fontSize: "14px" }} />
    </Button>
  );
};

export default DetailBtn;
