import { Container } from "./styles";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line as ChartLine } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      tension: 0.1,
    },
  },
  scales: {
    x: {
      grid: {
        color: "#fff",
        tickColor: "#fff",
        drawBorder: false,
      },
    },
    y: {
      // ticks:{
      //   callback:function(value, index,ticks){
      //     return
      //   }
      grid: {
        borderDash: [4, 4],
        tickColor: "#fff",
        borderColor: "#fff",
      },
    },
  },
};


const Line = ({datas}) => {

  //datas = [0,0,0,0,0,0,0,0,0, 100];

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const data = {
    labels,
    datasets: [
      {
        data: datas,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        pointStyle: "circle",
      },
    ],
  };

  return (
    <Container>
      <ChartLine options={options} data={data} />
    </Container>
  );
};

export default Line;
