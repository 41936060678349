import styled from "styled-components";
import { flexbox, fontStyle26, fontStyle16, fontStyle12 } from "styles/mixins";

export const PageHeading = styled.h1`
  ${fontStyle26};
  font-weight: 700;
  margin: 20px 0 30px 0;
`;

export const SearchError = styled.p`
  ${fontStyle12};
  font-weight: 500;
  color: ${(props) => props.theme.orange};
  margin: 2px 0 0 0;
`;

export const ResultWrapper = styled.div`
  ${flexbox("space-between", "center")};
  margin: 50px 0 10px 0;

  h4 {
    ${fontStyle16};
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }
`;
