import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Tooltip = styled.div`
  ${flexbox("center", "center")};
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 80px;
  padding: 4px 3px;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
  border-radius: 6px;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: visibility 250ms ease-in, opacity 250ms ease-in;

  ::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
