import styled from "styled-components";
import { AutoComplete } from "antd";
import { flexbox, fontStyle13 } from "styles/mixins";

export const Wrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const StyledAutocomplete = styled(AutoComplete)`
  &.ant-select.ant-select-auto-complete {
    width: 320px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.border};
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${(props) => props.theme.borderActive};
    box-shadow: unset;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 37px;
    ${fontStyle13};
    font-weight: 500;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
`;

export const Btn = styled.button`
  ${flexbox("center", "center")};
  ${fontStyle13};
  font-weight: 700;
  height: 40px;
  padding: 0 20px;
  margin: 0 0 0 15px;
  background-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  border-radius: 100px;
  transition: background-color 300ms ease-in-out, opacity 300ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.blueDark};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;
