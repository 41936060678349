import styled from "styled-components";
import { flexbox, fontStyle14 } from "styles/mixins";
import excel from "assets/excel.svg";

export const Btn = styled.button`
  ${flexbox("flex-start", "center")};
  ${fontStyle14};
  display: block;
  height: 34px;
  padding: 0 15px 0 32px;
  border-radius: 120px;
  border: 1px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.white};
  background-image: url(${excel});
  background-size: 16px 16px;
  background-position: 10px 9px;
  background-repeat: no-repeat;
  transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.buttonBgWhiteHover};
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;
