import { useState, useEffect } from "react";
import { Button } from "./styles";

const ToggleBtn = ({ id, onToggle, showing, isBig, disabled = false }) => {
  const [isShowing, setIsShowing] = useState(showing);

  const onClickToggle = (e) => {
    e.preventDefault();
    if (disabled) return;
    onToggle(id, isShowing);
    setIsShowing((prev) => !prev);
  };

  useEffect(() => {
    setIsShowing(showing);
  }, [showing]);

  useEffect(() => {
    onToggle(id, isShowing);
  }, [isShowing]);

  return (
    <>
      <Button type="button" id={id} onClick={onClickToggle} isShowing={isShowing} isBig={isBig} disabled={disabled}>
        <div />
      </Button>
    </>
  );
};

export default ToggleBtn;
