import { useState, useMemo, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { recoilKeywordTabKeySelector } from "states/recoilKeywordTabKeyState";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import SortableRow from "./SortableRow";
import TemplateSortableRow from "./TemplateSortableRow";
import { templateTableHeader } from "utils/data";

import TableSortTooltip from "components/common/TableSortTooltip";
import { DownOutlined } from "@ant-design/icons";
import { getSortingCriteria } from "utils/getSortingCriteria";
import {
  FlexWrapper,
  AppIcon,
  BtnWrapper,
  MockImage,
  SortBtnWrapper,
} from "./styles";

const SortableTable = ({
  data,
  setData,
  onToggle,
  onClickDel,
  currentPage,
  keywordList,
}) => {
  // dnd
  const [activeId, setActiveId] = useState();
  const items = useMemo(() => data?.map(({ id }) => id), [data]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragStart = (e) => setActiveId(e.active.id);
  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (active.id !== over.id) {
      setData((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };
  console.log(keywordList, "keywordList");
  const handleDragCancel = () => setActiveId(null);

  // th
  const currentKeywordTab = useRecoilValue(recoilKeywordTabKeySelector);

  // 테이블 - 등록 유형별 정렬
  const registerSortArr = ["default", "true", "false"];
  const [registerSortType, setRegisterSortType] = useState("default");
  const [isRegisterTooltipVisible, setIsRegisterTooltipVisible] =
    useState(false);
  const onClickRegisterTypeHead = () => {
    const nextSortType = getSortingCriteria(registerSortType, registerSortArr);
    setRegisterSortType(nextSortType);
    setIsRegisterTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };
  useEffect(() => {
    if (keywordList) {
      if (registerSortType === "default") {
        let temp = [...keywordList.result.content];
        let result = temp.sort((a, b) => a.tempSave - b.tempSave);
        setData(result);
      } else if (registerSortType === "false") {
        let temp = [...data];
        let result = temp.sort((a, b) => a.tempSave - b.tempSave);
        setData(result);
      } else {
        let temp = [...data];
        let result = temp.sort((a, b) => b.tempSave - a.tempSave);
        setData(result);
      }
    }
  }, [registerSortType]);

  // 테이블 - 토글 여부별 정렬
  const toggleSortArr = ["default", "true", "false"];
  const [toggleSortType, setToggleSortType] = useState("default");
  const [isToggleTooltipVisible, setIsToggleTooltipVisible] = useState(false);
  const onClickToggleHead = () => {
    const nextSortType = getSortingCriteria(toggleSortType, toggleSortArr);
    setToggleSortType(nextSortType);
    setIsToggleTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };
  useEffect(() => {
    if (keywordList) {
      if (toggleSortType === "default") {
        let temp = [...keywordList.result.content];
        let result = temp.sort((a, b) => a.exposure - b.exposure);
        setData(result);
      } else if (toggleSortType === "false") {
        let temp = [...data];
        let result = temp.sort((a, b) => a.exposure - b.exposure);
        setData(result);
      } else {
        let temp = [...data];
        let result = temp.sort((a, b) => b.exposure - a.exposure);
        setData(result);
      }
    }
  }, [toggleSortType]);

  // 테이블 - 유형별  정렬
  const productSortArr = ["default", "true", "false"];
  const [productSortType, setProductSortType] = useState("default");
  const [isProductSortVisible, setIsProductSortVisible] = useState(false);
  const onClickProductSortHead = () => {
    const nextSortType = getSortingCriteria(productSortType, productSortArr);
    setProductSortType(nextSortType);
    setIsProductSortVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };
  useEffect(() => {
    if (keywordList) {
      if (productSortType === "default") {
        let temp = [...keywordList.result.content];
        let result = temp.sort((a, b) => b.keywordType - a.keywordType);
        console.log(result, " default result");
        setData(temp);
      } else if (productSortType === "false") {
        let temp = [...keywordList.result.content];
        let result = temp.filter((item) => item.keywordType === "BUSINESS");
        console.log(result, " false result");
        setData(result);
      } else {
        let temp = [...keywordList.result.content];
        let result = temp.filter((item) => item.keywordType === "PRODUCT");
        console.log(result, "default2 result");
        setData(result);
      }
    }
  }, [productSortType]);

  // 테이블 - 등록일 순 정렬
  const dateSortArr = ["default", "latest", "oldest"];
  const [dateSortType, setDateSortType] = useState("default");
  const [isDateTooltipVisible, setIsDateTooltipVisible] = useState(false);
  const onClickDateHead = () => {
    const nextSortType = getSortingCriteria(dateSortType, dateSortArr);
    setDateSortType(nextSortType);
    setIsDateTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };

  useEffect(() => {
    if (keywordList) {
      if (dateSortType === "default") {
        let temp = [...keywordList.result.content];
        let result = temp.sort(
          (a, b) => new Date(b.joinDate) - new Date(a.joinDate)
        );
        setData(result);
      } else if (dateSortType === "latest") {
        let temp = [...data];
        let result = temp.sort(
          (a, b) => new Date(b.joinDate) - new Date(a.joinDate)
        );
        setData(result);
      } else {
        let temp = [...data];
        let result = temp.sort(
          (a, b) => new Date(a.joinDate) - new Date(b.joinDate)
        );
        setData(result);
      }
    }
  }, [dateSortType]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}>
      <Table>
        <Thead>
          <tr>
            {currentPage === "keyword" ? (
              <>
                {currentKeywordTab.key === "ETC" ? (
                  ""
                ) : (
                  <th style={{ width: "100px" }}>
                    <SortBtnWrapper>
                      <button type="button" onClick={onClickRegisterTypeHead}>
                        상태&nbsp;&nbsp;
                        <DownOutlined style={{ fontSize: "10px" }} />
                      </button>
                      <TableSortTooltip
                        isTooltipVisible={isRegisterTooltipVisible}
                        setIsTooltipVisible={setIsRegisterTooltipVisible}>
                        {registerSortType === "false"
                          ? "등록"
                          : registerSortType === "true"
                          ? "임시저장"
                          : "초기화"}
                      </TableSortTooltip>
                    </SortBtnWrapper>
                  </th>
                )}
                {currentKeywordTab.key === "BUSINESS" ? (
                  <th>구분</th>
                ) : currentKeywordTab.key === "ETC" ? (
                  <th style={{ width: "170px" }}>
                    <SortBtnWrapper>
                      <button type="button" onClick={onClickProductSortHead}>
                        유형&nbsp;&nbsp;
                        <DownOutlined style={{ fontSize: "10px" }} />
                      </button>
                      <TableSortTooltip
                        isTooltipVisible={isProductSortVisible}
                        setIsTooltipVisible={setIsProductSortVisible}>
                        {productSortType === "true"
                          ? "프로덕트"
                          : productSortType === "false"
                          ? "비즈니스"
                          : "초기화"}
                      </TableSortTooltip>
                    </SortBtnWrapper>
                  </th>
                ) : (
                  <th style={{ width: "170px" }}>유형</th>
                )}

                <th
                  style={{ width: currentKeywordTab.key === "ETC" && "170px" }}>
                  키워드
                </th>
                {currentKeywordTab.key === "MAIN_POINT" && <th>서비스명</th>}
                {(currentKeywordTab.key === "DESIGN_CONCEPT" ||
                  currentKeywordTab.key === "MAIN_POINT") && <th>이미지</th>}

                <th
                  style={{
                    width: currentKeywordTab.key === "ETC" ? "" : "120px",
                  }}>
                  {currentKeywordTab.key === "ETC" ? "회원명" : "누적사용량"}
                </th>
                {currentKeywordTab.key === "ETC" ? (
                  ""
                ) : (
                  <th style={{ width: "100px" }}>
                    <SortBtnWrapper>
                      <button type="button" onClick={onClickToggleHead}>
                        노출&nbsp;&nbsp;
                        <DownOutlined style={{ fontSize: "10px" }} />
                      </button>
                      <TableSortTooltip
                        isTooltipVisible={isToggleTooltipVisible}
                        setIsTooltipVisible={setIsToggleTooltipVisible}>
                        {toggleSortType === "true"
                          ? "노출"
                          : toggleSortType === "false"
                          ? "미노출"
                          : "초기화"}
                      </TableSortTooltip>
                    </SortBtnWrapper>
                  </th>
                )}

                <th style={{ width: "200px" }}>
                  <SortBtnWrapper>
                    <button type="button" onClick={onClickDateHead}>
                      등록일&nbsp;&nbsp;
                      <DownOutlined style={{ fontSize: "10px" }} />
                    </button>
                    <TableSortTooltip
                      isTooltipVisible={isDateTooltipVisible}
                      setIsTooltipVisible={setIsDateTooltipVisible}>
                      {dateSortType === "latest"
                        ? "최신순"
                        : dateSortType === "oldest"
                        ? "지난 순"
                        : "초기화"}
                    </TableSortTooltip>
                  </SortBtnWrapper>
                </th>
              </>
            ) : (
              templateTableHeader.map((item, i) => (
                <th width={item.width} key={i}>
                  {item.name}
                </th>
              ))
            )}
          </tr>
        </Thead>
        <Tbody>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {data?.map((item) =>
              currentPage === "keyword" ? (
                <SortableRow
                  key={item.id}
                  id={item.id}
                  data={item}
                  onToggle={onToggle}
                  onClickDel={onClickDel}
                />
              ) : (
                <TemplateSortableRow
                  key={item.id}
                  id={item.id}
                  data={item}
                  onToggle={onToggle}
                  onClickDel={onClickDel}
                />
              )
            )}
          </SortableContext>
        </Tbody>
      </Table>
    </DndContext>
  );
};

export default SortableTable;
