import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const Button = styled.button`
  ${flexbox("center", "center")};
  position: relative;
  width: ${(props) => (props.isBig ? "32px" : "22px")};
  height: ${(props) => (props.isBig ? "16px" : "10px")};
  border-radius: 100px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  background-color: ${(props) => (props.isShowing ? "#4556EE" : "#D9D9D9")};

  transition: all 300ms ease-in-out;

  > div {
    width: 12px;
    height: 12px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: absolute;
    left: ${(props) => (!props.isShowing ? "0" : props.isBing ? "0" : props.isBig ? "9px" : "0")};
    transform: ${(props) => (!props.isShowing ? "translate3d(0,0,0)" : props.isBing ? "translate3d(18px,0,0)" : "translate3d(10px,0,0)")};
    transition: all 300ms ease-in-out;
  }
`;
