import { useState } from "react";
import { isCharMatching } from "utils/isCharMatching";
import { Wrapper, StyledAutocomplete, Btn } from "./styles";

import { AutoComplete } from "antd";
import { useEffect } from "react";
const { Option } = AutoComplete;

const CustomAutocomplete = ({ optionList, onClickAddKeyword, isDisabled, placeholder }) => {
  const [options, setOptions] = useState(optionList);
  const [selectedKeyword, setSelectedKeyword] = useState("");

  useEffect(() => {
    setOptions(optionList);
  }, [optionList]);

  // 검색어 입력 중 매칭하는 리스트만 보여줄 수 있게
  const onSearch = (query) => {
    const filteredOptionArr = optionList?.filter((item) => {
      return item.name.includes(query) || isCharMatching(query, item.name);
    });
    setOptions(filteredOptionArr);
  };

  // 검색어 입력 혹은 추천 검색어 선택하는 경우
  const onChange = (value) => {
    setSelectedKeyword(value);
  };

  // 추가하기 버튼 클릭하는 경우
  const onClickAdd = () => {
    onClickAddKeyword(selectedKeyword);
    setSelectedKeyword("");
    setOptions(optionList);
  };

  return (
    <Wrapper>
      <StyledAutocomplete onSearch={onSearch} value={selectedKeyword} onChange={onChange} placeholder={placeholder}>
        {options.map((option, i) => (
          <Option key={option.id} value={option.name} style={{ height: "40px" }}>
            {option.name}
          </Option>
        ))}
      </StyledAutocomplete>
      <Btn type="button" disabled={isDisabled} onClick={onClickAdd}>
        추가하기
      </Btn>
    </Wrapper>
  );
};

export default CustomAutocomplete;
