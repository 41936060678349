import styled from "styled-components";
import { Breadcrumb } from "antd";
import { fontStyle13 } from "styles/mixins";

export const StyledBreadcrumb = styled(Breadcrumb)`
  &.ant-breadcrumb {
    ${fontStyle13}
    font-weight: 500;
    margin: 0 0 25px 0;
  }

  .disabled {
    pointer-events: none;
  }
`;
