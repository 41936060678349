import styled from "styled-components";
import { flexbox, fontStyle28, fontStyle20, fontStyle15, tableBase, fontStyle12 } from "styles/mixins";

export const PageHeading = styled.h1`
  ${fontStyle28};
  margin: 0 0 20px 0;
  font-weight: 700;
  color: ${(props) => props.theme.black};
`;

export const SubHeading = styled.h2`
  margin: 45px 0 10px 0;
  font-weight: 700;
  ${fontStyle20};
  color: ${(props) => props.theme.black};
`;

export const Table = styled.table`
  ${tableBase};
`;

export const ImageHeading = styled.h5`
  ${fontStyle15};
  font-weight: 500;
  margin: 25px 0 3px;
`;

export const HelperText = styled.p`
  ${fontStyle12};
  line-height: 16px;
  color: ${(props) => props.theme.helperTextGrey};

  &:first-of-type {
    margin: 3px 0 0 0;
  }
`;

export const BtnWrapper = styled.div`
  ${flexbox("space-between", "center")};
  margin: 30px 0 0 0;
`;

export const TempSaveBtn = styled.button`
  ${flexbox("center", "center")};
  ${fontStyle15};
  height: 44px;
  width: 180px;
  border-radius: 100px;
  font-weight: 700;

  border-radius: 120px;
  border: 1px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.white};
  transition: background-color 200ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.buttonBgWhiteHover};
  }
`;
