import { css } from "styled-components";

// ------------------------------------------------------------------------------ flexbox
export const fontStyle12 = css`
  font-size: 12px;
  line-height: 22px;
`;

export const fontStyle13 = css`
  font-size: 13px;
  line-height: 22px;
`;

export const fontStyle14 = css`
  font-size: 14px;
  line-height: 24px;
`;

export const fontStyle15 = css`
  font-size: 15px;
  line-height: 24px;
`;

export const fontStyle16 = css`
  font-size: 16px;
  line-height: 24px;
`;

export const fontStyle18 = css`
  font-size: 18px;
  line-height: 36px;
`;

export const fontStyle20 = css`
  font-size: 20px;
  line-height: 30px;
`;

export const fontStyle24 = css`
  font-size: 24px;
  line-height: 36px;
`;

export const fontStyle26 = css`
  font-size: 26px;
  line-height: 36px;
`;

export const fontStyle28 = css`
  font-size: 28px;
  line-height: 36px;
`;
// ------------------------------------------------------------------------------ flexbox

export const flexbox = (jc, ai) => css`
  display: flex;
  justify-content: ${jc};
  align-items: ${ai};
`;

export const flexboxColumn = (jc, ai) => css`
  ${flexbox(jc, ai)};
  flex-direction: column;
`;

// ------------------------------------------------------------------------------ position

export const positionCenterX = (type = "absolute") => css`
  position: ${type};
  left: 50%;
  transform: translateX(-50%);
`;

export const positionCenterY = (type = "absolute") => css`
  position: ${type};
  top: 50%;
  transform: translateY(-50%);
`;

export const positionCenter = (type = "absolute") => css`
  position: ${type};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// ------------------------------------------------------------------------------ etc

export const tableBase = css`
  width: 1050px;
  table-layout : fixed;
  border-spacing: 0;
  border-collapse: collapse;
  word-wrap: break-word;
  word-break: keep-all;
  border: 0;
`;

export const tableSortBtnWrapperBase = css`
  position: relative;

  button {
    display: block;
    ${fontStyle14};
    font-weight: 700;
  }

  .anticon-down {
    position: relative;
    top: -1px;
  }
`;

export const iconBtnBlueBase = css`
  ${flexbox("center", "center")};
  width: 22px;
  height: 22px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.white};
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.blue};
    background-color: rgba(69, 86, 238, 0.1);
  }

  &:active {
    background-color: rgba(69, 86, 238, 0.2);
  }
`;

export const iconBtnBlackBase = css`
  ${iconBtnBlueBase};
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${(props) => props.theme.black};
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    color: ${(props) => props.theme.black};
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const iconBtnOrangeBase = css`
  ${iconBtnBlueBase};
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${(props) => props.theme.orange};
    background-color: rgba(206, 94, 65, 0.1);
  }

  &:active {
    color: ${(props) => props.theme.orange};
    background-color: rgba(206, 94, 65, 0.2);
  }
`;

export const truncate = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const visuallyHidden = css`
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`;
