import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexbox, fontStyle14, fontStyle16 } from "styles/mixins";

export const RegisterLink = styled(Link)`
  ${flexbox("center", "center")};
  ${fontStyle14};
  font-weight: 500;
  height: 34px;
  width: 120px;
  margin: 40px 0 20px auto;
  border-radius: 120px;
  background-color: ${(props) => props.theme.buttonBgBlack};
  color: ${(props) => props.theme.white};
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:active {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.black};
  }
`;

export const ResultHeading = styled.h4`
  ${fontStyle16};
  font-weight: 500;
  margin: 50px 0 10px 0;

  strong {
    font-weight: 700;
  }
`;
