import { useState, useEffect } from "react";
import Tbody from "components/common/BasicTable/Tbody";
import Thead from "components/common/BasicTable/Thead";
import Input from "components/common/Input";
import { MailOutlined } from "@ant-design/icons";
import {
  Wrapper,
  CloseBtn,
  InputWrapper,
  Table,
  CheckboxWrapper,
  Duration,
  EmailBtn,
  TableWrapper,
} from "./styles";
import Pagination from "components/common/Pagination";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { projectMatchSearchOption } from "utils/data";
import CustomSelect from "components/common/Select";

const ResourceModal = ({ currentId, handleDialogClose }) => {
  const { id: uid } = useParams();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  // 검색어 입력 값
  const [selectedOption, setSelectedOption] = useState(
    projectMatchSearchOption[0]
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchName, setSearchName] = useState("");

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const {
    data: resourceData,
    error: resourceDataError,
    mutate,
  } = useSWR(
    `/api/v1/admin/projects/benchmarks-all?registerType=ADMIN&page=${currentPage}&size=10&direction=DESC&exposure=true&name=${searchName}`,
    fetcherPrivate
  );

  const { data: oldResourceData, error: oldResourceDataError } = useSWR(
    `/api/v1/admin/project/${currentId}/benchmark-match`,
    fetcherPrivate
  );

  const onClickSearch = () => {
    setSearchName(searchValue);
    mutate();
  };
  const onClickAllItem = () => {
    let temp = [];
    temp.push(
      resourceData?.result?.map((item) => {
        return item.id;
      })
    );
    setCheckedArr(temp[0]);
  };
  // 체크박스 선택
  const [checkedArr, setCheckedArr] = useState([]);
  useEffect(() => {
    let temp = [];
    let tempData = [];
    //등록한 리소스 목록 저장
    tempData.push(
      resourceData?.result?.map((item) => {
        return item.id;
      })
    );
    //서버에 저장된 리소스 아이템이 없으면 전체 리소스 목록 저장
    if (oldResourceData?.result.length == 0) {
      temp.push(
        resourceData?.result?.map((item) => {
          return item.id;
        })
      );
    }
    //서버에 저장된 리소스가 있으면 저장된 리스트 만 체크 및 리스트에 저장된 리소스가 없으면 삭제 처리
    else {
      let temp2 = [];
      temp2.push(
        oldResourceData?.result?.map((item) => {
          return tempData[0].includes(item.benchmarkId) && item.benchmarkId;
        })
      );
      temp[0] = temp2[0]?.filter((item) => item !== false);
    }
    setCheckedArr(temp[0]);
  }, [oldResourceData]);

  const onChangeCheckbox = (checked, id) => {
    if (checked) {
      setCheckedArr([...checkedArr, id]);
    } else {
      const oldArr = [...checkedArr];
      const newArr = oldArr.filter((item) => item !== id);
      setCheckedArr(newArr);
    }
  };

  const onChangeSelect = (value) => {
    setSelectedOption(projectMatchSearchOption[value]);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    axiosPrivate
      .patch(`/api/v1/admin/project/${currentId}/benchmark-match`, {
        benchmarkIds: checkedArr,
      })
      .then((res) => {
        handleDialogClose();
        alert("저장성공");
      })
      .catch((err) => {
        console.log("저장 에러 결과", err);
      });
  };

  return (
    <Wrapper>
      <h1>벤치마킹 리소스</h1>
      <CloseBtn type="button" onClick={handleDialogClose} />

      <form onSubmit={onSubmit}>
        <InputWrapper>
          <div style={{ display: "flex", width: "500px" }}>
            <div style={{ marginTop: "1px", width: "100%" }}>
              <Input
                type="text"
                placeholder="검색어를 입력하세요."
                value={searchValue}
                onChange={onChangeSearchValue}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {/* 전체 선택 필요 할때 사용
            <div style={{ marginRight: "20px" }}>
              <button type="button" onClick={onClickAllItem}>
                전체선택
              </button>
            </div>*/}
            <button type="button" onClick={onClickSearch}>
              검색
            </button>
          </div>
        </InputWrapper>
        <TableWrapper>
          <Table>
            <Thead>
              <tr>
                <th style={{ width: "50px" }}></th>
                <th style={{ width: "200px" }}>리소스 명</th>
                <th>키워드</th>
                <th style={{ width: "200px" }}>등록일</th>
              </tr>
            </Thead>
            <Tbody>
              {resourceData?.result?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <CheckboxWrapper>
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        checked={checkedArr?.includes(item.id)}
                        onChange={(e) =>
                          onChangeCheckbox(e.target.checked, item.id)
                        }
                      />
                      <label htmlFor={item.id}></label>
                    </CheckboxWrapper>
                  </td>
                  <td>
                    <div>{item.name}</div>
                  </td>
                  <td>
                    <div>{item.keywordNames}</div>
                  </td>
                  <td className="with-btn">
                    <div>
                      <Duration>
                        <p>{item.joinDate}</p>
                      </Duration>
                    </div>
                  </td>
                </tr>
              ))}
            </Tbody>
          </Table>
        </TableWrapper>
        {/*<Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={resourceData?.result?.totalPages} pageSize={1} />*/}
        <CancelConfirmBtn
          onClickCancel={handleDialogClose}
          confirmText="저장"
        />
      </form>
    </Wrapper>
  );
};

export default ResourceModal;
