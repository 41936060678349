import { Wrapper } from "./styles";
const CancelConfirmBtn = ({ onClickCancel, confirmText, isDisabled }) => {
  return (
    <Wrapper>
      <button type="button" onClick={onClickCancel}>
        취소
      </button>
      <button type="submit" disabled={isDisabled}>
        {confirmText}
      </button>
    </Wrapper>
  );
};

export default CancelConfirmBtn;
