import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexbox, fontStyle16, fontStyle14, fontStyle26 } from "styles/mixins";

export const PageHeading = styled.h1`
  ${fontStyle26};
  font-weight: 700;
  margin: 0 0 15px 0;
`;

export const RegisterLink = styled(Link)`
  ${flexbox("center", "center")};
  ${fontStyle14};
  font-weight: 500;
  height: 34px;
  width: 120px;
  margin: 40px 0 20px auto;
  border-radius: 120px;
  background-color: ${(props) => props.theme.buttonBgBlack};
  color: ${(props) => props.theme.white};
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

  &:hover,
  &:active {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.black};
  }
`;

export const TabBtnList = styled.ul`
  ${flexbox};
  border-bottom: 1px solid #f3f3f3;
`;

export const TabBtn = styled.button`
  ${fontStyle14};
  font-weight: ${(props) => (props.isCurrent ? 500 : 400)};
  margin: 0 3px -1px 0;
  border-top: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-bottom: 1px solid ${(props) => (props.isCurrent ? "transparent" : "#f3f3f3")};
  background-color: ${(props) => (props.isCurrent ? "#fff" : "#fafafa")};
  padding: 7px 16px;
  transition: background-color 300ms ease-in-out, font-weight 300ms ease-in-out;
`;

export const ResultWrapper = styled.div`
  ${flexbox("space-between", "center")};
  margin: 50px 0 10px 0;

  h4 {
    ${fontStyle16};
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }
`;

export const FlexWrapper = styled.div`
  ${flexbox("space-between", "center")};
`;
