import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ToggleBtn from "../../ToggleBtn";
import EditLink from "../../EditLink";
import DeleteBtn from "../../DeleteBtn";
import DragHandle from "../DragHandle";
import { FlexWrapper, ImageTd, BtnTd } from "../SortableRow/styles";
import { subValue, templateTypes } from "utils/changeValue";

const TemplateSortableRow = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });
  const { id, templateType, name, description, templateImage, tempSave, joinDate, exposure, businessModelType } = props.data;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // 삭제 버튼
  const onClickDelete = () => {
    props.onClickDel(id);
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td>
        <FlexWrapper>
          <DragHandle {...attributes} {...listeners} isDragging={isDragging} />
          <p>{tempSave == false ? "등록" : "임시저장"}</p>
        </FlexWrapper>
      </td>
      <td>{name}</td>
      <td>{businessModelType}</td>
      <td>{description}</td>
      <td>
        <ImageTd>
          <img src={templateImage} alt="keyword image" />
        </ImageTd>
      </td>
      <td>
        <ToggleBtn id={id} onToggle={props.onToggle} showing={exposure} disabled />
      </td>
      <td>
        <BtnTd>
          <p>{joinDate}</p>
          <FlexWrapper>
            <EditLink to={`/resource/template/edit/${id}`} />
            <DeleteBtn
              onClick={() => {
                onClickDelete(id);
              }}
            />
          </FlexWrapper>
        </BtnTd>
      </td>
    </tr>
  );
};

export default TemplateSortableRow;
