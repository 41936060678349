import moment from "moment";
import { useState } from "react";
import CustomSelect from "../Select";
import PeriodPicker from "../PeriodPicker";
import { Container, SelectInput, BtnWrapper } from "./styles";
import { useSearchData } from "utils/useSearchData";
import { useLocation, useNavigate } from "react-router-dom";
import { qsStringify, qsParses } from "utils/queryHandler";
import { useEffect } from "react";

const SearchBox = ({
  page,
  direction = "DESC",
  size = 20,
  optionList,
  isTitle = true,
  moreOption,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchValue, onChangeSearchValue, sDate, eDate, onChangeDate] =
    useSearchData();
  const [searchData, setSearchData] = useState(qsParses(search));
  const [selectedOption, setSelectedOption] = useState(optionList[0]);
  const [moreSelectOption, setMoreSelectOption] = useState(
    moreOption ? moreOption[0] : null
  );

  useEffect(() => {
    onChangeSearchValue({ target: { value: "" } });
    onChangeDate([null, null]);
    setSelectedOption(optionList[0]);
    setMoreSelectOption(null);
  }, [search]);

  const onChangeSelect = (value) => {
    if (selectedOption["searchOpt"]) {
      setSearchData((prev) => ({ ...prev, [selectedOption["searchOpt"]]: "" }));
    }

    setSelectedOption(optionList[value]);
  };

  const onChangeProjectDateSelect = (value) => {
    console.log(value);
    setMoreSelectOption(moreOption[value]);
    setSearchData((prev) => ({
      ...prev,
      [moreOption[value]["searchOpt"]]: moreOption[value]["searchVal"],
    }));
  };

  const onClickReset = () => {
    onChangeSearchValue({ target: { value: "" } });
    onChangeDate([null, null]);
  };

  useEffect(() => {
    setSearchData(qsParses(search));
  }, [search]);

  useEffect(() => {
    if (search) {
      const { page, size, direction, sDate, eDate, name, ...rest } =
        qsParses(search);

      if (sDate && eDate) {
        onChangeDate([moment(sDate), moment(eDate)]);
      }

      if (name) {
        onChangeSearchValue({ target: { value: name } });
        setSelectedOption(
          optionList.find((value) => value.searchOpt === "name")
        );
      } else {
        const isSearchValue = optionList.find((value) =>
          Object.keys(rest).includes(value.searchOpt)
        );
        if (isSearchValue) {
          onChangeSearchValue({
            target: { value: rest[isSearchValue["searchOpt"]] },
          });
          setSelectedOption(isSearchValue);
        }
      }
    }
  }, [search]);

  const onRoute = (e) => {
    e.preventDefault();
    console.log(searchValue, "searchValue");
    navigate(
      `?${qsStringify(
        {
          ...qsParses(searchData, { strictNullHandling: true }),
          page: 1, // 검색 시에는 기본 1페이지로 넘어가야 한다.
          size,
          direction,
          sDate: sDate ? moment(sDate).format("yyyy-MM-DD") : null,
          eDate: eDate ? moment(eDate).format("yyyy-MM-DD") : null,
          ...(selectedOption["searchOpt"] && {
            [selectedOption["searchOpt"]]: searchValue,
          }),
        },
        {
          skipNulls: true,
          strictNullHandling: true,
          skipEmpty: true,
          emptyValues: true,
        }
      )
        .replace(/[^=&]+=(&|$)/g, "")
        .replace(/&$/, "")}`
    );
  };

  return (
    <Container>
      <form onSubmit={onRoute}>
        <div>
          <SelectInput>
            <CustomSelect
              optionList={optionList}
              value={selectedOption}
              onChange={onChangeSelect}
              allowClear
            />
            <input
              type="text"
              placeholder="검색어를 입력하세요."
              value={searchValue}
              onChange={onChangeSearchValue}
            />
          </SelectInput>
          <PeriodPicker
            title={isTitle ? "가입일" : ""}
            allowClear
            startDate={sDate}
            endDate={eDate}
            onChangeDate={onChangeDate}
            selectOptionList={moreOption}
            onChangeSelect={onChangeProjectDateSelect}
            selectedValue={moreSelectOption}
          />
        </div>
        <BtnWrapper>
          <button type="submit">검색</button>
          <button type="button" onClick={onClickReset}>
            초기화
          </button>
        </BtnWrapper>
      </form>
    </Container>
  );
};

export default SearchBox;
