import { useState } from "react";
import { InputWrapper, ToggleBtn } from "./styles";

const PasswordInput = ({ id, placeholder, value, onChange, isValid }) => {
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const togglePassword = () => setIsPwdVisible((prev) => !prev);

  return (
    <InputWrapper isValid={isValid}>
      <input type={isPwdVisible ? "text" : "password"} id={id} placeholder={placeholder} autoComplete={id} value={value} onChange={onChange} />
      <ToggleBtn type="button" isPwdVisible={isPwdVisible} onClick={togglePassword}></ToggleBtn>
    </InputWrapper>
  );
};

export default PasswordInput;
