import styled from "styled-components";
import { fontStyle14 } from "styles/mixins";

export const StyledTr = styled.tr`
  ${fontStyle14};
  text-align: left;
  color: ${(props) => props.theme.black};
  border-top: 1px solid ${(props) => props.theme.tableBorder};
  &:last-of-type {
    border-bottom: 1px solid ${(props) => props.theme.tableBorder};
  }
`;
