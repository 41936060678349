import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { iconBtnBlackBase } from "styles/mixins";

const StyledLink = styled(Link)`
  ${iconBtnBlackBase};
`;

const EditLink = ({ to }) => {
  return (
    <StyledLink to={to}>
      <EditOutlined style={{ fontSize: "14px" }} />
    </StyledLink>
  );
};

export default EditLink;
