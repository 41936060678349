import { ModalContent, BtnWrapper } from "./styles";

const AlertModalContent = ({ onClickCancel, onClickSave, confrimText = "확인", children }) => {
  return (
    <ModalContent>
      {children}
      <BtnWrapper>
        <button type="button" onClick={onClickCancel}>
          취소
        </button>
        <button type="button" onClick={onClickSave}>
          {confrimText}
        </button>
      </BtnWrapper>
    </ModalContent>
  );
};

export default AlertModalContent;
