import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import axiosPrivateFormData from "utils/axiosPrivateFormData";
import Layout from "layouts/Layout";
import CustomBreadcrumb from "components/common/Breadcrumb";
import CustomSelect from "components/common/Select";
import Input from "components/common/Input";
import PasswordInput from "components/common/PasswordInput";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { PageHeading, SubHeading, Table, EmailWrapper, EmailCheckBtn, FileWrapper, BirthWrapper, InputWrapper, HelperText, EmailInfoText } from "./styles";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";

const AdminRegister = () => {
  const navigate = useNavigate();

  // 전문가 '등록' 페이지인지 '수정' 페이지인지 구분
  const { pathname } = useLocation();
  const { id: uid } = useParams();

  const {
    data: adminDatas,
    error: adminDatasError,
    mutate: keywordMutate,
  } = useSWR(`/api/v1/super/admins/${uid}`, (url) => fetcherPrivate(url), {
    revalidateOnFocus: false,
  });

  const [page, setPage] = useState("");
  useEffect(() => {
    if (pathname.includes("register")) {
      setPage("register");
    } else if (pathname.includes("edit")) {
      setPage("edit");
    }
  }, [pathname]);

  const [breadcrumb, setBreadCrumb] = useState("관리자 등록하기");
  const breadcrumbItem = [
    {
      name: "관리자 계정관리",
    },
    {
      name: breadcrumb,
    },
  ];

  useEffect(() => {
    if (page === "register") {
      setBreadCrumb("관리자 등록하기");
    } else {
      setBreadCrumb("관리자 수정하기");
    }
  }, [page]);

  // ----------------------------------------------------------------------------------------------------

  // 이메일
  const [emailId, setEmailId] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const onChangeEmailId = (e) => setEmailId(e.target.value);
  const onChangeEmailDomain = (e) => setEmailDomain(e.target.value);

  //이메일 - 중복 체크
  const [hasEmailChecked, setHasEmailChecked] = useState(false);
  const [isEmailDuplicated, setIsEmailDuplicated] = useState(false);

  // 비밀번호
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const onChangePassword = (e) => setPassword(e.target.value);
  useEffect(() => {
    if (password?.length > 0) {
      if (password?.length >= 8 && password?.length <= 32) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    } else {
      setIsPasswordValid(true);
    }
  }, [password]);

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const onChangePasswordConfirm = (e) => setPasswordConfirm(e.target.value);

  // 비밀번호 일치 여부
  useEffect(() => {
    if (passwordConfirm?.length > 0) {
      if (password === passwordConfirm) {
        setIsPasswordSame(true);
      } else {
        setIsPasswordSame(false);
      }
    } else {
      setIsPasswordSame(true);
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    if (adminDatas) {
      const { email, nick, phone, introduction, birth } = adminDatas.result;
      const emailSplit = email.split("@");

      setEmailId(emailSplit[0]);

      setEmailDomain(emailSplit[1]);
    }
  }, [adminDatas]);

  const onClickCheckEmail = () => {
    if (emailId?.length > 0 && emailDomain?.length > 0) {
      setHasEmailChecked(false);
      setIsEmailDuplicated(false);

      const email = emailId + "@" + emailDomain;
      axiosPrivate
        .get("/api/v1/open/email/duplicate-check", {
          params: {
            email,
          },
        })
        .then((resp) => {
          setIsEmailDuplicated(false);
          setVerifiedEmail(email);
        })
        .catch((err) => {
          console.log(err);
          setIsEmailDuplicated(true);
        })
        .finally(() => {
          setHasEmailChecked(true);
        });
    }
  };

  // 이메일 중복 체크 후 또 변경하는 경우
  useEffect(() => {
    if (hasEmailChecked && !isEmailDuplicated && verifiedEmail?.length > 0) {
      const newEmail = emailId + "@" + emailDomain;
      if (newEmail !== verifiedEmail) {
        setHasEmailChecked(false);
        setIsEmailDuplicated(false);
      }
    }
  }, [page, emailId, emailDomain, verifiedEmail, hasEmailChecked, isEmailDuplicated]);

  // 필수 입력값 다 입력해야 확인 버튼 클릭 가능.
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (page === "edit") {
      setIsDisabled(false);
    } else {
      if (emailId && emailDomain) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [page, emailId, emailDomain, hasEmailChecked, isEmailDuplicated, password, isPasswordValid, passwordConfirm, isPasswordSame]);

  const onSubmit = (e) => {
    e.preventDefault();

    const email = emailId + "@" + emailDomain;

    if (page === "register") {
      const value = {
        email,
        password,
      };

      axiosPrivate
        .post("/api/v1/super/admins", value)
        .then((res) => {
          if (res.status === 200) {
            navigate("/admin?page=1&size=20&direction=DESC");
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert("관리자 등록에 실패했습니다. 다시 시도해주세요.");
        });
    } else {
      // 수정 페이지
      const { email: emailFromServer } = adminDatas.result;
      const emailSplit = emailFromServer.split("@");

      const emailIdFromServer = emailSplit[0];
      const emailDomainFromServer = emailSplit[1];

      let value = {};
      if (emailId !== emailIdFromServer || emailDomain !== emailDomainFromServer) {
        if (!hasEmailChecked) {
          return window.alert("이메일 중복 체크를 해주세요");
        } else if (isEmailDuplicated) {
          return window.alert("중복된 이메일은 사용 불가능합니다. 새로운 이메일로 변경해주세요.");
        } else {
          value.email = emailId + "@" + emailDomain;
        }
      }

      if (password?.length > 0) {
        if (!isPasswordValid) {
          return window.alert("유효하지 않은 비밀번호입니다.");
        } else if (!isPasswordSame) {
          return window.alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        } else {
          value.password = password;
        }
      }

      axiosPrivate
        .patch(`/api/v1/super/admins/${uid}`, value)
        .then((res) => {
          if (res.status === 200) {
            navigate(`/admin?page=1&size=20&direction=DESC`);
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert("전문가 수정에 실패했습니다. 다시 시도해주세요.");
        });
    }
  };

  // 버튼
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const onClickCancel = () => {
    setIsCancelModalVisible(true);
  };
  // 취소 클릭
  const onClickCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  const onClickSaveModal = () => {
    setIsCancelModalVisible(false);
    navigate("/admin");
  };

  return (
    <>
      <Layout defaultMenu={["10"]}>
        <CustomBreadcrumb item={breadcrumbItem} />
        <PageHeading>{page === "register" ? "계정 생성" : "계정 편집"}</PageHeading>
        <form onSubmit={onSubmit}>
          <Table border="0">
            <tbody>
              <Tr>
                <RowThTd th="이메일" isRequired={true}>
                  <EmailWrapper>
                    <Input placeholder="이메일을 입력해주세요" id="email-id" value={emailId} onChange={onChangeEmailId} />
                    <span>@</span>
                    <Input placeholder="이메일을 입력해주세요" id="email-domain" value={emailDomain} onChange={onChangeEmailDomain} />
                    <EmailCheckBtn type="button" onClick={onClickCheckEmail}>
                      중복확인
                    </EmailCheckBtn>
                  </EmailWrapper>
                  {hasEmailChecked && isEmailDuplicated && <HelperText>중복된 이메일입니다.</HelperText>}
                  {hasEmailChecked && !isEmailDuplicated && <HelperText isSafe>사용 가능한 이메일입니다.</HelperText>}
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="비밀번호" isRequired={page === "register"}>
                  <InputWrapper>
                    <PasswordInput id="password" placeholder="비밀번호를 8~32자로 입력해주세요" value={password} onChange={onChangePassword} isValid={isPasswordValid} />
                    <small>{password?.length} / 32</small>
                  </InputWrapper>

                  {password?.length > 0 && !isPasswordValid && <HelperText>8~32자로 입력되어야 합니다.</HelperText>}
                </RowThTd>
              </Tr>
            </tbody>
          </Table>
          <CancelConfirmBtn onClickCancel={onClickCancel} confirmText="확인" isDisabled={isDisabled} />
        </form>
      </Layout>
      {isCancelModalVisible && (
        <Modal isVisible={isCancelModalVisible}>
          <AlertModalContent onClickCancel={onClickCancelModal} onClickSave={onClickSaveModal} confrimText="확인">
            <h2>정말 취소하시겠습니까?</h2>
            <p>
              저장전 취소 시 입력한 데이터가 <br />
              모두 삭제됩니다.
            </p>
            <p>그래도 취소하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default AdminRegister;
