import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import axiosPrivateFormData from "utils/axiosPrivateFormData";
import Layout from "layouts/Layout";
import CustomBreadcrumb from "components/common/Breadcrumb";
import CustomSelect from "components/common/Select";
import Input from "components/common/Input";
import PasswordInput from "components/common/PasswordInput";
import Textarea from "components/common/Textarea";
import ProfileUpload from "components/common/ProfileUpload";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { PageHeading, SubHeading, Table, EmailWrapper, EmailCheckBtn, FileWrapper, BirthWrapper, InputWrapper, HelperText, EmailInfoText } from "./styles";
import { expertTypeOption, yearOption, monthOption, dayOption } from "utils/data";
import { addZeroToStr, generateBirth } from "utils/helperFunctions";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import ic_default_startup_big from "assets/ic_default_startup_big.jpg";
import ic_default_manager_big from "assets/ic_default_manager_big.jpg";
import ic_default_disigner_big from "assets/ic_default_disigner_big.jpg";
import ic_default_mento_big from "assets/ic_default_mento_big.jpg";

import convertImgToFile from "utils/convertImgToFile";

const ExpertRegister = () => {
  const navigate = useNavigate();

  // 전문가 '등록' 페이지인지 '수정' 페이지인지 구분
  const { pathname } = useLocation();
  const { id: uid } = useParams();

  const {
    data: expertDatas,
    error: expertDatasError,
    mutate: keywordMutate,
  } = useSWR(`/api/v1/admin/experts/${uid}`, (url) => fetcherPrivate(url), {
    revalidateOnFocus: false,
  });

  const [page, setPage] = useState("");
  useEffect(() => {
    if (pathname.includes("register")) {
      setPage("register");
    } else if (pathname.includes("edit")) {
      setPage("edit");
    }
  }, [pathname]);

  const [breadcrumb, setBreadCrumb] = useState("전문가 등록하기");
  const breadcrumbItem = [
    {
      name: "전문가 관리",
    },
    {
      name: breadcrumb,
    },
  ];

  useEffect(() => {
    if (page === "register") {
      setBreadCrumb("전문가 등록하기");
    } else {
      setBreadCrumb("전문가 수정하기");
    }
  }, [page]);

  // ----------------------------------------------------------------------------------------------------

  // 전문가 타입
  const [expertType, setExpertType] = useState();
  const onChangeExpertType = (value) => setExpertType(value);

  // 이메일
  const [emailId, setEmailId] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const onChangeEmailId = (e) => setEmailId(e.target.value);
  const onChangeEmailDomain = (e) => setEmailDomain(e.target.value);

  //이메일 - 중복 체크
  const [hasEmailChecked, setHasEmailChecked] = useState(false);
  const [isEmailDuplicated, setIsEmailDuplicated] = useState(false);

  // 비밀번호
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const onChangePassword = (e) => setPassword(e.target.value);
  useEffect(() => {
    if (password?.length > 0) {
      if (password?.length >= 8 && password?.length <= 32) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    } else {
      setIsPasswordValid(true);
    }
  }, [password]);

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const onChangePasswordConfirm = (e) => setPasswordConfirm(e.target.value);

  // 비밀번호 일치 여부
  useEffect(() => {
    if (passwordConfirm?.length > 0) {
      if (password === passwordConfirm) {
        setIsPasswordSame(true);
      } else {
        setIsPasswordSame(false);
      }
    } else {
      setIsPasswordSame(true);
    }
  }, [password, passwordConfirm]);
  // 사진
  const fileRef = useRef();
  const [file, setFile] = useState(null);

  // 이름
  const [name, setName] = useState("");
  const onChangeName = (e) => setName(e.target.value);

  // 닉네임
  const [nickname, setNickname] = useState("");
  const onChangeNickname = (e) => setNickname(e.target.value);

  // 생년월일
  const [birthYear, setBirthYear] = useState(null);
  const [birthMonth, setBirthMonth] = useState(null);
  const [birthDay, setBirthDay] = useState(null);

  const onChangeBirthYear = (value) => setBirthYear(value);
  const onChangeBirthMonth = (value) => setBirthMonth(value);
  const onChangeBirthDay = (value) => setBirthDay(value);

  // 연락처
  const [phone, setPhone] = useState("");
  const onChangePhone = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(/-/g, "");
    setPhone(trimmedValue);
  };

  // 소개글
  const [intro, setIntro] = useState("");
  const onChangeIntro = (e) => setIntro(e.target.value);

  useEffect(() => {
    if (expertDatas) {
      const { email, nick, name, phone, introduction, birth, expertType } = expertDatas.result;
      const emailSplit = email.split("@");
      const birthSplit = birth ? birth.split("-") : ["", "", ""];

      setExpertType(expertType);
      // setExpertType(expertTypeOption.find((val) => val.value === expertType).text);

      setName(name);

      setNickname(nick);

      setEmailId(emailSplit[0]);

      setEmailDomain(emailSplit[1]);

      setPhone(phone);

      setIntro(introduction);

      onChangeBirthYear(birthSplit[0]);
      onChangeBirthMonth(birthSplit[1]);
      onChangeBirthDay(birthSplit[2]);
    }
  }, [expertDatas]);

  const onClickCheckEmail = () => {
    if (emailId?.length > 0 && emailDomain?.length > 0) {
      setHasEmailChecked(false);
      setIsEmailDuplicated(false);

      const email = emailId + "@" + emailDomain;
      axiosPrivate
        .get("/api/v1/open/email/duplicate-check", {
          params: {
            email,
          },
        })
        .then((resp) => {
          setIsEmailDuplicated(false);
          setVerifiedEmail(email);
        })
        .catch((err) => {
          console.log(err);
          setIsEmailDuplicated(true);
        })
        .finally(() => {
          setHasEmailChecked(true);
        });
    }
  };

  // 이메일 중복 체크 후 또 변경하는 경우
  useEffect(() => {
    if (hasEmailChecked && !isEmailDuplicated && verifiedEmail?.length > 0) {
      const newEmail = emailId + "@" + emailDomain;
      if (newEmail !== verifiedEmail) {
        setHasEmailChecked(false);
        setIsEmailDuplicated(false);
      }
    }
  }, [page, emailId, emailDomain, verifiedEmail, hasEmailChecked, isEmailDuplicated]);

  // 필수 입력값 다 입력해야 확인 버튼 클릭 가능.
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (page === "edit") {
      setIsDisabled(false);
    } else {
      if (
        expertType &&
        emailId &&
        emailDomain &&
        hasEmailChecked &&
        !isEmailDuplicated &&
        password?.length > 0 &&
        isPasswordValid &&
        passwordConfirm?.length > 0 &&
        isPasswordSame &&
        name &&
        nickname &&
        phone
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [page, expertType, emailId, emailDomain, hasEmailChecked, isEmailDuplicated, password, isPasswordValid, passwordConfirm, isPasswordSame, name, nickname, phone]);

  const deletePhoto = async () => {
    console.log(expertType, "전문가유형");
    //fileRef.current.value = "";
    return axiosPrivate.delete(`/api/v1/admin/experts/${uid}/headshot`).then((res) => {
      setFile(null);
    });
    /*
    try {
      const data = {
        layoutImage: expertType === "MANAGER" ? ic_default_manager_big : expertType === "DESIGNER" ? ic_default_disigner_big : expertType === "MENTOR" ? ic_default_mento_big : ic_default_startup_big,
        fileName:
          expertType === "MANAGER"
            ? "ic_default_manager_big.jpg"
            : expertType === "DESIGNER"
            ? "ic_default_disigner_big.jpg"
            : expertType === "MENTOR"
            ? "ic_default_mento_big.jpg"
            : "ic_default_startup_big.jpg",
        type: "image/jpg",
      };
      console.log(data, "fileinput");
      const newFile = await convertImgToFile(data);
      setFile(newFile);
    } catch (err) {
      console.log(err);
      window.alert("사진 삭제에 실패했습니다. 다시 시도해주세요!");
    }*/
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const email = emailId + "@" + emailDomain;
    const birth = generateBirth(birthYear, birthMonth, birthDay);

    if (page === "register") {
      const value = {
        email,
        password,
        name,
        nick: nickname,
        phone,
        introduction: intro,
        expertType,
        birth,
      };

      const formData = new FormData();

      const req = new Blob([JSON.stringify(value)], { type: "application/json" });

      formData.append("req", req);

      if (file) {
        formData.append("headshot", file);
      }

      axiosPrivateFormData
        .post("/api/v1/admin/experts", formData)
        .then((res) => {
          if (res.status === 200) {
            navigate("/expert?page=1&size=20&direction=DESC&expertType=MANAGER");
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert("전문가 등록에 실패했습니다. 다시 시도해주세요.");
        });
    } else {
      // 수정 페이지
      const { email: emailFromServer, nick: nickFromServer, name: nameFromServer, phone: phoneFromserver, introduction: introFromServer, birth, expertType: expertTypeFromServer } = expertDatas.result;
      const emailSplit = emailFromServer.split("@");
      const birthSplit = birth ? birth.split("-") : ["", "", ""];

      const emailIdFromServer = emailSplit[0];
      const emailDomainFromServer = emailSplit[1];

      const birthYearFromServer = birthSplit[0];
      const birthMonthFromServer = birthSplit[1];
      const birthDayFromServer = birthSplit[2];

      const expertTypeText = expertTypeOption.find((val) => val.value === expertTypeFromServer).text;

      let value = {};
      if (emailId !== emailIdFromServer || emailDomain !== emailDomainFromServer) {
        if (!hasEmailChecked) {
          return window.alert("이메일 중복 체크를 해주세요");
        } else if (isEmailDuplicated) {
          return window.alert("중복된 이메일은 사용 불가능합니다. 새로운 이메일로 변경해주세요.");
        } else {
          value.email = emailId + "@" + emailDomain;
        }
      }

      if (password?.length > 0) {
        if (!isPasswordValid) {
          return window.alert("유효하지 않은 비밀번호입니다.");
        } else if (!isPasswordSame) {
          return window.alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        } else {
          value.password = password;
        }
      }

      if (nickname?.length > 0 && nickname !== nickFromServer) {
        value.nick = nickname;
      }

      if (name?.length > 0 && name !== nameFromServer) {
        value.name = name;
      }

      if (phone?.length > 0 && phone !== phoneFromserver) {
        value.phone = phone;
      }

      if (intro?.length > 0 && intro !== introFromServer) {
        value.introduction = intro;
      }

      if (expertType !== expertTypeFromServer) {
        value.expertType = expertType;
      }

      if (birthYear !== birthYearFromServer || birthMonth !== birthMonthFromServer || birthDay !== birthDayFromServer) {
        const birth = generateBirth(birthYear, birthMonth, birthDay);
        value.birth = birth;
      }

      const formData = new FormData();

      const req = new Blob([JSON.stringify(value)], { type: "application/json" });

      formData.append("req", req);

      if (file) {
        formData.append("headshot", file);
      }

      axiosPrivateFormData
        .patch(`/api/v1/admin/experts/${uid}`, formData)
        .then((res) => {
          if (res.status === 200) {
            navigate(`/expert?page=1&size=20&direction=DESC&expertType=MANAGER`);
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert("전문가 수정에 실패했습니다. 다시 시도해주세요.");
        });
    }
  };

  // 버튼
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const onClickCancel = () => {
    setIsCancelModalVisible(true);
  };
  // 취소 클릭
  const onClickCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  const onClickSaveModal = () => {
    setIsCancelModalVisible(false);
    navigate("/expert");
  };

  return (
    <>
      <Layout defaultMenu={["8"]}>
        <CustomBreadcrumb item={breadcrumbItem} />
        <PageHeading>{page === "register" ? "전문가 등록" : "전문가 수정"}</PageHeading>
        <form onSubmit={onSubmit}>
          <Table border="0">
            <tbody>
              <Tr>
                <RowThTd th="전문가 유형" isRequired={page === "register"}>
                  <CustomSelect optionList={expertTypeOption} placeholder="유형을 선택해주세요" onChange={onChangeExpertType} value={expertType} />
                </RowThTd>
              </Tr>

              <Tr>
                <RowThTd th="이메일" isRequired={page === "register"}>
                  <EmailWrapper>
                    <Input placeholder="이메일을 입력해주세요" id="email-id" value={emailId} onChange={onChangeEmailId} />
                    <span>@</span>
                    <Input placeholder="이메일을 입력해주세요" id="email-domain" value={emailDomain} onChange={onChangeEmailDomain} />
                    <EmailCheckBtn type="button" onClick={onClickCheckEmail}>
                      중복확인
                    </EmailCheckBtn>
                  </EmailWrapper>
                  <EmailInfoText isBlack>*혹시 이메일 중복 확인 후 다시 이메일을 변경하셨나요? 그렇다면 꼭 중복확인을 한 번 더 해주세요!</EmailInfoText>
                  {hasEmailChecked && isEmailDuplicated && <HelperText>중복된 이메일입니다.</HelperText>}
                  {hasEmailChecked && !isEmailDuplicated && <HelperText isSafe>사용 가능한 이메일입니다.</HelperText>}
                </RowThTd>
              </Tr>

              <Tr>
                <RowThTd th="비밀번호" isRequired={page === "register"}>
                  <InputWrapper>
                    <PasswordInput id="password" placeholder="비밀번호를 8~32자로 입력해주세요" value={password} onChange={onChangePassword} isValid={isPasswordValid} />
                    <small>{password?.length} / 32</small>
                  </InputWrapper>

                  {password?.length > 0 && !isPasswordValid && <HelperText>8~32자로 입력되어야 합니다.</HelperText>}
                </RowThTd>
              </Tr>

              <Tr>
                <RowThTd th="비밀번호 확인" isRequired={page === "register"}>
                  <InputWrapper>
                    <PasswordInput id="password-confirm" placeholder="비밀번호를 8~32자로 입력해주세요" value={passwordConfirm} onChange={onChangePasswordConfirm} isValid={isPasswordSame} />
                    <small>{passwordConfirm?.length} / 32</small>
                  </InputWrapper>
                  {passwordConfirm?.length > 0 && !isPasswordSame && <HelperText>비밀번호가 일치하지 않습니다.</HelperText>}
                </RowThTd>
              </Tr>
            </tbody>
          </Table>

          <SubHeading>기본 정보</SubHeading>
          <Table border="0">
            <tbody>
              <Tr>
                <RowThTd th="사진">
                  <FileWrapper>
                    <ProfileUpload id="photo" file={file} setFile={setFile} isSmall imgSrc={expertDatas?.result?.expertHeadshot} deletePhoto={deletePhoto} />
                    <div className="desc">
                      <p>* 대표 사진은 1080 x 720px에 최적화</p>
                      <p>* 한 장당 10MB 이하, 최대 1장 / jpg, jpeg, png </p>
                    </div>
                  </FileWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="이름" isRequired={page === "register"}>
                  <InputWrapper>
                    <Input id="name" placeholder="이름을 입력해주세요" value={name} onChange={onChangeName} maxLength={30} />
                    <small>{name.length} / 30</small>
                  </InputWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="닉네임" isRequired={page === "register"}>
                  <InputWrapper>
                    <Input id="nickname" placeholder="닉네임을 입력해주세요" value={nickname} onChange={onChangeNickname} maxLength={30} />
                    <small>{nickname.length} / 30</small>
                  </InputWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="생년월일">
                  <BirthWrapper>
                    <CustomSelect optionList={yearOption} placeholder="년도" onChange={onChangeBirthYear} value={birthYear} />
                    <CustomSelect optionList={monthOption} placeholder="월" onChange={onChangeBirthMonth} value={birthMonth} />
                    <CustomSelect optionList={dayOption} placeholder="일" onChange={onChangeBirthDay} value={birthDay} />
                  </BirthWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="연락처" isRequired={page === "register"}>
                  <InputWrapper>
                    <Input id="phone" placeholder="숫자만 입력해주세요(8자리~50자리)" value={phone} onChange={onChangePhone} maxLength={50} />
                    <small>{phone?.length} / 50</small>
                  </InputWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="소개글">
                  <InputWrapper>
                    <Textarea id="intro" placeholder="소개글을 자유롭게 입력해주세요" value={intro} onChange={onChangeIntro} maxLength={50} />
                    <small> {intro.length} / 50 </small>
                  </InputWrapper>
                </RowThTd>
              </Tr>
            </tbody>
          </Table>
          <CancelConfirmBtn onClickCancel={onClickCancel} confirmText="확인" isDisabled={isDisabled} />
        </form>
      </Layout>
      {isCancelModalVisible && (
        <Modal isVisible={isCancelModalVisible}>
          <AlertModalContent onClickCancel={onClickCancelModal} onClickSave={onClickSaveModal} confrimText="확인">
            <h2>전문가 등록을 취소하시겠습니까?</h2>
            <p>
              취소 시 입력한 데이터가 <br />
              모두 삭제됩니다.
            </p>
            <p>그래도 취소하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default ExpertRegister;
