import styled from "styled-components";
import { fontStyle14 } from "styles/mixins";

const StyledThead = styled.thead`
  ${fontStyle14};
  font-weight: 700;
  width: 100%;
  height: 44px;
  background-color: ${(props) => props.theme.tableHeaderBg};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${(props) => props.theme.black};

  tr {
    border-top: 1px solid ${(props) => props.theme.tableBorder};
    border-bottom: 1px solid ${(props) => props.theme.tableBorder};
  }

  th {
    height: 44px;
    position: relative;
    vertical-align: middle;
    text-align: left;
    padding: 0 10px 0 15px;
    :not(:last-of-type)::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 20px;
      background-color: #ddd;
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
`;

const Thead = ({ children }) => {
  return <StyledThead>{children}</StyledThead>;
};

export default Thead;
