import { useState } from "react";
import Layout from "layouts/Layout";
import CustomSelect from "components/common/Select";
import Input from "components/common/Input";
import Textarea from "components/common/Textarea";
import FileUpload from "components/common/FileUpload";
import ToggleBtn from "components/common/ToggleBtn";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import { Table, PageHeading, SubHeading, TempSaveBtn, BtnWrapper, HelperText } from "../../keyword/keyword-register/styles";
import { templateTypeOption } from "utils/data";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { useNavigate, useParams } from "react-router-dom";
import { addTemplate, editTemplate } from "api/resource/template";
import { fetcherPrivate } from "utils/fetcher";
import useSWR from "swr";
import { useEffect } from "react";

const TemplateRegister = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: templateData,
    error: tempalteError,
    mutate,
  } = useSWR(params.id ? `/api/v1/admin/projects/templates/${params.id}` : null, (url) => fetcherPrivate(url), {
    revalidateOnFocus: false,
  });

  // 구분
  const [templateType, setTemplateType] = useState();
  const onChangeTemplateType = (value) => setTemplateType(value);

  // 템플릿 명
  const [templateName, setTemplateName] = useState("");
  const onChangeTemplateName = (e) => setTemplateName(e.target.value);

  // 템플릿 설명
  const [templateDesc, setTemplateDesc] = useState("");
  const onChangeTemplateDesc = (e) => setTemplateDesc(e.target.value);

  const [businessModelType, setBusinessModelType] = useState("");
  const onChangeBusinessModelType = (e) => setBusinessModelType(e.target.value);

  // 이미지
  const [file, setFile] = useState();

  //템플릿 -  노출
  const [toggled, setToggled] = useState(false);
  const onToggleShow = (id, toggled) => {
    setToggled(toggled);
  };

  useEffect(() => {
    if (templateData) {
      const { name, description, exposure, templateType, businessModelType } = templateData.result;

      setTemplateName(name);
      setTemplateDesc(description);
      setToggled(exposure);
      onChangeTemplateType(templateType);
      setBusinessModelType(businessModelType);
    }
  }, [templateData]);

  // 임시 저장 버튼
  const onClickTempSave = () => {};

  // 취소 버튼 - 취소 모달
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const onClickCancel = () => {
    setIsCancelModalVisible(true);
  };

  // 취소 alert 모달 안 버튼 클릭 이벤트
  const onClickCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  const onClickSaveModal = () => {
    setIsCancelModalVisible(false);
    navigate(-1);
  };

  // 저장 버튼 - 저장 모달
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const onCancelSaveModal = () => setIsSaveModalVisible(false);

  const onConfirmSaveModal = async (tempSave = false) => {
    const data = {
      templateType,
      name: templateName,
      // serviceName: "취급주의",
      businessModelType,
      description: templateDesc,
      exposure: toggled,
      tempSave,
    };

    const formData = new FormData();

    const req = new Blob([JSON.stringify(data)], { type: "application/json" });

    formData.append("req", req);

    const isEditPage = params && templateData;

    if ((isEditPage && file) || !isEditPage) {
      formData.append("templateImage", file);
    }

    try {
      const res = isEditPage ? await editTemplate(params?.id, formData) : await addTemplate(formData);

      if (res.status === 200) {
        navigate("/resource/template?page=1&size=20&direction=DESC");
      }
    } catch (error) {
      console.log(error);
    }

    setIsSaveModalVisible(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setIsSaveModalVisible(true);
  };

  return (
    <>
      <Layout defaultOpenKeys={["sub2"]} defaultMenu={["6"]}>
        <PageHeading>템플릿 {params.id ? "편집" : "등록"}</PageHeading>
        <SubHeading>템플릿 정보</SubHeading>
        <form onSubmit={onSubmit}>
          <Table border="0">
            <tbody>
              {templateData?.result?.tempSave === true ? (
                <Tr>
                  <RowThTd th="등록 상태">
                    <div>임시저장</div>
                  </RowThTd>
                </Tr>
              ) : null}

              <Tr>
                <RowThTd th="구분">
                  <CustomSelect optionList={templateTypeOption} placeholder="템플릿 유형을 선택해주세요" onChange={onChangeTemplateType} value={templateType} />
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="템플릿 명">
                  <Input placeholder="키워드를 입력하세요." id="template-name" value={templateName} onChange={onChangeTemplateName} />
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="비즈니스 모델 유형">
                  <Input placeholder="비즈니스 모델 유형을 입력하세요." id="template-businessModelType" value={businessModelType} onChange={onChangeBusinessModelType} />
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="템플릿 설명">
                  <Textarea id="template-desc" placeholder="소개글을 자유롭게 입력해주세요" value={templateDesc} onChange={onChangeTemplateDesc} />
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="이미지">
                  <FileUpload id="template-file" file={file} setFile={setFile} imgSrc={templateData?.result?.templateImage} isBig />
                  <HelperText>* 한 장당 10MB 이하, 최대 1장 / jpg(jpeg),png, gif</HelperText>
                  <HelperText>* 비즈니스, 프로덕트 키워드는 이미지를 삽입할 수 없습니다.</HelperText>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="노출">
                  <ToggleBtn id="template-show" onToggle={onToggleShow} showing={toggled} isBig />
                  <HelperText>* 노출하지 않으면 사용자에게 표시되지 않습니다.</HelperText>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="등록일">
                  <div>{templateData?.result?.joinDate}</div>
                </RowThTd>
              </Tr>
            </tbody>
          </Table>
          <BtnWrapper>
            <TempSaveBtn type="button" onClick={() => onConfirmSaveModal(true)}>
              임시 저장
            </TempSaveBtn>
            <div>
              <CancelConfirmBtn onClickCancel={onClickCancel} confirmText={params.id ? "수정" : "저장"} />
            </div>
          </BtnWrapper>
        </form>
      </Layout>

      {isCancelModalVisible && (
        <Modal isVisible={isCancelModalVisible}>
          <AlertModalContent onClickCancel={onClickCancelModal} onClickSave={onClickSaveModal} confrimText={"확인"}>
            <h2>정말 취소하시겠습니까?</h2>
            <p>
              저장 전 취소 시 입력한 데이터가 <br />
              모두 삭제됩니다.
            </p>
            <p>그래도 취소하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}

      {isSaveModalVisible && (
        <Modal isVisible={isSaveModalVisible}>
          <AlertModalContent onClickCancel={onCancelSaveModal} onClickSave={() => onConfirmSaveModal()}>
            <h2>템플릿을 등록하시겠습니까?</h2>
            <p>
              대표님들에게 새로운 템플릿을 <br />
              제공할 준비를 마쳤습니다.
            </p>
            <p>지금 바로 제공하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default TemplateRegister;
