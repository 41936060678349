import { Th, Td } from "./styles";

const RowThTd = ({ isRequired, th, children }) => {
  return (
    <>
      <Th scope="row">
        <div>
          {isRequired && <span>*</span>}
          {th}
        </div>
      </Th>
      <Td>
        <div>{children}</div>
      </Td>
    </>
  );
};

export default RowThTd;
