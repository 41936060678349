import styled from "styled-components";
import { fontStyle28, flexbox, fontStyle14, tableBase, fontStyle24, fontStyle20, fontStyle16 } from "styles/mixins";

export const Heading = styled.h1`
  ${fontStyle28};
  font-weight: 700;
  margin: 0 0 35px 0;
`;

export const SubHeadingWrapper = styled.div`
  ${flexbox("space-between", "center")};
  width: 1050px;
  margin: 0 0 10px 0;

  h2 {
    ${fontStyle24};
    font-weight: 700;
  }
`;

export const BtnWrapper = styled.div`
  ${flexbox("space-between", "center")};

  a,
  button {
    ${flexbox("center", "center")};
    ${fontStyle14};
    font-weight: 500;
    width: 74px;
    height: 34px;
    border-radius: 120px;
    transition: background-color 300ms ease-in-out;
  }

  a {
    margin: 0 8px 0 0;
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    &:hover,
    &:focus {
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.blueDark};
    }
  }

  button {
    background-color: ${(props) => props.theme.buttonBgGrey};

    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.buttonBgGreyHover};
    }
  }
`;

// 기본 정보 테이블

export const BaseInfoTable = styled.table`
  ${tableBase};
`;

// period picker

export const PickerWrapper = styled.div`
  width: 1050px;
  padding: 20px;
  border-top: 1px solid #181818;
  background-color: #f2f2f2;
`;

// 프로젝트 현황

export const CurrentHeadingWrapper = styled.div`
  ${flexbox("space-between", "center")};
  width: 1050px;
  margin: 60px 0 15px;

  h3 {
    ${fontStyle20};
    font-weight: 700;
  }

  button {
    ${flexbox("center", "center")};
    ${fontStyle14};
    font-weight: 500;
    height: 34px;
    width: 120px;
    border-radius: 120px;
    background-color: ${(props) => props.theme.buttonBgBlack};
    color: ${(props) => props.theme.white};
    transition: background-color 300ms ease-in-out;

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.black};
    }
  }
`;

export const ResultHeadingWrapper = styled.div`
  ${flexbox("space-between", "center")};
  width: 1050px;
  margin: 30px 0 10px 0;

  h4 {
    ${fontStyle16};
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }
`;
