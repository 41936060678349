import { useState, useEffect } from "react";
import Layout from "layouts/Layout";
import CustomBreadcrumb from "components/common/Breadcrumb";
import Input from "components/common/Input";
import FileUpload from "components/common/FileUpload";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import CustomSelect from "components/common/Select";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import Autocomplete from "components/common/Autocomplete";
import ToggleBtn from "components/common/ToggleBtn";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import {
  PageHeading,
  SubHeading,
  Table,
  AppIconWrapper,
  ViewWrapper,
  View,
  CheckboxWrapper,
  Checkbox,
  InfoWrapper,
  SearchWrapper,
  KeywordList,
  KeywordTag,
  ToggleWrapper,
  BtnWrapper,
  TempSaveBtn,
  ImageInput,
} from "./styles";
import { benchmarkingKeywordOption } from "utils/data";
import { addBenchMarking, editBenchMarking } from "api/resource/benchmarking";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useMemo } from "react";

const KEYWORD_MAX = 3;

const BenchmarkingRegister = () => {
  const navigate = useNavigate();
  const breadcrumbItem = [
    {
      name: "리소스 관리",
    },
    {
      name: "벤치마킹 리소스 관리",
    },
    {
      name: "벤치마킹 리소스 등록",
    },
  ];

  const params = useParams();
  const [keywordOption, setKeywordOption] = useState("BUSINESS");

  const {
    data: benchmarkingData,
    error: benchmarkingError,
    mutate,
  } = useSWR(params.id ? `/api/v1/admin/projects/benchmarks/${params.id}` : null, (url) => fetcherPrivate(url), {
    revalidateOnFocus: false,
  });

  const {
    data: keywordData,
    error: keywordError,
    mutate: keywordMutate,
  } = useSWR(`/api/v1/admin/projects/keywords?&keywordType=${keywordOption}&page=1&size=50&direction=DESC`, (url) => fetcherPrivate(url), {
    revalidateOnFocus: false,
  });

  const keywordLists = keywordData?.result.content;

  //리소스 명
  const [tempSave, setTempSave] = useState(false);

  // 앱 아이콘
  const [file, setFile] = useState(null);

  //리소스 명
  const [name, setName] = useState("");
  const onChangeName = (e) => setName(e.target.value);

  // ios,aos
  const [device, setDevice] = useState({
    aos: false,
    ios: false,
  });
  const { aos, ios } = device;
  const onCheckedDevice = (e) => {
    const { name, checked } = e.target;

    setDevice({
      ...device,
      [name]: checked,
    });
  };

  //이미지
  const [homeFile, setHomeFile] = useState();
  const [searchFile, setSearchFile] = useState();
  const [detailFile, setDetailFile] = useState();
  const [buyFile, setBuyFile] = useState();
  const [reviewFile, setReviewFile] = useState();

  //이미지 이름
  const [homeName, setHomeName] = useState("");
  const [searchName, setSearchame] = useState("");
  const [detailName, setDetailName] = useState("");
  const [buyName, setBuyName] = useState("");
  const [reviewName, setReviewName] = useState("");

  //평점
  const [rate, setRate] = useState("");
  const onChangeRate = (e) => setRate(e.target.value);

  //누적 다운로드
  const [download, setDownload] = useState("");
  const onChangeDownload = (e) => setDownload(e.target.value);

  useEffect(() => {
    if (!params.id) {
      setHomeName("홈");

      setSearchame("탐색");

      setDetailName("상세");

      setBuyName("구매");

      setReviewName("리뷰");
    }
  }, [params]);

  useEffect(() => {
    if (benchmarkingData) {
      console.info(benchmarkingData);

      const { name, ios, android, starRating, exposure, downloadCount, benchmarkImages, tempSave } = benchmarkingData.result;

      setTempSave(tempSave);
      setName(name);
      setDevice({
        ios,
        aos: android,
      });
      setRate(starRating);
      setDownload(downloadCount);
      setIsExposure(exposure);

      benchmarkImages?.forEach((value, index) => {
        switch (value.priority) {
          case 1:
            setHomeName(value.imageName);
            break;
          case 2:
            setSearchame(value.imageName);
            break;
          case 3:
            setDetailName(value.imageName);
            break;
          case 4:
            setBuyName(value.imageName);
            break;
          case 5:
            setReviewName(value.imageName);
            break;
          default:
            break;
        }
      });
    }
  }, [benchmarkingData]);

  useEffect(() => {
    if (benchmarkingData && keywordData) {
      const { benchmarkKeywords } = benchmarkingData.result;

      if (benchmarkKeywords) {
        setKeywordArr(benchmarkKeywords?.map((value) => value.keywordName));
        setKeywordIdArr(benchmarkKeywords?.map((value) => value.id));
      }
    }
  }, [benchmarkingData]);

  // -----------------------------------------------------------------------

  const onChangeKeywordOption = (value) => {
    setKeywordOption(value);
  };

  const sortedKeywordList = useMemo(
    () => (keywordData?.result ? keywordLists.filter((value) => (keywordOption ? value.keywordType === keywordOption && value.exposure === true : value)) : []),
    [keywordLists, keywordOption]
  );

  //const sortedListsId = () => keywordArr.map((value) => keywordLists.find((res) => res.name === value)?.id);

  useEffect(() => {
    keywordMutate();
  }, [keywordOption]);

  //키워드 - 선택한 키워드 배열
  const [keywordArr, setKeywordArr] = useState([]);
  const [keywordIdArr, setKeywordIdArr] = useState([]);

  // 키워드 추가 하기 버튼 클릭
  const onClickAddKeyword = (keyword) => {
    const oldArr = [...keywordArr];
    const oldIdArr = [...keywordIdArr];
    let newArr;
    let newArrId;
    let cnt = sortedKeywordList.filter((item) => item.name === keyword).length;
    if (cnt == 0) {
      return;
    }

    let newId = keywordLists.find((res) => res.name === keyword)?.id;
    if (!oldArr.includes(keyword)) {
      newArr = [...oldArr, keyword];
      newArrId = [...oldIdArr, newId];
    } else {
      newArr = [...oldArr];
      newArrId = [...oldIdArr];
    }
    setKeywordArr(newArr);
    setKeywordIdArr(newArrId);
  };

  // 키워드 삭제 클릭
  const onClickDeleteKeyword = (keyword, id) => {
    const oldArr = [...keywordArr];
    const newArr = oldArr.filter((item) => item !== keyword);
    setKeywordArr(newArr);

    const oldIdArr = [...keywordIdArr];
    const newIdArr = oldIdArr.filter((item) => item !== id);
    setKeywordIdArr(newIdArr);
  };

  // 키워드 - 추가하기 버튼 disable
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  useEffect(() => {
    if (keywordArr?.length >= KEYWORD_MAX) {
      setIsAddDisabled(true);
    } else {
      setIsAddDisabled(false);
    }
  }, [keywordArr]);

  // -----------------------------------------------------------------------
  // 노출

  const [isExposure, setIsExposure] = useState(false);
  const onToggleShow = (id, toggled) => {
    setIsExposure(toggled);
    // console.log(id, toggled);
  };

  // 버튼
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const onClickCancel = () => {
    setIsCancelModalVisible(true);
  };

  // 취소 alert 모달 안 버튼 클릭 이벤트
  const onClickCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  const onClickSaveModal = () => {
    setIsCancelModalVisible(false);
    navigate(-1);
  };

  const onSubmit = async (e, tempSave) => {
    e.preventDefault();

    // todo
    // data 값 실데이터 연동 필요
    // 유효성 검사 필요
    //

    if (typeof tempSave == "undefined") {
      tempSave = false;
    }

    const formData = new FormData();

    if (!name) {
      alert("리소스 명을 입력해주세요.");
      onCancelSaveModal();

      return;
    }

    formData.append("name", name);
    formData.append("ios", ios);
    formData.append("android", aos);
    formData.append("starRating", rate);
    formData.append("downloadCount", download);

    keywordIdArr.forEach((value) => {
      formData.append("keywordIds", value + "");
    });

    formData.append("exposure", isExposure);
    formData.append("tempSave", tempSave);

    const isEditPage = params && benchmarkingData;

    formData.append("images[0].imageName", homeName);
    formData.append("images[0].priority", "1");

    formData.append("images[1].imageName", searchName);
    formData.append("images[1].priority", "2");

    formData.append("images[2].imageName", detailName);
    formData.append("images[2].priority", "3");

    formData.append("images[3].imageName", buyName);
    formData.append("images[3].priority", "4");

    formData.append("images[4].imageName", reviewName);
    formData.append("images[4].priority", "5");

    if (!isEditPage) {
      if (!file || !homeFile || !searchFile || !detailFile || !buyFile || !reviewFile) return;
      formData.append("icon", file);

      formData.append("images[0].image", homeFile);
      formData.append("images[1].image", searchFile);
      formData.append("images[2].image", detailFile);
      formData.append("images[3].image", buyFile);
      formData.append("images[4].image", reviewFile);
    } else {
      const { benchmarkImages } = benchmarkingData.result;
      if (file) formData.append("icon", file);
      // 짜증

      homeFile ? formData.append("images[0].image", homeFile) : formData.append("images[0].imageId", benchmarkImages[0].imageId);
      searchFile ? formData.append("images[1].image", searchFile) : formData.append("images[1].imageId", benchmarkImages[1].imageId);
      detailFile ? formData.append("images[2].image", detailFile) : formData.append("images[2].imageId", benchmarkImages[2].imageId);
      buyFile ? formData.append("images[3].image", buyFile) : formData.append("images[3].imageId", benchmarkImages[3].imageId);
      reviewFile ? formData.append("images[4].image", reviewFile) : formData.append("images[4].imageId", benchmarkImages[4].imageId);
    }

    try {
      const res = isEditPage ? await editBenchMarking(params?.id, formData) : await addBenchMarking(formData);
      if (res.status === 200) {
        navigate("/resource/benchmarking?page=1&size=20&direction=DESC");
      }
    } catch (error) {
      console.log(error);
      console.log(error.response.data.resultMsg);
    }
  };
  const isEditPage = Boolean(params && benchmarkingData);
  // 저장 버튼 - 저장 모달
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const onCancelSaveModal = () => setIsSaveModalVisible(false);

  const openSaveModal = (e) => {
    e.preventDefault();
    setIsSaveModalVisible(true);
  };
  return (
    <>
      <Layout defaultOpenKeys={["sub2"]} defaultMenu={["4"]}>
        <CustomBreadcrumb item={breadcrumbItem} />
        <PageHeading>벤치마킹 리소스 {params.id ? "편집" : "등록"}</PageHeading>
        <SubHeading>리소스 정보</SubHeading>
        <form onSubmit={openSaveModal}>
          <Table border="0">
            <tbody>
              <Tr>
                <RowThTd th="상태">{params.id ? (tempSave ? "임시 저장" : "등록") : ""}</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="앱 아이콘">
                  <AppIconWrapper>
                    <FileUpload id="app-icon" file={file} setFile={setFile} isSmall imgSrc={benchmarkingData?.result?.benchmarkIcon} />
                    <div className="desc">
                      <p>* 대표 사진은 1080 x 720px에 최적화</p>
                      <p>* 한 장당 10MB 이하, 최대 1장</p>
                    </div>
                  </AppIconWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="리소스 명">
                  <Input id="name" placeholder="리소스 명을 입력해주세요." value={name} onChange={onChangeName} />
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="등록 유형">관리자</RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="이미지">
                  <ViewWrapper>
                    <View>
                      <ImageInput type="input" value={homeName} onChange={(e) => setHomeName(e.target.value)} />
                      <FileUpload id="home-file" file={homeFile} setFile={setHomeFile} imgSrc={benchmarkingData?.result?.benchmarkImages ? benchmarkingData?.result?.benchmarkImages[0].image : null} />
                    </View>
                    <View>
                      {/* <p>탐색</p> */}
                      <ImageInput type="input" value={searchName} onChange={(e) => setSearchame(e.target.value)} />
                      <FileUpload
                        id="search-file"
                        file={searchFile}
                        setFile={setSearchFile}
                        imgSrc={benchmarkingData?.result?.benchmarkImages ? benchmarkingData?.result?.benchmarkImages[1].image : null}
                      />
                    </View>
                    <View>
                      <ImageInput type="input" value={detailName} onChange={(e) => setDetailName(e.target.value)} />
                      <FileUpload
                        id="detail-file"
                        file={detailFile}
                        setFile={setDetailFile}
                        imgSrc={benchmarkingData?.result?.benchmarkImages ? benchmarkingData?.result?.benchmarkImages[2].image : null}
                      />
                    </View>
                    <View>
                      <ImageInput type="input" value={buyName} onChange={(e) => setBuyName(e.target.value)} />
                      <FileUpload id="buy-file" file={buyFile} setFile={setBuyFile} imgSrc={benchmarkingData?.result?.benchmarkImages ? benchmarkingData?.result?.benchmarkImages[3].image : null} />
                    </View>
                    <View>
                      <ImageInput type="input" value={reviewName} onChange={(e) => setReviewName(e.target.value)} />
                      <FileUpload
                        id="review-file"
                        file={reviewFile}
                        setFile={setReviewFile}
                        imgSrc={benchmarkingData?.result?.benchmarkImages ? benchmarkingData?.result?.benchmarkImages[4].image : null}
                      />
                    </View>
                  </ViewWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="정보">
                  {/* 체크박스 */}
                  <CheckboxWrapper>
                    <Checkbox>
                      <input type="checkbox" id="aos" name="aos" onChange={onCheckedDevice} checked={aos} />
                      <label htmlFor="aos">Android</label>
                    </Checkbox>
                    <Checkbox>
                      <input type="checkbox" id="ios" name="ios" onChange={onCheckedDevice} checked={ios} />
                      <label htmlFor="ios">IOS</label>
                    </Checkbox>
                  </CheckboxWrapper>
                  {/* 평점 */}
                  <div>
                    <InfoWrapper>
                      <label htmlFor="rate">평점</label>
                      <Input id="rate" placeholder="평점을 입력하세요." value={rate} onChange={onChangeRate} />
                    </InfoWrapper>
                    <InfoWrapper>
                      <label htmlFor="download">누적 다운로드</label>
                      <Input id="download" placeholder="다운로드 수를 입력하세요." value={download} onChange={onChangeDownload} />
                      <p>회 이상</p>
                    </InfoWrapper>
                  </div>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="키워드">
                  <SearchWrapper>
                    <CustomSelect
                      optionList={benchmarkingKeywordOption}
                      onChange={onChangeKeywordOption}
                      style={{ width: "170px", height: "40px" }}
                      placeholder="키워드를 선택하세요"
                      value={keywordOption}
                    />
                    <Autocomplete optionList={sortedKeywordList} onClickAddKeyword={onClickAddKeyword} isDisabled={isAddDisabled} placeholder="키워드를 입력하세요" />
                  </SearchWrapper>
                  <KeywordList>
                    {Array(KEYWORD_MAX)
                      .fill()
                      .map((_, i) => (
                        <li key={i}>
                          <p> 키워드 {i + 1}: </p>
                          {keywordArr[i] ? (
                            <KeywordTag index={i}>
                              <span>{keywordArr[i]}</span>
                              <button type="button" onClick={() => onClickDeleteKeyword(keywordArr[i], keywordIdArr[i])}></button>
                            </KeywordTag>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                  </KeywordList>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="노출">
                  <ToggleWrapper>
                    <ToggleBtn id="toggle-id" onToggle={onToggleShow} isBig showing={isExposure} />
                    <p>* 노출하지 않으면 사용자에게 표시되지 않습니다.</p>
                  </ToggleWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="등록일">{benchmarkingData?.result?.modifiedDate}</RowThTd>
              </Tr>
            </tbody>
          </Table>
          <BtnWrapper>
            <TempSaveBtn type="button" onClick={(e) => onSubmit(e, true)}>
              임시 저장
            </TempSaveBtn>
            <div>
              <CancelConfirmBtn onClickCancel={onClickCancel} confirmText={params.id ? "수정" : "저장"} />
            </div>
          </BtnWrapper>
        </form>
      </Layout>
      {isCancelModalVisible && (
        <Modal isVisible={isCancelModalVisible}>
          <AlertModalContent onClickCancel={onClickCancelModal} onClickSave={onClickSaveModal} confrimText={"확인"}>
            <h2>리소스 등록을 취소하시겠습니까?</h2>
            <p>
              취소시 입력한 데이터가 <br />
              모두 삭제됩니다.
            </p>
            <p>그래도 취소하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}

      {isSaveModalVisible && (
        <Modal isVisible={isSaveModalVisible}>
          <AlertModalContent onClickCancel={onCancelSaveModal} onClickSave={onSubmit}>
            <h2>벤치마킹을 {isEditPage ? "수정" : "등록"}하시겠습니까?</h2>
            <p>
              대표님들에게 새로운 키워드를 <br />
              제공할 준비를 마쳤습니다.
            </p>
            <p>지금 바로 제공하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default BenchmarkingRegister;
