import styled from "styled-components";
import { tableBase, flexbox, fontStyle20, fontStyle28, fontStyle12, flexboxColumn, fontStyle14 } from "styles/mixins";

export const PageHeading = styled.h1`
  ${fontStyle28};
  margin: 0 0 20px 0;
  font-weight: 700;
  color: ${(props) => props.theme.black};
`;

export const SubHeading = styled.h2`
  margin: 45px 0 10px 0;
  font-weight: 700;
  ${fontStyle20};
  color: ${(props) => props.theme.black};
`;

export const Table = styled.table`
  ${tableBase};
`;

export const EmailWrapper = styled.div`
  ${flexbox("flex-start", "center")};

  input {
    width: 180px !important;
  }

  span {
    ${fontStyle12};
    margin: 0 7px;
  }
`;

export const EmailCheckBtn = styled.button`
  ${fontStyle12};
  font-weight: 700;
  text-decoration: underline;
  color: ${(props) => props.theme.blue};
  align-self: flex-end;
  margin: 0 0 0 10px;
  text-align: left;
  transition: color 300ms ease-in-out;

  :hover,
  :active {
    color: ${(props) => props.theme.blueDark};
  }
`;

export const BirthWrapper = styled.div`
  ${flexbox("flex-start", "center")};

  > div {
    width: 100px !important;

    :not(:last-of-type) {
      margin: 0 10px 0 0;
    }
  }
`;

export const InputWrapper = styled.div`
  ${flexbox};
  small {
    ${fontStyle12};
    align-self: flex-end;
    margin: 0 0 0 8px;
  }
`;

export const FileWrapper = styled.div`
  > div.desc {
    align-self: flex-end;
    ${flexboxColumn("flex-start", "flex-start")};
    margin: 0 0 3px 8px;
  }
  p {
    font-size: 11px;
    line-height: 15px;
  }
`;

export const HelperText = styled.p`
  ${fontStyle12};
  font-weight: 500;
  color: ${(props) => (props.isSafe ? props.theme.blue : props.theme.orange)};
`;

export const EmailInfoText = styled.p`
  ${fontStyle12};
  font-weight: 400;
  color: #000;
`;
