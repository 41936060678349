import styled from "styled-components";
import { fontStyle13, flexbox, truncate } from "styles/mixins";

const StyledTbody = styled.tbody`
  ${fontStyle13};
  font-weight: 500;

  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  color: #353535;

  tr {
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    ${truncate};
    vertical-align: middle;
    height: 40px;
    padding: 10px 15px;

    &.with-btn > div {
      ${flexbox("space-between", "center")};
    }
  }
`;

const Tbody = ({ children }) => {
  return <StyledTbody>{children}</StyledTbody>;
};

export default Tbody;
