import { useEffect, useState } from "react";
import moment from "moment";
import Layout from "layouts/Layout";
import SearchBox from "components/common/SearchBox";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import ToggleBtn from "components/common/ToggleBtn";
import EditLink from "components/common/EditLink";
import DeleteBtn from "components/common/DeleteBtn";
import Pagination from "components/common/Pagination";
import TableSortTooltip from "components/common/TableSortTooltip";
import { DownOutlined } from "@ant-design/icons";
import { benchSearchOption } from "utils/data";
import { getSortingCriteria } from "utils/getSortingCriteria";
import {
  PageHeading,
  RegisterLink,
  ResultHeading,
  Table,
  FlexWrapper,
  AppIcon,
  BtnWrapper,
  MockImage,
  SortBtnWrapper,
} from "./styles";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";

// mock data

import { benchMockData } from "utils/mockData";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { deleteBenchMarking } from "api/resource/benchmarking";
import { useLocation } from "react-router-dom";
import { qsParses } from "utils/queryHandler";

const BenchmarkingTab = ({ currentTab }) => {
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [resultData, setResultData] = useState([]);
  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  //검색 설정 필요

  const { search } = useLocation();

  const {
    data: benchmarkList,
    error: benchmarkError,
    mutate,
  } = useSWR(
    `/api/v1/admin/projects/benchmarks?page=${currentPage}&size=20`,
    (url) =>
      fetcherPrivate(url, {
        params: search
          ? { ...qsParses(search), registerType: currentTab }
          : { page: 1, size: 20, direction: "DESC" },
      }),
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    benchmarkList && setResultData(benchmarkList.result.content);
  }, [benchmarkList]);
  //todo page 훅으로 빼도될듯
  useEffect(() => {
    if (search) {
      mutate();

      const { page } = qsParses(search);

      if (page) setCurrentPage(+page);
    }
  }, [search, currentTab]);

  // 테이블 ----------------------------------------------------------------
  // 테이블 - 등록 유형별 정렬
  const registerSortArr = ["default", "admin", "user"];
  const [registerSortType, setRegisterSortType] = useState("default");
  const [isRegisterTooltipVisible, setIsRegisterTooltipVisible] =
    useState(false);
  const onClickRegisterTypeHead = () => {
    const nextSortType = getSortingCriteria(registerSortType, registerSortArr);
    setRegisterSortType(nextSortType);
    setIsRegisterTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };
  useEffect(() => {
    if (benchmarkList) {
      if (registerSortType === "default") {
        setResultData(benchmarkList.result.content);
      } else {
        const result = benchmarkList.result.content.filter((person) =>
          person.registerType
            .toString()
            .includes(registerSortType.toLocaleUpperCase())
        );
        setResultData(result);
      }
    }
  }, [registerSortType]);

  // 테이블 - 토글 여부별 정렬
  const toggleSortArr = ["default", "true", "false"];
  const [toggleSortType, setToggleSortType] = useState("default");
  const [isToggleTooltipVisible, setIsToggleTooltipVisible] = useState(false);
  const onClickToggleHead = () => {
    const nextSortType = getSortingCriteria(toggleSortType, toggleSortArr);
    setToggleSortType(nextSortType);
    setIsToggleTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };
  useEffect(() => {
    /* 필터로 사용시
    if (benchmarkList) {
      if (benchmarkList && toggleSortType === "default") {
        setResultData(benchmarkList.result.content);
      } else {
        let result = benchmarkList.result.content.filter((person) => person.exposure.toString().includes(toggleSortType.toLocaleLowerCase()));
        setResultData(result);
      }
    }*/
    if (benchmarkList) {
      if (toggleSortType === "default") {
        let temp = [...benchmarkList.result.content];
        let result = temp.sort((a, b) => a.exposure - b.exposure);
        setResultData(result);
      } else if (toggleSortType === "false") {
        let temp = [...resultData];
        let result = temp.sort((a, b) => a.exposure - b.exposure);
        setResultData(result);
      } else {
        let temp = [...resultData];
        let result = temp.sort((a, b) => b.exposure - a.exposure);
        setResultData(result);
      }
    }
  }, [toggleSortType]);

  // 테이블 - 등록일 순 정렬
  const dateSortArr = ["default", "latest", "oldest"];
  const [dateSortType, setDateSortType] = useState("default");
  const [isDateTooltipVisible, setIsDateTooltipVisible] = useState(false);
  const onClickDateHead = () => {
    const nextSortType = getSortingCriteria(dateSortType, dateSortArr);
    setDateSortType(nextSortType);
    setIsDateTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };

  useEffect(() => {
    if (benchmarkList) {
      if (dateSortType === "default") {
        let temp = [...resultData];
        let result = temp.sort(
          (a, b) => new Date(b.joinDate) - new Date(a.joinDate)
        );
        setResultData(result);
      } else if (dateSortType === "latest") {
        let temp = [...resultData];
        let result = temp.sort(
          (a, b) => new Date(b.joinDate) - new Date(a.joinDate)
        );
        setResultData(result);
      } else {
        let temp = [...resultData];
        let result = temp.sort(
          (a, b) => new Date(a.joinDate) - new Date(b.joinDate)
        );
        setResultData(result);
      }
    }
  }, [dateSortType]);

  // 테이블 속 특정 아이템 토글 버튼 클릭하는 경우
  const onToggleItem = (id, toggled) => {
    // console.log(id, toggled);
  };

  // 테이블 속 특정 아이템 삭제 버튼 클릭
  const onClickDelete = async (id) => {
    try {
      const res = await deleteBenchMarking(id);
      if (res) {
        mutate();
      }
    } catch (error) {
      console.log(error);
    }
  }; // 삭제 버튼 클릭 시 나타나는 alert 모달
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const [targetDelId, setTargetDelId] = useState("");

  const onClickDelBtn = (id) => {
    setIsDelModalVisible(true);
    setTargetDelId(id);
  };

  // 삭제 alert 모달의 취소, 확인
  const onClickDelCancel = () => {
    setIsDelModalVisible(false);
  };
  const onClickDelConfirm = async () => {
    try {
      const res = await deleteBenchMarking(targetDelId);
      if (res) {
        await mutate();
        setIsDelModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }

    setIsDelModalVisible(false);
  };

  return (
    <>
      {/* <PageHeading>벤치마킹 리소스 관리</PageHeading> */}
      <RegisterLink to="/resource/benchmarking/register">등록</RegisterLink>
      <SearchBox page={currentPage} optionList={benchSearchOption} />
      {benchmarkList?.result && (
        <>
          <ResultHeading>
            총 <strong>{benchmarkList?.result?.totalElements}</strong>개
          </ResultHeading>
          <Table>
            <Thead>
              <tr>
                <th style={{ width: "100px" }}>상태</th>
                <th>리소스 명</th>
                <th style={{ width: "100px" }}>
                  <SortBtnWrapper>
                    <button type="button" onClick={onClickRegisterTypeHead}>
                      등록유형&nbsp;&nbsp;
                      <DownOutlined style={{ fontSize: "10px" }} />
                    </button>
                    <TableSortTooltip
                      isTooltipVisible={isRegisterTooltipVisible}
                      setIsTooltipVisible={setIsRegisterTooltipVisible}>
                      {registerSortType === "admin"
                        ? "관리자"
                        : registerSortType === "user"
                        ? "사용자"
                        : "초기화"}
                    </TableSortTooltip>
                  </SortBtnWrapper>
                </th>
                <th>이미지</th>
                <th>키워드</th>
                <th style={{ width: "100px" }}>
                  <SortBtnWrapper>
                    <button type="button" onClick={onClickToggleHead}>
                      노출&nbsp;&nbsp;
                      <DownOutlined style={{ fontSize: "10px" }} />
                    </button>
                    <TableSortTooltip
                      isTooltipVisible={isToggleTooltipVisible}
                      setIsTooltipVisible={setIsToggleTooltipVisible}>
                      {toggleSortType === "true"
                        ? "노출"
                        : toggleSortType === "false"
                        ? "미노출"
                        : "초기화"}
                    </TableSortTooltip>
                  </SortBtnWrapper>
                </th>
                <th style={{ width: "200px" }}>
                  <SortBtnWrapper>
                    <button type="button" onClick={onClickDateHead}>
                      등록일&nbsp;&nbsp;
                      <DownOutlined style={{ fontSize: "10px" }} />
                    </button>
                    <TableSortTooltip
                      isTooltipVisible={isDateTooltipVisible}
                      setIsTooltipVisible={setIsDateTooltipVisible}>
                      {dateSortType === "latest"
                        ? "최신순"
                        : dateSortType === "oldest"
                        ? "지난 순"
                        : "초기화"}
                    </TableSortTooltip>
                  </SortBtnWrapper>
                </th>
              </tr>
            </Thead>
            <Tbody>
              {resultData &&
                resultData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.tempSave ? "임시저장" : "등록"}</td>
                    <td>
                      <FlexWrapper>
                        <AppIcon>
                          {item.icon ? (
                            <img src={item.icon} alt="app icon" />
                          ) : null}
                        </AppIcon>
                        <span>{item.name}</span>
                      </FlexWrapper>
                    </td>
                    <td>
                      {item.registerType === "ADMIN" ? "관리자" : "사용자"}
                    </td>
                    <td>
                      <FlexWrapper>
                        {item.benchmarkImages &&
                          item.benchmarkImages.map((image) => (
                            <MockImage key={image}>
                              <img src={image} key={image} alt="image" />
                            </MockImage>
                          ))}
                      </FlexWrapper>
                    </td>
                    <td>{item.keywordNames} </td>
                    <td>
                      <ToggleBtn
                        id={item.id}
                        onToggle={onToggleItem}
                        showing={item.exposure}
                        disabled
                      />
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}>
                      <div>{item.joinDate}</div>
                      <BtnWrapper>
                        {item.registerType === "MEMBER" ? (
                          ""
                        ) : (
                          <EditLink
                            to={`/resource/benchmarking/edit/${item.id}`}
                          />
                        )}
                        <DeleteBtn
                          onClick={() => {
                            onClickDelBtn(item.id);
                          }}
                        />
                      </BtnWrapper>
                    </td>
                  </tr>
                ))}
            </Tbody>
          </Table>
          {isDelModalVisible && (
            <Modal isVisible={isDelModalVisible}>
              <AlertModalContent
                onClickCancel={onClickDelCancel}
                onClickSave={onClickDelConfirm}>
                <h2>리소스를 삭제하시겠습니까?</h2>
                <p>
                  벤치마킹 리소스 삭제시 입력한 데이터가
                  <br />
                  모두 삭제됩니다.
                </p>
                <p>그래도 리소스를 삭제하시겠습니까?</p>
              </AlertModalContent>
            </Modal>
          )}
          {benchmarkList?.result ? (
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              onChange={onChangeCurrentPage}
              total={benchmarkList?.result.totalElements}
              pageSize={20}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default BenchmarkingTab;
