import styled from "styled-components";
import { flexbox, flexboxColumn, fontStyle15, fontStyle28, fontStyle20, tableBase, fontStyle14, fontStyle13, fontStyle12 } from "styles/mixins";
import checkbox_unchecked from "assets/checkbox_unchecked.svg";
import checkbox_checked from "assets/checkbox_checked.svg";
import x_black from "assets/x_black.svg";

export const PageHeading = styled.h1`
  ${fontStyle28};
  margin: 0 0 20px 0;
  font-weight: 700;
  color: ${(props) => props.theme.black};
`;

export const SubHeading = styled.h2`
  margin: 45px 0 10px 0;
  font-weight: 700;
  ${fontStyle20};
  color: ${(props) => props.theme.black};
`;

export const Table = styled.table`
  ${tableBase};
`;

export const AppIconWrapper = styled.div`
  ${flexbox};
  > div.desc {
    align-self: flex-end;
    ${flexboxColumn("flex-start", "flex-start")};
    margin: 0 0 3px 8px;
  }
  p {
    font-size: 11px;
    line-height: 15px;
  }
`;

export const ViewWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const View = styled.div`
  ${flexboxColumn("center", "center")};

  &:not(:last-of-type) {
    margin: 0 20px 0 0;
  }

  p {
    ${flexbox("center", "center")};
    ${fontStyle14};
    font-weight: 500;
    width: 100%;
    height: 34px;
    margin: 0 0 7px 0;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.border};
  }
`;

export const ImageInput = styled.input`
  padding: 6px 10px;
  margin-bottom: 15px;
  width: 150px;
  font-size: 13px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.border};
`;

export const CheckboxWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const Checkbox = styled.div`
  margin: 0 20px 0 0;

  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"] + label {
    ${fontStyle13};
    cursor: pointer;
    height: 42px;
    width: 100px;
    padding: 0 0 0 20px;
    background-image: url(${checkbox_unchecked});
    background-size: 14px 14px;
    background-position: left center;
    background-repeat: no-repeat;
  }

  input[type="checkbox"]:checked + label {
    background-image: url(${checkbox_checked});
  }
`;

// 정보
export const InfoWrapper = styled.div`
  ${flexbox("flex-start", "center")};

  margin: 20px 0 0 0;

  label {
    font-weight: 500;
    min-width: 85px;
    width: 85px;
    margin: 0 10px 0 0;
    flex-shrink: 0;
  }

  p {
    margin: 0 0 0 5px;
  }
`;

// 키워드
export const SearchWrapper = styled.div`
  ${flexbox("flex-start", "center")};

  > div:first-of-type {
    margin: 0 10px 0 0;
  }
`;

export const KeywordList = styled.ul`
  margin: 20px 0 0 180px;
  li {
    ${flexbox("flex-start", "center")};
    margin: 0 0 15px 0;

    p {
      ${fontStyle13};
    }
  }
`;

export const KeywordTag = styled.div`
  ${fontStyle13};
  display: inline-block;
  position: relative;
  border-radius: 120px;
  margin: 0 0 0 5px;
  min-width: 50px;
  padding: 3px 27px 3px 10px;
  background-color: ${(props) => (props.index === 0 ? props.theme.yellow : props.index === 1 ? props.theme.green : props.theme.blueLight)};

  button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-image: url(${x_black});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
`;

// 노출
export const ToggleWrapper = styled.div`
  p {
    ${fontStyle12};
    color: ${(props) => props.theme.helperTextGrey};
    margin: 3px 0 0 0;
  }
`;

export const BtnWrapper = styled.div`
  ${flexbox("space-between", "center")};
  margin: 30px 0 0 0;
`;

export const TempSaveBtn = styled.button`
  ${flexbox("center", "center")};
  ${fontStyle15};
  height: 44px;
  width: 180px;
  border-radius: 100px;
  font-weight: 700;

  border-radius: 120px;
  border: 1px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.white};
  transition: background-color 200ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.buttonBgWhiteHover};
  }
`;
