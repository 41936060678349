import styled from "styled-components";
import { fontStyle14 } from "styles/mixins";

export const Th = styled.th`
  width: 200px;
  max-width: 200px;
  padding: 20px 0;
  border: 0;
  background-color: ${(props) => props.theme.tableHeaderBg};
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  ${fontStyle14};
  > div {
    color: ${(props) => props.theme.black};
  }

  span {
    color: ${(props) => props.theme.orange};
  }
`;

export const Td = styled.td`
  ${fontStyle14};
  min-width: 325px;
  padding: 20px 0 20px 20px;
  border: 0;
  vertical-align: middle;
`;
