import styled from "styled-components";
import { tableBase } from "styles/mixins";

const StyledTable = styled.table`
  ${tableBase};
`;

const Table = ({ children }) => {
  return <StyledTable>{children}</StyledTable>;
};

export default Table;
