import axios from "axios";

let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.map((callback) => callback(accessToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => refreshSubscribers.push(callback);

const axiosPrivateFormData = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  },
});

axiosPrivateFormData.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivateFormData.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    // TODO
    const errorCode = error.response.data.code;

    if (errorCode === "AUTH_E_003") {
      // refresh토큰을 이용해서 accesstoken을 재발급 받을 때 refresh토큰 조차 만료 되었을 경우 발행하는 예외 처리로 로그인 창으로 보내면 됩니다. (10.12)
      window.location.href = "/";
    } else if (errorCode === "AUTH_E_011" || errorCode === "AUTH_E_009") {
      if (!isTokenRefreshing) {
        isTokenRefreshing = true;
        const oldAccessToken = window.localStorage.getItem("accessToken");
        const data = JSON.stringify({ accessToken: oldAccessToken });
        console.log("here", data);
        const resp = await axios({
          method: "post",
          baseURL: process.env.REACT_APP_API,
          url: "/api/v1/open/auth/refresh",
          headers: { "Content-Type": "application/json", Authorization: oldAccessToken },
          data,
        });

        const newAccessToken = resp.data.result.token;
        window.localStorage.setItem("accessToken", newAccessToken);
        isTokenRefreshing = false;
        axios.defaults.headers.common.Authorization = newAccessToken;
        onTokenRefreshed(newAccessToken);
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((accessToken) => {
          error.config.headers.Authorization = accessToken;
          resolve(axios(error.config));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export default axiosPrivateFormData;
