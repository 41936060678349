import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
// import { recoilKeywordTabKeyState } from "states/recoilKeywordTabKeyState";
import Layout from "layouts/Layout";
import TabMenu from "components/common/TabMenu";
import KeywordTab from "components/resource/keyword/KeywordTab";
import { PageHeading } from "./styles";

// TODO  : delete

import { useLocation, useNavigate } from "react-router-dom";
import { qsStringify, qsParses } from "utils/queryHandler";
import { recoilKeywordTabKeyState } from "states/recoilKeywordTabKeyState";

const Keyword = () => {
  // TODO : swr
  // 테이블 데이터

  // 탭 메뉴
  // todo children...?
  const navigate = useNavigate();
  const { search } = useLocation();

  const [key, setKey] = useRecoilState(recoilKeywordTabKeyState);
  const [currentTab, setCurrentTab] = useState(key.key);
  const url = new URL(window.location.href);
  const urlParams = url.searchParams;

  const keyWord = urlParams.get("keywordType");

  const onChangeCurrentKey = (key) => {
    setCurrentTab(key);
    setKey((prev) => {
      const prevState = { ...prev };
      prevState.key = key;
      return { ...prevState };
    });

    navigate(
      `?${qsStringify(
        {
          ...qsParses(search),
          keywordType: key,
          name: null,
          serviceName: null,
          memberName: null,
          allSearch: null,
        },
        { skipNulls: true, strictNullHandling: true }
      )}`
    );
  };

  useEffect(() => {
    onChangeCurrentKey(keyWord);
  }, []);

  const items = [
    {
      label: "비즈니스 유형",
      key: "BUSINESS",
      children: <KeywordTab currentTab={currentTab} />,
    },
    {
      label: "프로덕트 분야",
      key: "PRODUCT",
      children: <KeywordTab currentTab={currentTab} />,
    },
    {
      label: "핵심 기능",
      key: "MAIN_POINT",
      children: <KeywordTab currentTab={currentTab} />,
    },
    {
      label: "디자인 컨셉",
      key: "DESIGN_CONCEPT",
      children: <KeywordTab currentTab={currentTab} />,
    },
    {
      label: "기타",
      key: "ETC",
      children: <KeywordTab currentTab={currentTab} />,
    },
  ];

  // tab 상태 변화 관리
  // 0. currentTab key.
  // 1. recoil에 current tab key 저장하여 등록/편집 페이지로 이동했다가 돌아왔을 때, 마지막으로 보고 있던 탭이 currentTab 되도록.
  // 2. currentTab 바뀌면 데이터도 각 탭에 맞게 받아와서 넣어주기.

  return (
    <Layout defaultOpenKeys={["sub2"]} defaultMenu={["5"]}>
      <PageHeading>키워드 관리</PageHeading>
      <TabMenu
        type="card"
        items={items}
        activeKey={currentTab}
        onChange={onChangeCurrentKey}
      />
    </Layout>
  );
};

export default Keyword;
