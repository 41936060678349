import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import { Wrapper, Table, CloseBtn, ExpertList, ExpertImg, ExpertName, ExpertInfo, ExpertEmail } from "./styles";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { ImageTd } from "../SortableTable/SortableRow/styles";

const expertType = {
  manager: "매니저",
  designer: "디자이너",
  mentor: "멘토",
};

const DetailModalContent = ({ id, data, onClick }) => {
  const getKR = (type) => expertType[type];
  const { data: projectData, error: projectDataError } = useSWR(`/api/v1/admin/project/${id}`, (url) => fetcherPrivate(url));

  return (
    <Wrapper>
      <h1>프로젝트 상세</h1>
      <CloseBtn type="button" onClick={onClick} />

      <Table>
        <tbody>
          <Tr>
            <RowThTd th="기관 명">{projectData?.result.authCodeCompanyName}</RowThTd>
          </Tr>
          <Tr>
            <RowThTd th="프로젝트 명">{projectData?.result.name}</RowThTd>
          </Tr>
          <Tr>
            <RowThTd th="상태">{projectData?.result.projectStepType}</RowThTd>
            <RowThTd th="버전">{projectData?.result.version}</RowThTd>
          </Tr>
          <Tr>
            <RowThTd th="회원 명">{projectData?.result.memberName}</RowThTd>
            <RowThTd th="기업 명">{projectData?.result.companyName}</RowThTd>
          </Tr>
          <Tr>
            <RowThTd th="시작 일">{projectData?.result.projectStartDate}</RowThTd>
            <RowThTd th="종료 일">{projectData?.result.projectEndDate}</RowThTd>
          </Tr>
          <Tr>
            <RowThTd th="전문가">
              {projectData?.result?.experts?.map((item, i) => (
                <ExpertList key={i} style={{ display: "flex", width: "680px", justifyContent: "space-between", paddingRight: "40px" }}>
                  <div style={{ display: "flex" }}>
                    <ExpertImg>
                      <ImageTd>{<img src={item.expertHeadshot} />}</ImageTd>
                    </ExpertImg>
                    <ExpertInfo>
                      <ExpertName type={item.expertType}>
                        <strong>{item.name}</strong>
                      </ExpertName>
                      <ExpertEmail>{item.email}</ExpertEmail>
                    </ExpertInfo>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <strong>{item.expertType}</strong>
                  </div>
                </ExpertList>
              ))}
            </RowThTd>
          </Tr>
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default DetailModalContent;
