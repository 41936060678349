import { atom, selector } from "recoil";

const initialState = {
  key: "MANAGER",
};

export const recoilExpertTabKeyState = atom({
  key: "recoilExpertTabKeyState",
  default: initialState,
});

// export const recoilTabSelector = selector({
//   key: "recoilTabSelector",
//   get: ({ get }) => {
//     const tab = get(recoilTabState);
//     return tab;
//   },
// });
