import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import ToggleBtn from "components/common/ToggleBtn";
import DeleteBtn from "components/common/DeleteBtn";
import { useRecoilState } from "recoil";

const EtcTable = ({ data, onToggle, onClickDel }) => {
  // 삭제 버튼
  const onClickDelete = (id) => onClickDel(id);

  return (
    <Table>
      <Thead>
        <tr>
          <th>유형</th>
          <th>키워드</th>
          <th>누적 사용량</th>
          <th>노출</th>
          <th>등록일</th>
        </tr>
      </Thead>
      <Tbody>
        {data?.map((item, i) => (
          <tr key={item.id}>
            <td>{item.type}</td>
            <td>{item.keyword}</td>
            <td>{item.usage}</td>
            <td>
              <ToggleBtn id={item.id} onToggle={onToggle} />
            </td>
            <td className="with-btn">
              <div>
                <p> {item.createdAt}</p>
                <DeleteBtn
                  onClick={() => {
                    onClickDelete(item.id);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default EtcTable;
