import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const PieContainer = styled.div`
  width: 250px;
  height: 200px;

  canvas {
    position: relative;
    top: -10px;
    left: 47px;
  }
`;
