import { CustomSelect } from "./styles";
import { Select } from "antd";

const { Option } = Select;

const SelectRange = ({ defaultValue, optionArr, onChange, style }) => {
  return (
    <CustomSelect defaultValue={optionArr[0].value} onChange={onChange} style={style}>
      {optionArr.map((item, i) => (
        <Option key={i} value={item.value}>
          {item.text}
        </Option>
      ))}
    </CustomSelect>
  );
};

export default SelectRange;
