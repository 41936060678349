import styled from "styled-components";
import { flexbox, fontStyle14, fontStyle15, fontStyle18 } from "styles/mixins";

export const ModalContent = styled.div`
  width: 360px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};

  h2 {
    padding: 18px 0 14px;
    border-bottom: 1px solid #f3f3f3;
    ${fontStyle18}
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.black};
  }

  p {
    padding: 15px 0 0;
    ${fontStyle14};
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    color: ${(props) => props.theme.black};
  }
`;

export const BtnWrapper = styled.div`
  ${flexbox("center", "center")};
  width: 100%;
  padding: 20px 0;
  button {
    ${flexbox("center", "center")};
    height: 44px;
    width: 155px;
    border-radius: 100px;
    font-weight: 700;
    ${fontStyle15};
    transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out, color 300ms ease-in-out;
  }

  button:first-of-type {
    background-color: #f3f3f3;
    color: ${(props) => props.theme.black};
    margin: 0 10px 0 0;

    :hover,
    :focus {
      background-color: #d6d6d6;
    }
  }

  button:last-of-type {
    background-color: ${(props) => props.theme.blue};
    color: #ffffff;

    :hover,
    :focus {
      background-color: ${(props) => props.theme.blueDark};
    }
  }
`;
