import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { recoilKeywordTabKeySelector } from "states/recoilKeywordTabKeyState";
import SortableTable from "components/common/SortableTable";
import EtcTable from "./EtcTable";
import moment from "moment";
import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";

import { RegisterLink, ResultHeading } from "./styles";
import {
  keywordSearchOption,
  keywordSearchOptionETC,
  keywordSearchOptionMAIN_POINT,
} from "utils/data";

import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { deleteKeyword } from "api/resource/keyword";
import { useLocation, useParams } from "react-router-dom";
import { qsParses } from "utils/queryHandler";
import { useLayoutEffect } from "react";

// keywordType=PRODUCT
let pagesize = 20;
const KeywordTab = ({ currentTab }) => {
  const { search } = useLocation();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);

  if (currentTab == "BUSINESS" || currentTab == "PRODUCT") {
    pagesize = 20;
  } else if (currentTab == "MAIN_POINT" || currentTab == "DESIGN_CONCEPT") {
    pagesize = 10;
  } else {
    pagesize = 14;
  }

  const {
    data: keywordList,
    isLoading: keywordLoading,
    isValidating,
    error: keywordError,
    mutate,
  } = useSWR(
    `/api/v1/admin/projects/keywords?page=${currentPage}&size=${pagesize}`,
    (url) =>
      fetcherPrivate(url, {
        params: search
          ? {
              ...qsParses(search),
              registerType: currentTab === "ETC" ? "MEMBER" : "ADMIN",
              keywordType: currentTab === "ETC" ? "" : currentTab,
            }
          : { page: 1, size: pagesize, direction: "DESC" },
      })
  );
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (search) {
      mutate();

      const { page } = qsParses(search);

      if (page) setCurrentPage(+page);
    }
  }, [search, currentTab]);

  useLayoutEffect(() => {
    if (keywordList) {
      console.log(keywordList, "키워드 릭스트");
      setTableData(keywordList.result?.content);
    }
  }, [keywordList]);

  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  // // 검색 소팅

  // 토글 버튼
  const onToggle = (id, blocked) => {
    // console.log(id, blocked);
  };

  // 삭제 버튼 클릭 시 나타나는 alert 모달
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const [targetDelId, setTargetDelId] = useState("");

  const onClickDelBtn = (id) => {
    setIsDelModalVisible(true);
    setTargetDelId(id);
  };

  // 삭제 alert 모달의 취소, 확인
  const onClickDelCancel = () => {
    setIsDelModalVisible(false);
  };

  const onClickDelConfirm = async () => {
    try {
      const res = await deleteKeyword(targetDelId);
      if (res) {
        await mutate();
        setIsDelModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 기타 탭은 sortable 테이블 아니고 일반 테이블
  const currentKeywordTab = useRecoilValue(recoilKeywordTabKeySelector);

  return (
    <>
      <div>
        <RegisterLink to="/resource/keyword/register">등록</RegisterLink>
        <SearchBox
          page={currentPage}
          optionList={
            currentTab === "ETC"
              ? keywordSearchOptionETC
              : currentTab === "MAIN_POINT"
              ? keywordSearchOptionMAIN_POINT
              : keywordSearchOption
          }
        />

        {!isValidating && (
          <>
            <ResultHeading>
              총 <strong>{keywordList?.result?.totalElements}</strong>개
            </ResultHeading>

            {currentKeywordTab.key !== "etc" ? (
              <SortableTable
                data={tableData}
                setData={setTableData}
                onToggle={onToggle}
                onClickDel={onClickDelBtn}
                keywordList={keywordList}
                currentPage="keyword"
              />
            ) : (
              <EtcTable
                data={tableData}
                onToggle={onToggle}
                onClickDel={onClickDelBtn}
              />
            )}

            {keywordList && (
              <Pagination
                defaultCurrent={1}
                current={currentPage}
                onChange={onChangeCurrentPage}
                total={keywordList?.result?.totalElements}
                pageSize={pagesize}
              />
            )}
          </>
        )}
      </div>

      {isDelModalVisible && (
        <Modal isVisible={isDelModalVisible}>
          <AlertModalContent
            onClickCancel={onClickDelCancel}
            onClickSave={onClickDelConfirm}>
            <h2>정말 삭제하시겠습니까?</h2>
            <p>
              삭제하시면 해당 키워드 내용이 <br />
              사라집니다.
            </p>
            <p>그래도 삭제하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default KeywordTab;
