import { hover } from "@testing-library/user-event/dist/hover";
import { Wrapper } from "./styles";

const RangeRadioBtn = ({ name, optionList, selectedValue, onChange }) => {
  const onChangeValue = (item) => onChange(item);

  return (
    <>
      {/* 
      <Wrapper>
        {optionList?.map((item, i) => (
          <div key={i}>
            <input type="radio" name={name} id={item.value} defaultChecked={selectedValue === item.value} value={item.value} onChange={() => onChangeValue(item.value)} />
            <label htmlFor={item.value}>{item.text}</label>
          </div>
        ))}
      </Wrapper>*/}
      {optionList?.map((item, i) => (
        <div
          key={i}
          name={name}
          id={item.value}
          style={{
            width: "80px",
            height: "40px",
            border:
              selectedValue == item.value
                ? "2px solid black"
                : "1px solid #e0e0e0",
            borderRight:
              i == 3
                ? selectedValue == item.value
                  ? "2px solid black"
                  : "1px solid #e0e0e0"
                : "0px",
            textAlign: "center",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => onChangeValue(item.value)}>
          {item.text}
        </div>
      ))}
    </>
  );
};

export default RangeRadioBtn;
