import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "utils/ProtectedRoute";
import Login from "pages/login";
import Home from "pages/home";
import List from "pages/project/list";
import Request from "pages/project/request";
import Benchmarking from "pages/resource/benchmarking";
import BenchmarkingRegister from "pages/resource/benchmarking/register";
import Keyword from "pages/resource/keyword";
import KeywordRegister from "pages/resource/keyword/keyword-register";
import Template from "pages/resource/template";
import TemplateRegister from "pages/resource/template/template-register";
import User from "pages/user";
import UserDetail from "pages/user/detail";
import Expert from "pages/expert";
import ExpertRegister from "pages/expert/register";
import ExpertDetail from "pages/expert/detail";
import Certification from "pages/certification";
import CertificationRegister from "pages/certification/register";
import Admin from "pages/admin";
import AdminRegister from "pages/admin/register";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/project/list"
        element={
          <ProtectedRoute>
            <List />
          </ProtectedRoute>
        }
      />
      <Route
        path="/project/request"
        element={
          <ProtectedRoute>
            <Request />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/benchmarking"
        element={
          <ProtectedRoute>
            <Benchmarking />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/benchmarking/edit/:id"
        element={
          <ProtectedRoute>
            <BenchmarkingRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/benchmarking/register"
        element={
          <ProtectedRoute>
            <BenchmarkingRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/keyword"
        element={
          <ProtectedRoute>
            <Keyword />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/keyword/edit/:id"
        element={
          <ProtectedRoute>
            <KeywordRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/keyword/register"
        element={
          <ProtectedRoute>
            <KeywordRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/template"
        element={
          <ProtectedRoute>
            <Template />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/template/edit/:id"
        element={
          <ProtectedRoute>
            <TemplateRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/resource/template/register"
        element={
          <ProtectedRoute>
            <TemplateRegister />
          </ProtectedRoute>
        }
      />

      <Route
        path="/user"
        element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user/detail/:id"
        element={
          <ProtectedRoute>
            <UserDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/expert"
        element={
          <ProtectedRoute>
            <Expert />
          </ProtectedRoute>
        }
      />
      <Route
        path="/expert/edit/:id"
        element={
          <ProtectedRoute>
            <ExpertRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/expert/register"
        element={
          <ProtectedRoute>
            <ExpertRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/expert/detail/:id"
        element={
          <ProtectedRoute>
            <ExpertDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/certification"
        element={
          <ProtectedRoute>
            <Certification />
          </ProtectedRoute>
        }
      />
      <Route
        path="/certification/register"
        element={
          <ProtectedRoute>
            <CertificationRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/register"
        element={
          <ProtectedRoute>
            <AdminRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/edit/:id"
        element={
          <ProtectedRoute>
            <AdminRegister />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
