import styled from "styled-components";
import password_hidden from "assets/password_hidden.svg";
import password_visible from "assets/password_visible.svg";
import { fontStyle13 } from "styles/mixins";

export const InputWrapper = styled.div`
  position: relative;

  input {
    height: 44px;
    width: 320px;
    padding: 0 38px 0 18px;
    border-radius: 4px;
    border: ${(props) => (props.isValid ? "1px solid #e0e0e0" : "1px solid #ce5e41")};
    font-weight: 500;
    ${fontStyle13};
    color: #000;
    transition: border-color 300ms ease-in-out;

    &:hover,
    &:focus,
    &:active {
      border: ${(props) => (props.isValid ? "1px solid #181818" : "1px solid #ce5e41")};
    }

    ::placeholder {
      font-weight: 500;
      ${fontStyle13};

      color: ${(props) => props.theme.placeholder};
    }
  }
`;

export const ToggleBtn = styled.button`
  position: absolute;
  top: 50%;
  left: 290px;
  transform: translateY(-50%);
  width: 16px;
  height: 12px;
  background-image: url(${(props) => (props.isPwdVisible ? password_visible : password_hidden)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;
