import styled from "styled-components";
import { flexbox, fontStyle16, fontStyle26, tableSortBtnWrapperBase } from "styles/mixins";

export const PageHeading = styled.h1`
  ${fontStyle26};
  font-weight: 700;
  margin: 20px 0 30px;
`;

export const ResultWrapper = styled.div`
  ${flexbox("space-between", "center")};
  margin: 50px 0 10px 0;

  h4 {
    ${fontStyle16};
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }
`;

export const SortBtnWrapper = styled.div`
  ${tableSortBtnWrapperBase};
`;
