import { Link } from "react-router-dom";
import logo from "assets/logo.svg";
import { Menu } from "antd";
import { StyledSider, StyledMenu, Logo } from "./styles";
const Nav = ({ defaultMenu, defaultOpenKeys }) => {
  const { SubMenu, Item } = Menu;
  const defaultSearchOption = `page=1&size=20&direction=DESC`;
  return (
    <StyledSider>
      <Logo>
        <Link to="/home">
          <img src={logo} alt="i2b logo"></img>
        </Link>
      </Logo>
      <StyledMenu mode="inline" defaultSelectedKeys={defaultMenu} defaultOpenKeys={defaultOpenKeys}>
        <Item key="1">
          <Link to="/home">홈</Link>
        </Item>
        <SubMenu key="sub1" title="프로젝트 관리">
          <Item key="2">
            <Link to="/project/list?page=1&size=20&direction=DESC">프로젝트 목록</Link>
          </Item>
          <Item key="3">
            <Link to="#">매칭 요청 목록</Link>
          </Item>
        </SubMenu>
        <SubMenu key="sub2" title="리소스 관리">
          <Item key="4">
            <Link to={`/resource/benchmarking?${defaultSearchOption}`}>벤치마킹 리소스 관리</Link>
          </Item>
          <Item key="5">
            <Link to={`/resource/keyword?${defaultSearchOption}&keywordType=BUSINESS`}>키워드 관리</Link>
          </Item>
          <Item key="6">
            <Link to={`/resource/template?${defaultSearchOption}`}>템플릿 관리</Link>
          </Item>
        </SubMenu>
        <Item key="7">
          <Link to={`/user?${defaultSearchOption}`}>회원 관리</Link>
        </Item>
        <Item key="8">
          <Link to={`/expert?${defaultSearchOption}&expertType=MANAGER`}>전문가 관리</Link>
        </Item>
        <Item key="9">
          <Link to={`/certification?${defaultSearchOption}`}>인증 코드 관리</Link>
        </Item>
        <Item key="10">
          <Link to={`/admin?${defaultSearchOption}`}>관리자 계정 관리</Link>
        </Item>
      </StyledMenu>
    </StyledSider>
  );
};

export default Nav;
