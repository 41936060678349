import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexbox, fontStyle16, fontStyle26, fontStyle14, tableBase, tableSortBtnWrapperBase } from "styles/mixins";
import avatar_mentor from "assets/avatar_mentor.svg";
import avatar_manager from "assets/avatar_manager.svg";
import avatar_designer from "assets/avatar_designer.svg";

export const PageHeading = styled.h1`
  ${fontStyle26};
  font-weight: 700;
  margin: 0 0 15px 0;
`;

export const RegisterLink = styled(Link)`
  ${flexbox("center", "center")};
  ${fontStyle14};
  font-weight: 500;
  height: 34px;
  width: 120px;
  margin: 40px 0 20px auto;
  border-radius: 120px;
  background-color: ${(props) => props.theme.buttonBgBlack};
  color: ${(props) => props.theme.white};
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:active {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.black};
  }
`;

export const ResultHeading = styled.h4`
  margin: 50px 0 10px 0;

  ${fontStyle16};
  font-weight: 500;

  strong {
    font-weight: 700;
  }
`;

export const Table = styled.table`
  ${tableBase};
`;

export const FlexWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const AppIcon = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 2px 0 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const BtnWrapper = styled.div`
  ${flexbox("center", "center")};
  margin: 5px 0 0 0;
  button {
    margin: 0 0 0 3px;
  }
`;

//

export const MockImage = styled.div`
  width: 90px;
  height: auto;
  background-color: #d9d9d9;
  :not(:last-of-type) {
    margin: 0 5px 0 0;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

// 테이블 정렬 버튼
export const SortBtnWrapper = styled.div`
  ${tableSortBtnWrapperBase};
`;
