import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  .ant-modal {
    max-width: 100vw;
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }
`;
