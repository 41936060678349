import { atom, selector } from "recoil";

const initialState = {
  key: "BUSINESS",
};

export const recoilKeywordTabKeyState = atom({
  key: "recoilKeywordTabKeyState",
  default: initialState,
});

export const recoilKeywordTabKeySelector = selector({
  key: "recoilKeywordTabKeySelector",
  get: ({ get }) => {
    const key = get(recoilKeywordTabKeyState);
    return key;
  },
});
