import { createGlobalStyle } from "styled-components";
import "antd/dist/antd.min.css";

const GlobalStyle = createGlobalStyle`

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* ---------------------------------------------------------------------------------------------------- */
/* Custom */

:root{
	--font-main:'Noto Sans KR', sans-serif;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding:0;
	font-family: var(--font-main);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
  font-family: var(--font-main);
  font-size: 14px;
}

body {
  font-family: var(--font-main);
  color:${(props) => props.theme.black};
}

a {
	text-decoration: none;
	color: inherit;
}

button,
input,
select,
textarea {
	border: 0;
	background-color: transparent;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

a,
button {
	cursor: pointer;
}

button {
	padding: 0;
}

ul,
ol {
	list-style: none;
	padding-left: 0;
}

// antd modal
.ant-modal-body{
  padding:0;
}

.ant-modal-content{
	box-shadow:unset;
	border-radius:8px;
}




// antd dropdown
.ant-select-dropdown{
	padding:4px;
	border-radius:4px;
}

.ant-select-item{
	margin:0 0 2px 0;
	border-radius:4px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
	background-color:#e9e9e9;
}

// antd range picker
.ant-picker-dropdown{
	font-family:'Noto Sans', sans-serif;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border: 1px solid ${(props) => props.theme.blue};
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
	background:${(props) => props.theme.blue};
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before{
	background:rgba(69, 86, 238, 0.2);
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
	background:rgba(69, 86, 238, 0.2);
}


//antd range picker - hover
.ant-picker-cell-in-view.ant-picker-cell-range.ant-picker-cell-range-hover::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
	.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before
	.ant-picker-panel > :not(.ant-picker-data-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
	.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before{
		background:rgba(69, 86, 238, 0.2);
	}



.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before{
	background:rgba(69, 86, 238, 0.2);
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after{
	background:rgba(69, 86, 238, 0.2);

}


`;

export default GlobalStyle;
