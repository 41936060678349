import { useNavigate } from "react-router-dom";
import { Button } from "./styles";
import { useRecoilState } from "recoil";
import { recoilExpertTabKeyState } from "states/recoilExpertTabKeyState";

const ShowMoreBtn = ({ targetTab }) => {
  let navigate = useNavigate();

  const [_, setKey] = useRecoilState(recoilExpertTabKeyState);

  const onClick = () => {
    setKey((prev) => {
      const prevState = { ...prev };
      prevState.key = targetTab;
      return { ...prevState };
    });
    navigate("/expert");
  };

  return (
    <Button type="button" onClick={onClick}>
      더보기 &gt;
    </Button>
  );
};

export default ShowMoreBtn;
