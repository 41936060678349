import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import moment from "moment";
import SearchBox from "components/common/SearchBox";
import Layout from "layouts/Layout";
import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import ExcelBtn from "components/common/ExcelBtn";
import DetailLink from "components/common/DetailLink";
import Pagination from "components/common/Pagination";
import { PageHeading, SearchError, ResultWrapper } from "./styles";
import { userSearchOption } from "utils/data";
import { userExcelData } from "components/common/ExcelBtn/helper";
import { qsParses } from "utils/queryHandler";
import { utils, writeFileXLSX } from "xlsx";

const selectOptionObj = {
  0: "all",
  1: "email",
  2: "name",
  3: "companyName",
  4: "phone",
};

const User = () => {
  const [params, setParams] = useState({
    // page: 1,
    size: 20,
    direction: "DESC",
    name: "",
    phone: "",
    companyName: "",
    sDate: "",
    eDate: "",
  });
  let pagesize = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const role = window.localStorage.getItem("superAdmin");
  const {
    data: userData,
    error,
    mutate,
  } = useSWR(`/api/v1/admin/members?page=${currentPage}&size=${pagesize}`, (url) =>
    fetcherPrivate(url, { params: search ? { ...qsParses(search) } : { page: 1, size: 20, direction: "DESC" } }, { revalidateOnFocus: false })
  );
  const [userArr, setUserArr] = useState([]);
  useEffect(() => {
    if (userData) {
      const userList = userData.result.content;

      setUserArr(userList);
    }
  }, [userData]);
  const { search } = useLocation();

  useEffect(() => {
    mutate();
  }, [search]);

  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    mutate();
  }, [params]);

  const onClickExcel = async () => {
    try {
      let parseSearch = qsParses(search);

      delete parseSearch.size;
      delete parseSearch.page;

      const totalCount = userArr.length;

      const res = await fetcherPrivate(`/api/v1/admin/members`, { params: search ? { ...qsParses(search), size: totalCount } : { page: 1, size: totalCount, direction: "DESC" } });

      const newList = res.result.content.map((item) => {
        return {
          email: item.email,
          name: item.name,
          companyName: item.companyName,
          phone: item.phone,
          usePlan: item.usePlan,
          joinDate: item.joinDate,
        };
      });
      const ws = utils.json_to_sheet(newList);
      const wb = utils.book_new();
      utils.sheet_add_aoa(ws, [["이메일", "회원 명", "기업 명", "연락처", "플랜", "가입일"]], {
        origin: "A1",
      });
      utils.book_append_sheet(wb, ws, "UserList");
      writeFileXLSX(wb, "UserList.xlsx");
    } catch (e) {
      console.log(e);
      window.alert("엑셀 다운로드에 실패했습니다. 개발팀에 문의해주세요!");
    }
  };

  //----------------------------------------------------------
  // 엑셀 버튼 클릭

  return (
    <Layout defaultMenu={["7"]}>
      <PageHeading>회원 관리</PageHeading>

      <SearchBox optionList={userSearchOption} page={currentPage} />
      {userData && (
        <>
          <ResultWrapper>
            <h4>
              검색 결과 총 <strong>{userData?.result?.totalElements}</strong>명
            </h4>

            {/* TODO */}
            <ExcelBtn onClick={onClickExcel} disabled={userArr.length === 0} />
          </ResultWrapper>

          <Table>
            <Thead>
              <tr>
                <th style={{ width: "120px" }}>인증 코드</th>
                <th style={{ width: "150px" }}>기관 명</th>
                <th style={{ width: "150px" }}>이메일</th>
                <th style={{ width: "100px" }}>회원 명</th>
                <th>기업 명</th>
                <th style={{ width: "120px" }}>연락처</th>
                <th style={{ width: "100px" }}>플랜</th>
                <th style={{ width: "170px" }}>가입일</th>
              </tr>
            </Thead>
            <Tbody>
              {userArr?.map((item, i) => (
                <tr key={i}>
                  <td>{item.authCode}</td>
                  <td>{item.authCodeCompanyName}</td>
                  <td>{item.email}</td>
                  <td>{item.name}</td>
                  <td>{item.companyName}</td>
                  <td>{item.phone}</td>
                  <td>{item.usePlan}</td>
                  <td className="with-btn">
                    <div>
                      <div>{item.joinDate}</div>
                      {role === "true" ? <DetailLink to={`/user/detail/${item.uid}`} /> : ""}
                    </div>
                  </td>
                </tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}

      <Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={userData?.result?.totalElements} pageSize={20} />
    </Layout>
  );
};

export default User;
