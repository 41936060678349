import styled from "styled-components";
import close_x_black from "assets/close_x_black.svg";
import { flexbox, flexboxColumn, fontStyle12, fontStyle13, fontStyle14, fontStyle24, tableBase } from "styles/mixins";

export const Wrapper = styled.div`
  position: relative;
  padding: 20px 30px 40px;

  h1 {
    ${fontStyle24};
    font-weight: 700;
    margin: 10px 0;
  }
`;

export const Table = styled.table`
  ${tableBase};
  width: 900px !important;
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(${close_x_black});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

export const ExpertList = styled.div`
  ${flexbox};
  &:not(:last-of-type) {
    margin: 0 0 20px 0;
  }
`;

export const ExpertImg = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 10px 0 0;

  img {
    width: 50px;
    height: auto;
    border-radius: 50%;
  }
`;

export const ExpertInfo = styled.div`
  ${flexboxColumn};
`;

export const ExpertName = styled.div`
  ${flexbox("flex-start", "center")};
  strong {
    ${fontStyle14};
    font-weight: 500;
  }

  p {
    ${flexbox("center", "center")};
    font-size: 11px;
    margin: 0 0 0 5px;
    font-weight: 500;
    height: 20px;
    padding: 0 10px;
    border-radius: 100px;
    background-color: ${(props) => (props.type === "manager" ? props.theme.yellow : props.type === "designer" ? props.theme.orange : props.theme.blueLight)};
  }
`;

export const ExpertEmail = styled.small`
  ${fontStyle12};
  color: ${(props) => props.theme.helperTextGrey};
`;
