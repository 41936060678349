import { Select } from "antd";
import { StyledSelect } from "./styles";

const CustomSelect = ({ placeholder, optionList, value, onChange, style, disabled = false }) => {
  const { Option } = Select;

  const options = optionList?.map((option, i) => (
    <Option key={i} value={option.value} disabled={option.disabled || disabled}>
      {option.text}
    </Option>
  ));

  return (
    <StyledSelect placeholder={placeholder} value={value} onChange={onChange} style={style} dropdownStyle={{ borderRadius: "4px" }}>
      {options}
    </StyledSelect>
  );
};

export default CustomSelect;
