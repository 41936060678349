import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import { Container } from "./styles";

const Bubble = ({ data }) => {
  return (
    <Container>
      <ResponsiveCirclePacking
        data={data}
        leavesOnly
        margin={{ top: 10, bottom: 10 }}
        id="name"
        value="amount"
        colors={{ scheme: "pastel2" }}
        colorBy="id"
        enableLabels={true}
        labelTextColor={"#000"}
        // valueFormat=" >-"
        tooltip={({ id, value }) => (
          <div style={{"background-color" : "#192A3E", "color" : "#FFFFFF", "borderRadius" : "24px", "padding" : "10px 30px 10px 30px", "textAlign" : "center"}}>
            <div style={{"fontSize" : "7px", "padding" : "2px"}}>{id}</div>
            <div style={{"fontSize" : "10px", "padding" : "2px", "fontWeight" : "bold"}}>
                {value}회 사용
            </div>
          </div>
        )}
      />
    </Container>
  );
};

export default Bubble;
