import styled from "styled-components";
import { flexbox } from "styles/mixins";

export const FlexWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const ImageTd = styled.div`
  width: ${(props) => (props.isSmall ? "65px" : "120px")};
  height: 65px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const BtnTd = styled.div`
  ${flexbox("space-between", "center")};

  button {
    margin: 0 0 0 3px;
  }
`;
