const theme = {
  white: "#fff",
  black: "#000",

  yellow: "#F6DD66",
  blueLight: "#70BECD",
  blue: "#4556EE",
  blueDark: "#3745BE",
  orange: "#CE5E41",
  green: "#52B337",

  helperTextGrey: "#808080",
  placeholder: "#B1B1B1",

  border: "#E0E0E0",
  borderActive: "#181818",

  tableHeaderBg: "#f9f9f9",
  tableBorder: "#e5e5e5",

  buttonBgWhiteHover: "#fbfbfb",
  buttonBgGrey: "#f3f3f3",
  buttonBgGreyHover: "#eee",
  buttonBgBlack: "#1b1b1b",
  buttonDisabledColor: "#D6D6D6",
};

export default theme;
