import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { recoilBenchmarkTabKeyState } from "states/recoilBenchmarkTabKeyState";
import Layout from "layouts/Layout";
import TabMenu from "components/common/TabMenu";
import KeywordTab from "components/resource/keyword/KeywordTab";
import { PageHeading } from "./styles";

// TODO  : delete

import { useLocation, useNavigate } from "react-router-dom";
import { qsStringify, qsParses } from "utils/queryHandler";
import BenchmarkingTab from "components/resource/benchmarking/benchmarkTab";

const Benchmarking = () => {
  // TODO : swr
  // 테이블 데이터

  // 탭 메뉴
  // todo children...?
  const navigate = useNavigate();
  const { search } = useLocation();

  const [key, setKey] = useRecoilState(recoilBenchmarkTabKeyState);
  const [currentTab, setCurrentTab] = useState(key.key);

  const onChangeCurrentKey = (key) => {
    setCurrentTab(key);
    setKey((prev) => {
      const prevState = { ...prev };
      prevState.key = key;
      return { ...prevState };
    });
    console.log(search);
    navigate(
      `?${qsStringify(
        {
          ...qsParses(search),
          registerType: key,
          allSearch: null,
          name: null,
          keywordName: null,
        },
        { skipNulls: true, strictNullHandling: true }
      )}`
    );
  };

  useEffect(() => {
    onChangeCurrentKey("ADMIN");
  }, []);

  const items = [
    {
      label: "관리자",
      key: "ADMIN",
      children: <BenchmarkingTab currentTab={currentTab} />,
    },
    {
      label: "회원",
      key: "MEMBER",
      children: <BenchmarkingTab currentTab={currentTab} />,
    },
  ];

  // tab 상태 변화 관리
  // 0. currentTab key.
  // 1. recoil에 current tab key 저장하여 등록/편집 페이지로 이동했다가 돌아왔을 때, 마지막으로 보고 있던 탭이 currentTab 되도록.
  // 2. currentTab 바뀌면 데이터도 각 탭에 맞게 받아와서 넣어주기.

  return (
    <Layout defaultOpenKeys={["sub2"]} defaultMenu={["4"]}>
      <PageHeading>벤치마킹 리소스 관리</PageHeading>
      <TabMenu
        type="card"
        items={items}
        activeKey={currentTab}
        onChange={onChangeCurrentKey}
      />
    </Layout>
  );
};

export default Benchmarking;
