import styled from "styled-components";
import { flexbox, fontStyle15 } from "styles/mixins";

export const Wrapper = styled.div`
  ${flexbox("center", "center")};
  margin: 30px 0 0 0;

  button {
    ${flexbox("center", "center")};
    ${fontStyle15};
    height: 44px;
    width: 180px;
    border-radius: 100px;
    font-weight: 700;
    transition: background-color 300ms ease-in-out;
  }

  button:first-of-type {
    margin: 0 15px 0;
    background-color: ${(props) => props.theme.buttonBgGrey};
    color: ${(props) => props.theme.black};

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.buttonBgGreyHover};
    }
  }

  button:nth-of-type(2) {
    background-color: ${(props) => props.theme.buttonBgBlack};
    color: ${(props) => props.theme.white};

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.black};
    }

    &:disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
`;
