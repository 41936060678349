import styled from "styled-components";
import { flexbox, fontStyle13 } from "styles/mixins";

export const Wrapper = styled.div`
  ${flexbox("flex-start", "center")};

  input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  label {
    ${flexbox("center", "center")};
    ${fontStyle13};
    cursor: pointer;
    height: 42px;
    width: 84px;
    background-color: ${(props) => props.theme.white};
    transition: border-color 300ms ease-in-out, font-weight 300ms ease-in-out;
  }

  input[type="radio"]:checked + label {
    font-weight: 500;
  }

  // 오늘
  > div:first-of-type input[type="radio"] + label {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 1px solid ${(props) => props.theme.border};
    border-right: 1px solid ${(props) => props.theme.border};
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-left: 1px solid ${(props) => props.theme.border};
  }

  > div:first-of-type input[type="radio"] + label:hover,
  > div:first-of-type input[type="radio"]:checked + label {
    border-top: 1px solid ${(props) => props.theme.borderActive};
    border-right: 1px solid ${(props) => props.theme.borderActive};
    border-bottom: 1px solid ${(props) => props.theme.borderActive};
    border-left: 1px solid ${(props) => props.theme.borderActive};
  }

  // 1개월
  > div:nth-of-type(2) input[type="radio"] + label {
    border-top: 1px solid ${(props) => props.theme.border};
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-left: 1px solid transparent;
  }

  > div:nth-of-type(2) input[type="radio"] + label:hover,
  > div:nth-of-type(2) input[type="radio"]:checked + label {
    border-top: 1px solid ${(props) => props.theme.borderActive};
    border-right: 1px solid ${(props) => props.theme.borderActive};
    border-bottom: 1px solid ${(props) => props.theme.borderActive};
    border-left: 1px solid ${(props) => props.theme.borderActive};
  }

  // 6개월
  > div:nth-of-type(3) input[type="radio"] + label {
    border-top: 1px solid ${(props) => props.theme.border};
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-left: 1px solid ${(props) => props.theme.border};
  }

  > div:nth-of-type(3) input[type="radio"] + label:hover,
  > div:nth-of-type(3) input[type="radio"]:checked + label {
    border-top: 1px solid ${(props) => props.theme.borderActive};
    border-right: 1px solid ${(props) => props.theme.borderActive};
    border-bottom: 1px solid ${(props) => props.theme.borderActive};
    border-left: 1px solid ${(props) => props.theme.borderActive};
  }

  // 전체
  > div:last-of-type input[type="radio"] + label {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid ${(props) => props.theme.border};
    border-right: 1px solid ${(props) => props.theme.border};
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-left: 1px solid ${(props) => props.theme.border};
  }

  > div:last-of-type input[type="radio"] + label:hover,
  > div:last-of-type input[type="radio"]:checked + label {
    border-top: 1px solid ${(props) => props.theme.borderActive};
    border-right: 1px solid ${(props) => props.theme.borderActive};
    border-bottom: 1px solid ${(props) => props.theme.borderActive};
    border-left: 1px solid ${(props) => props.theme.borderActive};
  }
`;
