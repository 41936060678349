import { useState, useEffect } from "react";
import moment from "moment";
import SearchBox from "components/common/SearchBox";
import SortableTable from "components/common/SortableTable";
import Pagination from "components/common/Pagination";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { RegisterLink, ResultHeading } from "../../keyword/KeywordTab/styles";
import { templateSearchOption } from "utils/data";

import { templateMockData } from "utils/mockData";
import { deleteTemplate } from "api/resource/template";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useLocation } from "react-router-dom";
import { qsParses } from "utils/queryHandler";

const TemplateTab = () => {
  // /Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };
  const { search } = useLocation();

  const {
    data: templateList,
    isLoading,
    error: templateError,
    mutate,
  } = useSWR(`/api/v1/admin/projects/templates?page=${currentPage}&size=10`, (url) =>
    fetcherPrivate(url, { params: search ? { ...qsParses(search), templateType: "BUSINESS_MODEL" } : { page: 1, size: 20, direction: "DESC" } })
  );

  //테이블 데이터
  const [tableData, setTableData] = useState([]);
  // TODO
  useEffect(() => {
    if (templateList) {
      setTableData(templateList.result?.content);
    }
  }, [templateList]);
  // 검색 소팅
  const [selectedOption, setSelectedOption] = useState(0);
  const onChangeSelect = (value) => setSelectedOption(value);

  //검색어
  const [searchValue, setSearchValue] = useState("");
  const onChangeSearchValue = (e) => setSearchValue(e.target.value);

  // 날짜
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  // 날짜 변경
  const onChangeDate = (values) => {
    setStartDate(values?.[0]);
    setEndDate(values?.[1]);
  };

  useEffect(() => {
    if (search) {
      mutate();

      const { page } = qsParses(search);

      if (page) setCurrentPage(+page);
    }
  }, [search]);

  // 토글 버튼
  const onToggle = (id, blocked) => {
    // console.log(id, blocked);
  };

  // 삭제 버튼 클릭 시 나타나는 alert 모달
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const [targetDelId, setTargetDelId] = useState("");

  const onClickDelBtn = (id) => {
    setIsDelModalVisible(true);
    setTargetDelId(id);
  };

  // 삭제 alert 모달의 취소, 확인
  const onClickDelCancel = () => {
    setIsDelModalVisible(false);
  };
  const onClickDelConfirm = async () => {
    try {
      const res = await deleteTemplate(targetDelId);
      if (res) {
        await mutate();
        setIsDelModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }

    setIsDelModalVisible(false);
  };

  return (
    <>
      <div>
        <RegisterLink to="/resource/template/register">등록</RegisterLink>
        <SearchBox optionList={templateSearchOption} page={currentPage} />
        <ResultHeading>
          총 <strong>{templateList?.result?.totalElements}</strong>개
        </ResultHeading>
        <SortableTable data={tableData} setData={setTableData} onToggle={onToggle} onClickDel={onClickDelBtn} currentPage="template" />
        {templateList && !isLoading && <Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={templateList?.result?.totalElements} pageSize={10} />}
      </div>

      {isDelModalVisible && (
        <Modal isVisible={isDelModalVisible}>
          <AlertModalContent onClickCancel={onClickDelCancel} onClickSave={onClickDelConfirm}>
            <h2>정말 삭제하시겠습니까?</h2>
            <p>
              삭제하시면 해당 키워드 내용이 <br />
              사라집니다.
            </p>
            <p>그래도 삭제하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default TemplateTab;
