import { useState } from "react";

export const useSearchData = () => {
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const onChangeDate = (values) => {
    setStartDate(values?.[0]);
    setEndDate(values?.[1]);
  };

  return [searchValue, onChangeSearchValue, startDate, endDate, onChangeDate];
};
