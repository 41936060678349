import styled from "styled-components";
import plus_grey from "assets/plus_grey.svg";

import x_black from "assets/x_black.svg";
import { flexbox } from "styles/mixins";

export const Wrapper = styled.div`
  pointer-events: ${(props) => (props.disabled ? "none" : "default")};
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  label {
    display: block;
    width: ${(props) => (props.keywordType === "DESIGN_CONCEPT" ? "410px" : props.isSmall ? "84px" : props.isBig ? "100%" : "150px")};
    height: ${(props) => (props.keywordType === "DESIGN_CONCEPT" ? "410px" : props.isSmall ? "84px" : props.isBig ? "600px" : "324px")};

    border: 1px solid ${(props) => props.theme.border};
    border-radius: 4px;
    background-image: url(${plus_grey});
    background-size: 15px 15px;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: border-color 300ms ease-in-out;

    :hover {
      border: 1px solid ${(props) => props.theme.borderActive};
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  ${flexbox("center", "center")};
  width: ${(props) => (props.keywordType === "DESIGN_CONCEPT" ? "410px" : props.isSmall ? "84px" : props.isBig ? "100%" : "150px")};
  height: ${(props) => (props.keywordType === "DESIGN_CONCEPT" ? "410px" : props.isSmall ? "84px" : props.isBig ? "600px" : "324px")};
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #f3f3f3;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 9px;
    height: 9px;
    background-image: url(${x_black});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
`;
