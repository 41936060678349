import styled from "styled-components";
import { flexbox, fontStyle12, fontStyle14 } from "styles/mixins";

export const Wrapper = styled.div`
  ${flexbox("center", "center")};
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.yellow};
`;

export const Card = styled.div`
  width: 400px;
  padding: 40px;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;

  input#admin-id {
    margin: 0 0 15px 0;
  }
`;

export const Logo = styled.div`
  width: 140px;
  height: 70px;
  margin: 0 0 45px 0;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const HelperText = styled.p`
  ${fontStyle12};
  line-height: 18px;
  font-weight: 500;
  background-color: #f3f3f3;
  border-radius: 4px;
  color: ${(props) => props.theme.orange};
  margin: 15px 0 0 0;
  padding: 10px 11px;
`;

export const LoginBtn = styled.button`
  ${flexbox("center", "center")};
  ${fontStyle14};
  font-weight: 700;
  width: 100%;
  height: 44px;
  margin: 50px 0 10px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.buttonBgBlack};
  color: ${(props) => props.theme.white};
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out;

  &:hover,
  &:active {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.black};
  }

  &:disabled {
    background-color: ${(props) => props.theme.buttonBgGrey};
    color: ${(props) => props.theme.buttonDisabledColor};
  }
`;
