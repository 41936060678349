import styled from "styled-components";
import { flexbox, fontStyle13 } from "styles/mixins";
export const Container = styled.div`
  ${flexbox("space-between", "center")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 40px;
  padding: 0 24px;
  background-color: #fff;
  border-bottom: 1px solid ${(props) => props.theme.border};
  z-index: 999;

  img {
    display: block;
    width: 24px;
    height: 24px;
  }

  h1 {
    font-weight: 700;
    ${fontStyle13};
    color: ${(props) => props.theme.black};
  }
`;
