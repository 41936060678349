import styled from "styled-components";
import { flexbox, flexboxColumn, fontStyle15, fontStyle14, fontStyle18, fontStyle28, fontStyle13, fontStyle24, fontStyle12 } from "styles/mixins";
import avatar_manager from "assets/avatar_manager.svg";
import avatar_designer from "assets/avatar_designer.svg";
import avatar_mentor from "assets/avatar_mentor.svg";
import { Link } from "react-router-dom";

export const Main = styled.main`
  margin: 0px 0 0 240px;
  padding: 20px 6px 100px 20px;
`;

export const PageHeading = styled.h1`
  ${fontStyle28};
  margin: 0 0 6px 0;
  font-weight: 700;
  color: ${(props) => props.theme.black};
`;

export const PageSelectWrapper = styled.div`
  width: 1100px;
  margin: 0 0 8px 0;
  text-align: right;
`;

export const FlexWrapper = styled.div`
  ${flexbox("flex-start", "center")};
`;

export const FlexColumnWrapper = styled.div`
  ${flexboxColumn}
`;

export const BoxFlexWrapper = styled(FlexWrapper)`
  gap: 25px;

  :first-of-type {
    margin: 0 0 25px 0;
  }
`;

export const BoxBase = styled.div`
  height: 350px;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 4px;

  h2,
  h3,
  h4 {
    color: ${(props) => props.theme.black};
    font-weight: 500;
  }

  h2 {
    ${fontStyle15};
  }

  h3 {
    ${fontStyle14};
  }

  h4 {
    ${fontStyle13};
  }
`;

export const SmallBox = styled(BoxBase)`
  width: 350px;
`;

export const LongBox = styled(BoxBase)`
  width: 725px;
`;

export const TotalNumber = styled.p`
  ${fontStyle18};
  font-weight: 700;

  strong {
    ${fontStyle24};
  }
`;

export const CompareNumber = styled.div`
  ${flexbox("flex-start", "center")};
  position: relative;
  top: -4px;

  .anticon {
    position: relative;
    top: 1px;
    margin: 0 3px 0 0;
  }
  span {
    ${fontStyle12};
  }

  span:fisrt-of-type {
    color: ${(props) => props.theme.helperTextGrey};
  }

  // TODO : 색상
  span:last-of-type {
    font-weight: 700;
    color: ${(props) => props.theme.orange};
  }
`;

export const YearSelector = styled.div`
  ${fontStyle12};
  float: right;
  color: #4c5862;
  margin-top: -28px;
`;

export const ShowMoreLink = styled(Link)`
  ${fontStyle12};
  font-weight: 500;

  :hover {
    color: ${(props) => props.theme.black};
  }
`;

// 프로젝트 총 건수, 프로젝트 진행 헤딩
export const HeadingWrapper = styled.div`
  ${flexbox("space-between", "flex-start")};
  padding: 10px 14px;
`;

export const PieWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.border};
  padding: 10px 14px;
`;

export const ChartBtn = styled.button`
  ${fontStyle13};
  display: block;
  height: 30px;
  padding: 0 6px;
  background-color: ${(props) => (props.isActive ? props.theme.blue : props.theme.white)};
  font-weight: ${(props) => (props.isActive ? 500 : 400)};
  color: ${(props) => (props.isActive ? props.theme.white : props.theme.black)};
  border-radius: 4px;
  transition: color 300ms ease-in-out, font-weight 300mse ease-in-out, background-color 300ms ease-in-out;

  &:first-of-type {
    margin: 0 4px 0 0;
  }

  :hover {
    color: ${(props) => (props.isActive ? props.theme.white : props.theme.blue)};
  }
`;

// 프로젝트 진행
export const ProgressList = styled.ul`
  ${flexbox("space-between", "center")};
  width: 100%;
  padding: 0 14px;
  margin: 10px 0 20px;
`;

export const ProgressItem = styled.li`
  ${flexboxColumn("flex-start", "flex-start")};
  width: 120px;
  height: 100px;
  padding: 18px 12px;
  border-radius: 16px;
  background-color: rgba(153, 153, 153, 0.1);
  .anticon {
    margin: 0 0 6px 0;
  }
  h3 {
    font-weight: 400;
  }
  strong {
    font-weight: 500;
  }
`;

export const ProgressBottom = styled.div`
  ${flexbox("flex-start", "center")};
  width: 100%;
  height: 115px;
  border-top: 1px solid ${(props) => props.theme.border};

  > div {
    ${flexboxColumn("center", "flex-start")};
    flex: 1;
    padding: 0 14px;

    &:first-of-type {
      border-right: 1px solid ${(props) => props.theme.border};
    }
  }
`;

export const SmallBoxHeadingWrapper = styled.div`
  ${flexbox("space-between", "center")};
  padding: 12px 14px;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

// 전문가 매칭
export const MatchingList = styled.ul`
  ${flexbox};
  height: 287px;
  padding: 25px 0;

  li {
    flex: 1;
    ${flexboxColumn("flex-start", "flex-start")};
    padding: 66px 0 0 22px;
    background-repeat: no-repeat;
    background-position: 20px 22px;
    background-size: 42px 42px;
    position: relative;
  }

  li:first-of-type {
    background-image: url(${avatar_manager});
    border-right: 1px dashed ${(props) => props.theme.border};
  }

  li:nth-of-type(2) {
    background-image: url(${avatar_designer});
    border-right: 1px dashed ${(props) => props.theme.border};
  }

  li:last-of-type {
    background-image: url(${avatar_mentor});
  }

  h5 {
    ${fontStyle13};
    font-weight: 500;
    margin: 0 0 18px 0;
  }

  p {
    ${fontStyle18};

    strong {
      ${fontStyle24};
    }

    font-weight: 700;
  }

  a {
    position: absolute;
    right: 9px;
    bottom: -3px;
  }
`;

// 회원 가입 건수
export const LineWrapper = styled.div`
  padding: 10px 14px;
`;

// 리소스

export const ResourceBtnWrapper = styled.div`
  ${flexbox("space-between", "center")};
  padding: 10px 14px 0;
`;
