import { Wrapper, StyledPagination } from "./styles";

const Pagination = ({ defaultCurrent, current, onChange, total, pageSize }) => {
  return (
    <Wrapper>
      <StyledPagination defaultCurrent={defaultCurrent} current={current} onChange={onChange} total={total} pageSize={pageSize}></StyledPagination>
    </Wrapper>
  );
};

export default Pagination;
