import styled from "styled-components";
import { fontStyle12, fontStyle13 } from "styles/mixins";

export const StyledTextarea = styled.textarea`
  ${fontStyle13};
  display: block;
  width: 320px;
  height: 100px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border};
  color: ${(props) => props.theme.black};
  transition: border-color 100ms ease-in-out;
  resize: none;

  ::placeholder {
    font-weight: 500;
    ${fontStyle12};
    color: ${(props) => props.theme.placeholder};
  }

  :focus,
  :active {
    border: 1px solid ${(props) => props.theme.borderActive};
  }
`;
