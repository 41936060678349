import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, Card, Logo, HelperText, LoginBtn } from "./styles";
import axiosPublic from "utils/axiosPublic";
import Input from "components/common/Input";
import PasswordInput from "components/common/PasswordInput";
import logo from "assets/logo.svg";

const Login = () => {
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const onChangeId = (e) => setId(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);

  const [isValid, setIsValid] = useState(true);

  // disabled btn
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (id?.length > 0 && password?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [id, password]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: id,
      password,
    };

    axiosPublic
      .post("/api/v1/open/sign-in", data)
      .then((resp) => {
        const uid = resp.data.result.uid;
        const token = resp.data.result.token;
        const role = resp.data.result.roleCodes[0];
        if (id === "i2badmin@olivestonelab.com" || role === "ROLE_SUPER") {
          window.localStorage.setItem("superAdmin", true);
        } else {
          window.localStorage.setItem("superAdmin", false);
        }
        window.localStorage.setItem("uid", uid);
        window.localStorage.setItem("accessToken", token);
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        const errorCode = err.response.data.code;
        if (errorCode === "AUTH_E_002") {
          setIsValid(false);
        }
      });
  };

  return (
    <Wrapper>
      <Card>
        <Logo>
          <img src={logo} alt="i2b logo" />
        </Logo>
        <form onSubmit={onSubmit}>
          <Input id="admin-id" value={id} onChange={onChangeId} placeholder="ID" />
          <PasswordInput id="admin-password" value={password} onChange={onChangePassword} placeholder="Password" isValid />
          {!isValid && (
            <HelperText>
              아이디 또는 비밀번호를 잘못 입력했습니다.
              <br /> 입력하신 내용을 다시 확인해주세요.
            </HelperText>
          )}
          <LoginBtn type="submit" disabled={isDisabled}>
            로그인
          </LoginBtn>
        </form>
      </Card>
    </Wrapper>
  );
};

export default Login;
