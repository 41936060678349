import { useState } from "react";
import moment from "moment";
import Select from "components/common/Select";
import { Container, Wrapper, SearchBtn, StyledRangePicker } from "./styles";
import RangeRadioBtn from "../RangeRadioBtn";
import { TODAY, MONTH, MONTHS, ALL, rangeBtnOption } from "utils/data";
import { useEffect } from "react";

const PeriodPicker = ({ title, selectOptionList, selectOptionDefault, allowClear, startDate, endDate, onChangeDate, isAlone, selectedValue, onChangeSelect }) => {
  // select
  // const onChangeSelect = (value) => {
  //   console.log(value);
  // };

  // range picker
  const [range, setRange] = useState(ALL);

  const onChangeRange = (value) => {
    const period = value;

    if (onChangeDate) {
      let startDate = moment();
      let endDate = moment();
      switch (period) {
        case TODAY: {
          startDate = moment();
          endDate = moment();
          break;
        }
        case MONTH: {
          startDate = moment().subtract(1, "month");
          endDate = moment();
          break;
        }
        case MONTHS: {
          startDate = moment().subtract(6, "month");
          endDate = moment();
          break;
        }
        case ALL: {
          // TOOD
          startDate = null;
          endDate = null;
          break;
        }
      }

      onChangeDate([startDate, endDate]);
    }

    setRange(period);
  };

  return (
    <Container>
      <Wrapper>
        {title ? <h4>{title}</h4> : <Select optionList={selectOptionList} defaultValue={selectOptionDefault} onChange={onChangeSelect} value={selectedValue} />}
        <StyledRangePicker allowClear={allowClear} value={startDate && endDate ? [startDate, endDate] : null} onChange={onChangeDate} />
        <RangeRadioBtn name="range" optionList={rangeBtnOption} selectedValue={range} onChange={onChangeRange} />
      </Wrapper>
      {isAlone && <SearchBtn type="submit">검색</SearchBtn>}
    </Container>
  );
};

export default PeriodPicker;
