import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useRecoilState } from "recoil";
import { recoilExpertTabKeyState } from "states/recoilExpertTabKeyState";
import Layout from "layouts/Layout";
import SearchBox from "components/common/SearchBox";
import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import Pagination from "components/common/Pagination";
import { authSearchOption } from "utils/data";
import { PageHeading, RegisterLink, TabBtnList, TabBtn, ResultWrapper, ImageTd, BtnTd } from "./styles";
import { qsParses, qsStringify } from "utils/queryHandler";
import DeleteBtn from "components/common/DeleteBtn";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { deleteAuthCode } from "api/resource/admin";

const Certification = () => {
  // 검색 선택

  const role = window.localStorage.getItem("superAdmin");
  // 상세보기 버튼 클릭

  //-------------------------------

  // 현재 tab 관리
  const [key, setKey] = useRecoilState(recoilExpertTabKeyState);
  const [currentTab, setCurrentTab] = useState(key.key);

  // 현재 페이지 관리
  const [currentPage, setCurrentPage] = useState(1);

  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  // TODO: 프로젝트 진행 단계 params 빠짐
  const { search } = useLocation();

  const {
    data: authCodeList,
    error: authCodeListError,
    mutate,
  } = useSWR(`/api/v1/super/auth-codes?page=${currentPage}`, (url) =>
    fetcherPrivate(url, { params: search ? { ...qsParses(search), expertType: currentTab } : { page: 1, size: 20, direction: "DESC", experType: currentTab } }, { revalidateOnFocus: false })
  );

  console.log(authCodeList, "authCodeList");
  useEffect(() => {
    mutate();
  }, [search, currentTab]);

  // 삭제 버튼 클릭 시 나타나는 alert 모달
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const [targetDelId, setTargetDelId] = useState("");

  const onClickDelBtn = (id) => {
    setIsDelModalVisible(true);
    setTargetDelId(id);
  };

  // 삭제 alert 모달의 취소, 확인
  const onClickDelCancel = () => {
    setIsDelModalVisible(false);
  };

  const onClickDelConfirm = async () => {
    setIsDelModalVisible(false);
    try {
      const res = await deleteAuthCode(targetDelId);
      if (res) {
        await mutate();
        setIsDelModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout defaultMenu={["9"]}>
      <PageHeading>인증 코드 관리</PageHeading>
      <div>
        <RegisterLink to="/certification/register">등록</RegisterLink>
        <SearchBox optionList={authSearchOption} page={currentPage} />
        {authCodeList?.result && (
          <>
            <ResultWrapper>
              <h4>
                검색 결과 총 <strong>{authCodeList?.result?.totalElements ?? 0}</strong>개
              </h4>
              {/* TODO */}
            </ResultWrapper>
            <Table>
              <Thead>
                <tr>
                  <th style={{ width: "250px" }}>인증 코드</th>
                  <th style={{ width: "250px" }}>기관명</th>
                  <th>로고 이미지</th>
                  <th style={{ width: "200px" }}>등록일</th>
                </tr>
              </Thead>
              <Tbody>
                {authCodeList?.result?.content?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.authCode}</td>
                    <td>{item.companyName}</td>
                    <td>
                      {item.logoImage ? (
                        <ImageTd>
                          <img src={item.logoImage} />
                        </ImageTd>
                      ) : (
                        <ImageTd> </ImageTd>
                      )}
                    </td>
                    <td>
                      <BtnTd>
                        <p>{item.joinDate}</p>

                        <DeleteBtn
                          onClick={() => {
                            onClickDelBtn(item.id);
                          }}
                        />
                      </BtnTd>
                    </td>
                  </tr>
                ))}
              </Tbody>
            </Table>
            {isDelModalVisible && (
              <Modal isVisible={isDelModalVisible}>
                <AlertModalContent onClickCancel={onClickDelCancel} onClickSave={onClickDelConfirm}>
                  <h2>인증 코드를 삭제하시겠습니까?</h2>
                  <p>
                    인증 코드 삭제시 해당 코드를 부여한
                    <br />
                    테스터는 로그인 페이지에
                    <br />
                    접근할 수 없습니다.
                  </p>
                  <p>그래도 인증 코드를 삭제하시겠습니까?</p>
                </AlertModalContent>
              </Modal>
            )}
            {authCodeList?.result ? <Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={authCodeList?.result?.totalElements} pageSize={20} /> : null}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Certification;
