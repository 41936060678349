import styled from "styled-components";
import { DatePicker } from "antd";
import { flexbox, fontStyle13, fontStyle14 } from "styles/mixins";

export const Container = styled.div`
  ${flexbox("space-between", "center")};
`;

export const Wrapper = styled.div`
  ${flexbox("flex-start", "center")};
  flex-grow: 0;
  flex-shring: 0;
  width: 900px;
  max-width: 900px;

  .ant-select {
    width: 160px !important;
    margin: 0 15px 0 0;
  }

  h4 {
    ${fontStyle14};
    font-weight: 500;
    width: 160px;
    margin: 0 15px 0 0;
  }
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  ${fontStyle13};
  height: 42px;
  width: 400px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border};
  margin: 0 15px 0 0;
  color: ${(props) => props.theme.black};

  .ant-picker-input > input {
    ${fontStyle13};
    font-weight: 500;
    color: ${(props) => props.theme.black};
    text-align: center;
  }

  :hover,
  :focus {
    border: 1px solid ${(props) => props.theme.borderActive};
  }

  &.ant-picker-focused {
    box-shadow: unset;
  }

  .ant-picker-active-bar {
    background: ${(props) => props.theme.black};
  }
`;

export const SearchBtn = styled.button`
  ${fontStyle14};
  height: 34px;
  width: 90px;
  border-radius: 120px;
  font-weight: 500;
  background-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.blueDark};
  }
`;
