import { useState } from "react";
import Tbody from "components/common/BasicTable/Tbody";
import Thead from "components/common/BasicTable/Thead";
import Input from "components/common/Input";
import { MailOutlined } from "@ant-design/icons";
import { Wrapper, CloseBtn, InputWrapper, Table, CheckboxWrapper, Duration, EmailBtn, TableWrapper } from "./styles";
import Pagination from "components/common/Pagination";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import { projectMatchSearchOption } from "utils/data";
import CustomSelect from "components/common/Select";

const MatchingModalContent = ({ data, onClick }) => {
  const { id: uid } = useParams();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(projectMatchSearchOption[0]);
  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };
  const { data: projectData, error: projectDataError } = useSWR(`/api/v1/admin/projects/expert-match?${selectedOption.searchOpt}=${searchValue}&page=${currentPage}`, (url) =>
    fetcherPrivate(url, { params: { page: 1, size: 20, direction: "DESC" } }, { revalidateOnFocus: false })
  );

  // 검색어 입력 값

  const onChangeSearchValue = (e) => setSearchValue(e.target.value);

  const onClickSearch = () => {
    //mutate();
  };

  const onChangeSelect = (value) => {
    setSelectedOption(projectMatchSearchOption[value]);
  };

  // 이메일 전송
  const onClickEmail = async (id) => {
    try {
      const res = axiosPrivate.post(`/api/v1/admin/experts/${uid}/projects`, {
        projectId: id,
      });
      // if (res.status === 200) {
      //   navigate("/resource/benchmarking?page=1&size=20&direction=DESC");
      // }
    } catch (error) {
      console.log(error);
    }
    // /api/v1/admin/experts/${uid}/projects
    console.log(id);
  };

  // 체크박스 선택
  const [checkedArr, setCheckedArr] = useState([]);
  const onChangeCheckbox = (checked, id) => {
    if (checked) {
      setCheckedArr([id]);
    } else {
      const oldArr = [...checkedArr];
      const newArr = oldArr.filter((item) => item !== id);
      setCheckedArr(newArr);
    }
  };

  const onSubmit = (e) => {
    const chkLen = checkedArr.length;
    try {
      checkedArr.forEach((item) => {
        const res = axiosPrivate.post(`/api/v1/admin/experts/${uid}/projects`, {
          projectId: item,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <h1>프로젝트 매칭</h1>
      <CloseBtn type="button" onClick={onClick} />

      <form onSubmit={onSubmit}>
        <InputWrapper>
          <div style={{ display: "flex", width: "500px" }}>
            <CustomSelect style={{ width: "150px" }} optionList={projectMatchSearchOption} value={selectedOption} onChange={onChangeSelect} />
            <div style={{ marginLeft: "20px", marginTop: "1px", width: "100%" }}>
              <Input type="text" placeholder="검색어를 입력하세요." value={searchValue} onChange={onChangeSearchValue} />
            </div>
          </div>

          <button type="button" onClick={onClickSearch}>
            검색
          </button>
        </InputWrapper>
        <TableWrapper>
          <Table>
            <Thead>
              <tr>
                <th style={{ width: "50px" }}></th>
                <th>프로젝트 명</th>
                <th style={{ width: "100px" }}>상태</th>
                <th style={{ width: "80px" }}>버전</th>
                <th style={{ width: "120px" }}>회원명</th>
                <th>기업명</th>
                <th>기관명</th>
                <th>시작일/종료일</th>
              </tr>
            </Thead>
            <Tbody>
              {projectData?.result?.content.map((item, i) => (
                <tr key={i}>
                  <td>
                    <CheckboxWrapper>
                      <input type="checkbox" id={item.id} value={item.id} checked={checkedArr?.includes(item.id)} onChange={(e) => onChangeCheckbox(e.target.checked, item.id)} />
                      <label htmlFor={item.id}></label>
                    </CheckboxWrapper>
                  </td>
                  <td>
                    <div>{item.name}</div>
                  </td>
                  <td>
                    <div>{item.projectStatusType == "STOP" ? "진행 중단" : item.projectStatusType == "COMPLETION" ? "진행 완료" : "진행 중"}</div>
                  </td>
                  <td>
                    <div>{item.version}</div>
                  </td>
                  <td>
                    <div>{item.memberName}</div>
                  </td>
                  <td>
                    <div>{item.companyName}</div>
                  </td>
                  <td>
                    <div>{item.authCodeCompanyName}</div>
                  </td>
                  <td className="with-btn">
                    <div>
                      <Duration>
                        <p>{item.projectStartDate}</p>
                        <p>{item.projectEndDate}</p>
                      </Duration>
                      <EmailBtn onClick={() => onClickEmail(item.id)}>
                        <MailOutlined style={{ fontSize: "14px" }} />
                      </EmailBtn>
                    </div>
                  </td>
                </tr>
              ))}
            </Tbody>
          </Table>
        </TableWrapper>
        <Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={projectData?.result?.totalElements} pageSize={20} />
        <CancelConfirmBtn onClickCancel={onClick} confirmText="저장" />
      </form>
    </Wrapper>
  );
};

export default MatchingModalContent;
