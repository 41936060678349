import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Pie as ChartPie } from "react-chartjs-2";
import { PieContainer } from "./styles";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Ver 1", "Ver 2", "Ver 3"],
  // labels: ["진행", "완료", "중단"],

  datasets: [
    {
      data: [54, 26, 20],
      backgroundColor: ["rgba(75, 192, 192,0.6)", "rgba(255, 206, 86, 0.6)", "rgba(255, 99, 132, 0.6)"],
      borderColor: ["rgba(75, 192, 192,0.6)", "rgba(255, 206, 86, 0.6)", "rgba(255, 99, 132, 0.6)"],
      borderWidth: 1,
      hoverOffset: 10,
    },
  ],
};

const options = {
  layout: {
    padding: {
      left: 10,
    },
  },
  plugins: {
    legend: {
      position: "right",
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        font: {
          family: "'Noto Sans', sans-serif",
          size: 12,
        },
      },
    },
  },
};

const Pie = ({ datas, totalType }) => {
  const [pieData, setPieData] = useState(null);
  const [labels, setLabels] = useState(["Ver 1", "Ver 2", "Ver 3"]);
  const chartRef = useRef(null);
  const isStatusType = totalType === "status";

  useEffect(() => {
    console.log();
    if (datas) {
      setPieData({
        labels: isStatusType ? ["진행", "완료", "중단"] : Object.entries(datas).map((value) => `Ver ${value[0]}`),
        // labels: ["진행", "완료", "중단"],

        datasets: [
          {
            data: isStatusType
              ? Object.entries(datas).reduce(
                  (acc, cur) => {
                    if (cur[0] === "PROCEEDING") {
                      acc[0] = cur[1];
                    }
                    if (cur[0] === "COMPLETION") {
                      acc[1] = cur[1];
                    }
                    if (cur[0] === "STOP") {
                      acc[2] = cur[1];
                    }
                    return acc;
                  },
                  [0, 0, 0]
                )
              : Object.entries(datas).map((value) => value[1]),
            backgroundColor: ["rgba(75, 192, 192,0.6)", "rgba(255, 206, 86, 0.6)", "rgba(255, 99, 132, 0.6)"],
            borderColor: ["rgba(75, 192, 192,0.6)", "rgba(255, 206, 86, 0.6)", "rgba(255, 99, 132, 0.6)"],
            borderWidth: 1,
            hoverOffset: 10,
          },
        ],
      });
    }
  }, [datas, totalType]);

  useEffect(() => {
    if (chartRef) {
      chartRef?.current?.update();
    }
  }, [pieData, chartRef]);

  return (
    <>
      {datas && pieData ? (
        <PieContainer>
          <ChartPie key={Math.random()} ref={chartRef} data={pieData} options={options} />
        </PieContainer>
      ) : null}
    </>
  );
};

export default Pie;
