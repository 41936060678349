import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GNB from "layouts/GNB";
import Nav from "layouts/Nav";
import SelectRange from "components/dashboard/SelectRange";
import Pie from "components/dashboard/Graph/Pie";
import Line from "components/dashboard/Graph/Line";
import Bubble from "components/dashboard/Graph/Bubble";
import ShowMoreBtn from "components/dashboard/ShowMoreBtn";
import { dashBoardRangeOption, userRegisterRangeOption } from "utils/data";
import { CaretUpOutlined, CaretDownOutlined, FileTextOutlined, BulbOutlined, FormOutlined, UserOutlined, CommentOutlined } from "@ant-design/icons";
import {
  Main,
  PageHeading,
  FlexWrapper,
  BoxFlexWrapper,
  SmallBox,
  LongBox,
  ProgressList,
  ProgressItem,
  TotalNumber,
  CompareNumber,
  FlexColumnWrapper,
  HeadingWrapper,
  PieWrapper,
  ChartBtn,
  ProgressBottom,
  SmallBoxHeadingWrapper,
  MatchingList,
  LineWrapper,
  ResourceBtnWrapper,
  PageSelectWrapper,
  ShowMoreLink,
  YearSelector,
} from "./styles";

// TODO 지우기
import { lineMockData, bubbleMockData } from "utils/mockData";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import moment from "moment";
const Home = () => {
  // TODO
  // 백엔드 데이터
  const [totalType, setTotalType] = useState("status");
  const [lineData, setLineData] = useState();
  const [memberTotal, setMemberTotal] = useState(0);
  const [matchingDueDate, setMatchingDueDate] = useState("TOTAL");
  const [keywordType, setKeywordType] = useState("BUSINESS");
  const [searchYear, setSearchYear] = useState(new moment().format("YYYY"));
  const { data: totalProject, error: totalProjectError } = useSWR(`/api/v1/admin/dashboard/statistics/projects`, (url) => fetcherPrivate(url));
  const { data: yearData, error: yearDataError } = useSWR(`/api/v1/admin/dashboard/statistics/members?year=${searchYear}`, (url) => fetcherPrivate(url));
  const isYear = new moment().format("YYYY");
  const { data: projectStatus, error: projectStatusError } = useSWR(`/api/v1/admin/dashboard/statistics/projects/status`, (url) => fetcherPrivate(url));
  const [bubbleData, setBubbleData] = useState();
  let navigate = useNavigate();
  let bubbleInitData = {
    name: "benchmarking",
    children: [
      {
        name: "1",
        amount: "0",
      },
    ],
  };
  const {
    data: matchingData,
    mutate: matchingDataMutate,
    isValidating: mathcingValidate,
  } = useSWR(`/api/v1/admin/dashboard/statistics/projects/experts?projectDashboardPeriodType=${matchingDueDate}`, (url) => fetcherPrivate(url));
  const [resourceDueDate, setResourceDueDate] = useState("TOTAL");
  const {
    data: resourceData,
    mutate: resourceDataMutate,
    isValidating: resourceDatavalidate,
  } = useSWR(`/api/v1/admin/dashboard/statistics/resources/keywords?projectDashboardPeriodType=${resourceDueDate}&keywordType=${keywordType}`, (url) => fetcherPrivate(url));

  // /api/v1/admin/dashboard/statistics/projects

  useEffect(() => {
    initLineData();
    resourceData &&
      resourceData.result.map((item, index) => {
        console.log(item.keywordName);
        bubbleInitData.children[index] = {
          name: item.keywordName,
          amount: item.keywordUseCount,
        };
      });

    resourceData && setBubbleData((prev) => bubbleInitData);
  }, []);

  const initLineData = () => {
    setLineData([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  };

  useEffect(() => {
    //matchingDataMutate();
    initLineData();
    let lines = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let total = 0;
    if (yearData) {
      const list = yearData.result;
      list.map((month) => {
        const joinMonth = moment(month.joinMonth);
        if (searchYear == joinMonth.get("year")) {
          const mm = joinMonth.get("M");

          lines.forEach((l, i) => {
            if (i == mm) {
              lines[i] = month.memberSignUpCount;
              total += month.memberSignUpCount;
            }
          });
        }

        setLineData(lines);
      });
    }

    setMemberTotal(total);
  }, [yearData]);

  useEffect(() => {
    matchingDataMutate();
  }, [matchingDueDate]);

  useEffect(() => {
    resourceDataMutate();
    resourceData &&
      resourceData.result.map((item, index) => {
        bubbleInitData.children[index] = {
          name: item.keywordName,
          amount: item.keywordUseCount,
        };
      });
    setBubbleData(bubbleInitData);
  }, [resourceDueDate, keywordType, resourceData]);

  const onChangeTopRange = (value) => {
    console.log(value);
  };
  const logout = (value) => {
    window.localStorage.clear();
    navigate("/");
  };

  const onChangeMatchingRange = (value) => {
    setMatchingDueDate(value);
  };

  const onChangeRegisterRange = (value) => {
    console.log(value);
  };

  const onChangeResourceRange = (value) => {
    setResourceDueDate(value);
  };

  const onSearchPrevYear = () => {
    setSearchYear((searchYear) => parseInt(searchYear) - 1);
  };

  const onSearchNextYear = () => {
    setSearchYear((searchYear) => parseInt(searchYear) + 1);
  };

  return (
    <>
      {/*<GNB />  로고삭제 요청으로 주석처리*/}
      <Nav defaultMenu={["1"]} />
      <Main>
        <BoxFlexWrapper style={{ justifyContent: "space-between" }}>
          <PageHeading>홈</PageHeading>
          <button style={{ marginRight: "40px", border: "1px solid #d0d0d0", padding: "10px 20px 10px 20px", borderRadius: "10px", fontWeight: "600" }} onClick={logout}>
            <strong>로그아웃</strong>
          </button>
        </BoxFlexWrapper>
        {/* 
        <PageSelectWrapper>
          <SelectRange optionArr={dashBoardRangeOption} onChange={onChangeTopRange} style={{ margin: "0 0 0 auto" }} />
        </PageSelectWrapper> */}
        <div style={{ marginTop: "30px" }}>
          {/* 상단 */}
          <BoxFlexWrapper>
            {/* 상단 - 1 */}
            <SmallBox>
              <HeadingWrapper>
                <FlexColumnWrapper>
                  <h3>프로젝트 총 건수 </h3>
                  <TotalNumber>
                    <strong>{totalProject?.result.projectTotalCount}</strong>건
                  </TotalNumber>
                  <CompareNumber>
                    {/* <CaretDownOutlined style={{ color: "#4556EE" }} />
                    <span>한달 전 보다&nbsp;</span>
                    <span>{totalProject?.result.projectMoMCount}</span> */}
                  </CompareNumber>
                </FlexColumnWrapper>
                <ShowMoreLink to="/project/list?page=1&size=20&direction=DESC">더보기 &gt;</ShowMoreLink>
              </HeadingWrapper>

              <PieWrapper>
                <FlexWrapper>
                  {/* TODO : isActive */}
                  <ChartBtn type="button" isActive={totalType === "ver"} onClick={() => setTotalType("ver")}>
                    버전별
                  </ChartBtn>
                  <ChartBtn type="button" isActive={totalType === "status"} onClick={() => setTotalType("status")}>
                    상태별
                  </ChartBtn>
                </FlexWrapper>
                <Pie datas={totalType === "status" ? totalProject?.result.projectStatusCountRatioMap : totalProject?.result.projectVersionCountRatioMap} totalType={totalType} />
              </PieWrapper>
            </SmallBox>
            {/* 상단 - 2 */}
            <LongBox>
              <HeadingWrapper>
                <FlexColumnWrapper>
                  <h3>프로젝트 진행</h3>
                  <TotalNumber>
                    <strong>{projectStatus?.result.projectProcessStatusCount}</strong>건
                  </TotalNumber>
                  <CompareNumber>
                    {/* <CaretUpOutlined style={{ color: "#CE5E41" }} />
                    <CaretDownOutlined style={{ color: "#4556EE" }} />
                    <span>한달 전 보다&nbsp;</span>
                    <span>+432</span> */}
                  </CompareNumber>
                </FlexColumnWrapper>
                <ShowMoreLink to="/project/list?page=1&size=20&direction=DESC">더보기 &gt;</ShowMoreLink>
              </HeadingWrapper>

              {/* 프로젝트 진행 */}
              <FlexWrapper>
                <ProgressList>
                  <ProgressItem>
                    <FileTextOutlined style={{ fontSize: "20px", color: "#4556EE" }} />
                    <h3>분석</h3>
                    <strong>{projectStatus?.result.projectStepTypeCountMap.ANALYSIS}</strong>
                  </ProgressItem>
                  <ProgressItem>
                    <BulbOutlined style={{ fontSize: "20px", color: "#4556EE" }} />
                    <h3>컨셉</h3>
                    <strong>{projectStatus?.result.projectStepTypeCountMap.CONCEPT}</strong>
                  </ProgressItem>
                  <ProgressItem>
                    <FormOutlined style={{ fontSize: "20px", color: "#4556EE" }} />
                    <h3>제작</h3>
                    <strong>{projectStatus?.result.projectStepTypeCountMap.PRODUCE}</strong>
                  </ProgressItem>
                  <ProgressItem>
                    <UserOutlined style={{ fontSize: "20px", color: "#4556EE" }} />
                    <h3>테스트</h3>
                    <strong>{projectStatus?.result.projectStepTypeCountMap.PROJECT_TEST}</strong>
                  </ProgressItem>
                  <ProgressItem>
                    <CommentOutlined style={{ fontSize: "20px", color: "#4556EE" }} />
                    <h3>리뷰</h3>
                    {/* 현재 리뷰에 대한 데이터가 없음 임시 로 0 입력 */}
                    <strong>{projectStatus?.result.projectStepTypeCountMap.REVIEW}</strong>
                  </ProgressItem>
                </ProgressList>
              </FlexWrapper>
              {/* 프로젝트 완료, 중단 */}
              <ProgressBottom>
                <div>
                  <h4>프로젝트 완료</h4>
                  <TotalNumber>{projectStatus?.result.projectCompletionStatusCount}</TotalNumber>
                  <CompareNumber>
                    {/* <CaretUpOutlined style={{ color: "red" }} />
                    <span>한달 전 보다&nbsp;</span>
                    <span>+432</span> */}
                  </CompareNumber>
                </div>
                <div>
                  <h4>프로젝트 중단 </h4>
                  <TotalNumber>{projectStatus?.result.projectStopStatusCount}</TotalNumber>
                  <CompareNumber>
                    {/* <CaretUpOutlined style={{ color: "red" }} />
                    <span>한달 전 보다&nbsp;</span>
                    <span>+432</span> */}
                  </CompareNumber>
                </div>
              </ProgressBottom>
            </LongBox>
          </BoxFlexWrapper>
          {/* 하단 */}
          <BoxFlexWrapper>
            {/* 전문가 매칭 건수 */}
            <SmallBox>
              <SmallBoxHeadingWrapper>
                <h3>전문가 매칭 건수</h3>
                <SelectRange optionArr={dashBoardRangeOption} onChange={onChangeMatchingRange} />
              </SmallBoxHeadingWrapper>
              <MatchingList>
                <li>
                  <h5>매니저 매칭</h5>
                  <p>
                    {!mathcingValidate && (
                      <>
                        <strong>{matchingData?.result.filter((val) => val.expertType === "MANAGER")[0]?.expertMatchedCount ?? 0}</strong>건
                      </>
                    )}
                  </p>
                  <ShowMoreBtn targetTab="MANAGER" />
                </li>
                <li>
                  <h5>디자이너 매칭</h5>
                  <p>
                    {!mathcingValidate && (
                      <>
                        <strong>{matchingData?.result.filter((val) => val.expertType === "DESIGNER")[0]?.expertMatchedCount ?? 0}</strong>건
                      </>
                    )}
                  </p>
                  <ShowMoreBtn targetTab="DESIGNER" />
                </li>
                <li>
                  <h5>멘토 매칭</h5>
                  <p>
                    {!mathcingValidate && (
                      <>
                        <strong>{matchingData?.result.filter((val) => val.expertType === "MENTOR")[0]?.expertMatchedCount ?? 0}</strong>건
                      </>
                    )}
                  </p>
                  <ShowMoreBtn targetTab="MENTOR" />
                </li>
              </MatchingList>
            </SmallBox>
            {/* 회원 가입 건수  */}
            <SmallBox>
              <SmallBoxHeadingWrapper>
                <h3 style={{ marginTop: "6px", marginBottom: "6px" }}>회원 가입 건수</h3>
                {/* 년도별 검색만 하기로 함 주석처리
                <SelectRange optionArr={userRegisterRangeOption} onChange={onChangeRegisterRange} />
                */}
              </SmallBoxHeadingWrapper>
              <LineWrapper>
                <FlexColumnWrapper>
                  <TotalNumber>
                    총 <strong>{memberTotal}</strong>명
                  </TotalNumber>
                  <CompareNumber>
                    {/* <CaretUpOutlined style={{ color: "#CE5E41" }} />
                    <CaretDownOutlined style={{ color: "#4556EE" }} /> */}
                    {/* <span>한달 전 보다&nbsp;</span>
                    <span>+432</span>*/}
                  </CompareNumber>
                  <div>
                    <YearSelector>
                      <a onClick={onSearchPrevYear}>&lt;</a>&nbsp;&nbsp;{searchYear}년&nbsp;&nbsp;
                      {searchYear == isYear ? <a style={{ color: "#d0d0d0" }}>&gt;</a> : <a onClick={onSearchNextYear}>&gt;</a>}
                    </YearSelector>
                  </div>
                </FlexColumnWrapper>
                <Line datas={lineData} />
              </LineWrapper>
            </SmallBox>
            {/* 리소스 사용 현황  */}
            <SmallBox>
              <SmallBoxHeadingWrapper>
                <h3>리소스 사용현황</h3>
                <SelectRange optionArr={dashBoardRangeOption} onChange={onChangeResourceRange} />
              </SmallBoxHeadingWrapper>
              <ResourceBtnWrapper>
                {/* TODO : isActive */}
                {/* 버튼 컴포넌트화 */}
                {/* 1차범위 제외로 주석처리
                <ChartBtn type="button" isActive={keywordType === "BENCHMARKING"} onClick={() => setKeywordType("BENCHMARKING")}>
                  벤치마킹
                </ChartBtn> */}
                <ChartBtn type="button" isActive={keywordType === "BUSINESS"} onClick={() => setKeywordType("BUSINESS")}>
                  비즈니스
                </ChartBtn>
                <ChartBtn type="button" isActive={keywordType === "PRODUCT"} onClick={() => setKeywordType("PRODUCT")}>
                  프로덕트
                </ChartBtn>
                <ChartBtn type="button" isActive={keywordType === "MAIN_POINT"} onClick={() => setKeywordType("MAIN_POINT")}>
                  핵심기능
                </ChartBtn>
                <ChartBtn type="button" isActive={keywordType === "DESIGN_CONCEPT"} onClick={() => setKeywordType("DESIGN_CONCEPT")}>
                  디자인컨셉
                </ChartBtn>
              </ResourceBtnWrapper>
              {bubbleData && <Bubble data={bubbleData} />}
            </SmallBox>
          </BoxFlexWrapper>
        </div>
      </Main>
    </>
  );
};

export default Home;
