import styled from "styled-components";
import { flexbox, flexboxColumn, fontStyle13, fontStyle14 } from "styles/mixins";

export const Container = styled.div`
  padding: 20px;
  border-top: 1px solid #181818;
  background-color: #f2f2f2;

  form {
    ${flexbox("space-between", "center")};
  }
`;

export const SelectInput = styled.div`
  ${flexbox("flex-start", "center")};
  width: 900px;
  margin: 0 0 15px 0;

  .ant-select {
    width: 160px !important;
    margin: 0 15px 0 0;
  }

  input {
    background-color: ${(props) => props.theme.white};
    height: 42px;
    width: 725px;
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.border};
    transition: border-color 300ms ease-in-out;
    font-weight: 500;
    ${fontStyle13};
    color: ${(props) => props.theme.black};

    &:hover,
    &:focus {
      border: 1px solid ${(props) => props.theme.borderActive};
    }

    ::placeholder {
      font-weight: 500;
      ${fontStyle13};
      color: ${(props) => props.theme.placeholder};
    }
  }
`;

export const BtnWrapper = styled.div`
  ${flexboxColumn("space-around", "center")};
  button {
    height: 34px;
    width: 90px;
    border-radius: 120px;
    ${fontStyle14};
    font-weight: 500;
  }

  button:first-of-type {
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    margin: 0 0 15px 0;
    transition: background-color 300ms ease-in-out;

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.blueDark};
    }
  }

  button:last-of-type {
    background-color: ${(props) => props.theme.white};
    color: ${(props) => props.theme.black};

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.buttonBgWhiteHover};
    }
  }
`;
