import { useState, useEffect } from "react";
import { Wrapper, StyledInput, DelBtn } from "./styles";
const Input = ({ placeholder, id, value, onChange, maxLength, hasDelBtn, onClickInputDelete, disabled = false }) => {
  const [isDelVisible, setIsDelVisible] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIsDelVisible(true);
    } else {
      setIsDelVisible(false);
    }
  }, [value]);
  return (
    <Wrapper>
      <StyledInput type="text" placeholder={placeholder} id={id} value={value} onChange={onChange} maxLength={maxLength} disabled={disabled} />
      {hasDelBtn && isDelVisible && <DelBtn type="button" onClick={onClickInputDelete} />}
    </Wrapper>
  );
};

export default Input;
