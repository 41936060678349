import styled from "styled-components";
import { Tabs } from "antd";

export const StyledTabs = styled(Tabs)`
  color: ${(props) => props.theme.primary};

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  :not(:disabled).ant-tabs-tab-btn:hover {
    color: ${(props) => props.theme.black};
    font-weight: 500;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: ${(props) => props.theme.black};
  }

  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.black};
  }

  .ant-tabs-tab-disabled {
    pointer-events: none;
  }

  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: ${(props) => props.theme.black};
  }
`;
