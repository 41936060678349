const { default: Layout } = require("layouts/Layout");

const Request = () => {
  return (
    <Layout defaultOpenKeys={["sub1"]} defaultMenu={["3"]}>
      matching request
    </Layout>
  );
};

export default Request;
