// search box
//range radio btn

export const TODAY = "TODAY";
export const MONTH = "MONTH";
export const MONTHS = "SIX_MONTH";
export const YEAR = "YEAR";
export const ALL = "TOTAL";

// 홈 대시보드
// TODO. back data
export const dashBoardRangeOption = [
  {
    id: 0,
    value: ALL,
    text: "전체",
  },
  {
    id: 1,
    value: MONTH,
    text: "1개월",
  },
  {
    id: 2,
    value: MONTHS,
    text: "6개월",
  },
  {
    id: 3,
    value: YEAR,
    text: "1년",
  },
];

export const userRegisterRangeOption = [
  {
    id: 0,
    value: "monthly",
    text: "월별",
  },
  {
    id: 1,
    value: "yearly",
    text: "연도별",
  },
];

export const rangeBtnOption = [
  {
    id: 0,
    value: TODAY,
    text: "오늘",
  },
  {
    id: 1,
    value: MONTH,
    text: "1개월",
  },
  {
    id: 2,
    value: MONTHS,
    text: "6개월",
  },
  {
    id: 3,
    value: ALL,
    text: "전체",
  },
];

// 프로젝트 관리 - 프로젝트 목록
export const projectSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "프로젝트명",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "회원명",
    searchOpt: "memberName",
  },
  {
    id: 3,
    value: 3,
    text: "기업명",
    searchOpt: "companyName",
  },
  {
    id: 4,
    value: 4,
    text: "기관명",
    searchOpt: "authCodeCompanyName",
  },
];

//프로젝트매칭
export const projectMatchSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "프로젝트명",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "회원명",
    searchOpt: "memberName",
  },
  {
    id: 3,
    value: 3,
    text: "기업명",
    searchOpt: "companyName",
  },
  {
    id: 4,
    value: 4,
    text: "기관명",
    searchOpt: "authCodeCompanyName",
  },
];

export const projectSearchDateType = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "projectSearchDateType",
    searchVal: null,
  },
  {
    id: 1,
    value: 1,
    text: "프로젝트 시작일",
    searchOpt: "projectSearchDateType",
    searchVal: "START",
  },
  {
    id: 2,
    value: 2,
    text: "프로젝트 종료일",
    searchOpt: "projectSearchDateType",
    searchVal: "END",
  },
];

// 리소스 관리
// 벤치마킹 리소스 search box
export const benchSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "리소스 명",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "키워드 명",
    searchOpt: "keywordName",
  },
];

// 벤치마킹 매칭 리소스 search box
export const benchMatchSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "리소스 명",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "키워드 명",
    searchOpt: "keywordName",
  },
];

// 벤치마킹 리소스 등록 - 키워드 옵션
export const benchmarkingKeywordOption = [
  {
    id: 0,
    value: "BUSINESS",
    text: "비즈니스 키워드",
  },
  {
    id: 1,
    value: "PRODUCT",
    text: "프로덕트 키워드",
  },
];

// 키워드
export const keywordSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",

    searchOpt: "name",
  },
  // {
  //   id: 1,
  //   value: 1,
  //   text: "유형",
  //   searchOpt: "keywordType",
  //   searchVal: "PRODUCT",
  // },
  {
    id: 1,
    value: 1,
    text: "키워드",
    searchOpt: "name",
    searchVal: "KEYWORD",
  },
];

// 키워드 ETC서치
export const keywordSearchOptionETC = [
  {
    id: 0,
    value: 0,
    text: "전체",

    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "키워드",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "회원명",
    searchOpt: "memberName",
  },
];
// 키워드 핵심기능(MAIN_POINT)서치
export const keywordSearchOptionMAIN_POINT = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "키워드",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "서비스명",
    searchOpt: "serviceName",
  },
];
//  키워드 리소스 등록 - 키워드 타입 옵션
export const keywordTypeOption = [
  {
    id: 0,
    value: "BUSINESS",
    text: "비즈니스 유형",
  },
  {
    id: 1,
    value: "PRODUCT",
    text: "프로덕트 분야 유형",
  },
  {
    id: 2,
    value: "MAIN_POINT",
    text: "핵심 기능",
  },
  {
    id: 3,
    value: "DESIGN_CONCEPT",
    text: "디자인 컨셉",
  },
];

// 템플릿 검색
export const templateSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "템플릿 명",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "비즈니스 유형",
    searchOpt: "businessModelType",
  },
];
//

// 키워드 탭 테이블 헤더
export const templateTableHeader = [
  { name: "상태", width: "100px" },
  { name: "템플릿 명", width: "150px" },
  { name: "비즈니스 유형", width: "150px" },
  { name: "템플릿 설명" },
  { name: "이미지", width: "150px" },
  { name: "노출", width: "70px" },
  { name: "등록일", width: "180px" },
];

export const templateTypeOption = [
  {
    id: 0,
    value: "BUSINESS_MODEL",
    text: "비지니스 모델",
  },
  {
    id: 1,
    value: "SCREEN_LAYOUT",
    text: "앱화면 유형",
    disabled: true,
  },
  // {
  //   id: 2,
  //   value: 2,
  //   text: "콘텐츠 제공 중개형",
  // },
  // {
  //   id: 3,
  //   value: 3,
  //   text: "유료 콘텐츠 다면형",
  // },
  // {
  //   id: 4,
  //   value: 4,
  //   text: "위치 기반 다면형",
  // },
  // {
  //   id: 5,
  //   value: 5,
  //   text: "중개 금융형",
  // },
  // {
  //   id: 6,
  //   value: 6,
  //   text: "대여 관리 통합형",
  // },
  // {
  //   id: 7,
  //   value: 7,
  //   text: "인력 매칭형",
  // },
  // {
  //   id: 8,
  //   value: 8,
  //   text: "큐레이션형",
  // },
  // {
  //   id: 9,
  //   value: 9,
  //   text: "공유 경제형",
  // },
  // {
  //   id: 10,
  //   value: 10,
  //   text: "제휴형",
  // },
  // {
  //   id: 11,
  //   value: 11,
  //   text: "트래픽 기반 광고형",
  // },
  // {
  //   id: 12,
  //   value: 12,
  //   text: "투자 중개형",
  // },
];

//----------------------------------------------------------------

// 사용자
// 사용자 search box
export const userSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "이메일",
    searchOpt: "email",
  },
  {
    id: 2,
    value: 2,
    text: "회원 명",
    searchOpt: "name",
  },
  {
    id: 3,
    value: 3,
    text: "기업 명",
    searchOpt: "companyName",
  },
  {
    id: 4,
    value: 4,
    text: "연락처",
    searchOpt: "phone",
  },
  {
    id: 5,
    value: 5,
    text: "인증 코드",
    searchOpt: "authCode",
  },
];

// 사용자 상세 정보 - 프로젝트 이용 목록
export const userProjectSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체 진행 단계",
  },
  {
    id: 1,
    value: 1,
    text: "분석",
  },
  {
    id: 2,
    value: 2,
    text: "컨셉",
  },
  {
    id: 3,
    value: 3,
    text: "제작",
  },
  {
    id: 4,
    value: 4,
    text: "테스트",
  },
  {
    id: 5,
    value: 5,
    text: "리뷰",
  },
];

// 전문가
// 전문가 search box
export const expertSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "이름",
    searchOpt: "name",
  },
  {
    id: 2,
    value: 2,
    text: "닉네임",
    searchOpt: "nick",
  },
  {
    id: 3,
    value: 3,
    text: "연락처",
    searchOpt: "phone",
  },
];

// 인증코드 search box
export const authSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
    searchOpt: "allSearch",
  },
  {
    id: 1,
    value: 1,
    text: "인증코드",
    searchOpt: "authCode",
  },
  {
    id: 2,
    value: 2,
    text: "기관명",
    searchOpt: "companyName",
  },
];

// 전문가 상세 search box
export const expertDetailSearchOption = [
  {
    id: 0,
    value: 0,
    text: "전체",
  },
  {
    id: 1,
    value: 1,
    text: "프로젝트 명",
  },
  {
    id: 2,
    value: 2,
    text: "회원명",
  },
  {
    id: 3,
    value: 3,
    text: "기업명",
  },
];

// 전문가 타입 옵션 리스트
export const expertTypeOption = [
  {
    id: 0,
    value: "MANAGER",
    text: "매니저",
  },
  {
    id: 1,
    value: "DESIGNER",
    text: "디자이너",
  },
  {
    id: 2,
    value: "MENTOR",
    text: "멘토",
  },
];

const generateOptionList = (max, min, ascending) => {
  const optionList = [];
  for (let i = max; i >= min; i--) {
    const newObj = {
      value: i,
      text: i,
    };
    optionList.push(newObj);
  }
  return ascending ? optionList.reverse() : optionList;
};

export const yearOption = generateOptionList(2022, 2022 - 60, false);
export const monthOption = generateOptionList(12, 1, true);
export const dayOption = generateOptionList(31, 1, true);
