import styled from "styled-components";

export const Container = styled.div``;

export const Main = styled.main`
  width: 1110px;
  padding: 20px 30px 100px;
  margin: 40px 0 0 240px;
  max-width: 1366px;
`;
