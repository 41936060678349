import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axiosPrivate from "utils/axiosPrivate";
import axiosPrivateFormData from "utils/axiosPrivateFormData";
import Layout from "layouts/Layout";
import CustomBreadcrumb from "components/common/Breadcrumb";
import CustomSelect from "components/common/Select";
import Input from "components/common/Input";
import PasswordInput from "components/common/PasswordInput";
import Textarea from "components/common/Textarea";
import ProfileUpload from "components/common/ProfileUpload";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { PageHeading, SubHeading, Table, EmailWrapper, EmailCheckBtn, FileWrapper, BirthWrapper, InputWrapper, HelperText, EmailInfoText } from "./styles";
import { expertTypeOption, yearOption, monthOption, dayOption } from "utils/data";
import { addZeroToStr, generateBirth } from "utils/helperFunctions";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import ic_default_startup_big from "assets/ic_default_startup_big.jpg";
import ic_default_manager_big from "assets/ic_default_manager_big.jpg";
import ic_default_disigner_big from "assets/ic_default_disigner_big.jpg";
import ic_default_mento_big from "assets/ic_default_mento_big.jpg";

import convertImgToFile from "utils/convertImgToFile";

const CertificationRegister = () => {
  const navigate = useNavigate();

  // 전문가 '등록' 페이지인지 '수정' 페이지인지 구분

  const [breadcrumb, setBreadCrumb] = useState("코드 생성");

  const breadcrumbItem = [
    {
      name: "인증 코드 관리",
    },
    {
      name: breadcrumb,
    },
  ];

  // 사진
  const fileRef = useRef();
  const [file, setFile] = useState(null);

  // 인증코드
  const [authCode, setAuthCode] = useState("");
  const onChangeAuthCode = (e) => setAuthCode(e.target.value);

  // 기관명
  const [companyName, setCompanyName] = useState("");
  const onChangeCompanyName = (e) => setCompanyName(e.target.value);

  // 필수 입력값 다 입력해야 확인 버튼 클릭 가능.
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (authCode && companyName) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [authCode, companyName]);

  const deletePhoto = async () => {
    //fileRef.current.value = "";
    /*
    try {
      const data = {
        layoutImage: expertType === "MANAGER" ? ic_default_manager_big : expertType === "DESIGNER" ? ic_default_disigner_big : expertType === "MENTOR" ? ic_default_mento_big : ic_default_startup_big,
        fileName:
          expertType === "MANAGER"
            ? "ic_default_manager_big.jpg"
            : expertType === "DESIGNER"
            ? "ic_default_disigner_big.jpg"
            : expertType === "MENTOR"
            ? "ic_default_mento_big.jpg"
            : "ic_default_startup_big.jpg",
        type: "image/jpg",
      };
      console.log(data, "fileinput");
      const newFile = await convertImgToFile(data);
      setFile(newFile);
    } catch (err) {
      console.log(err);
      window.alert("사진 삭제에 실패했습니다. 다시 시도해주세요!");
    }*/
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const value = {
      authCode,
      companyName,
    };

    const formData = new FormData();

    const req = new Blob([JSON.stringify(value)], { type: "application/json" });

    formData.append("req", req);

    if (file) {
      formData.append("logoImage", file);
    }

    axiosPrivateFormData
      .post("/api/v1/super/auth-codes", formData)
      .then((res) => {
        if (res.status === 200) {
          navigate("/certification?page=1&size=20&direction=DESC&expertType=MANAGER");
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.resultMsg);
      });
  };

  // 버튼
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const onClickCancel = () => {
    setIsCancelModalVisible(true);
  };
  // 취소 클릭
  const onClickCancelModal = () => {
    setIsCancelModalVisible(false);
  };

  const onClickSaveModal = () => {
    setIsCancelModalVisible(false);
    navigate("/certification");
  };

  return (
    <>
      <Layout defaultMenu={["9"]}>
        <CustomBreadcrumb item={breadcrumbItem} />
        <PageHeading>{"코드 생성"}</PageHeading>
        <form onSubmit={onSubmit}>
          <Table border="0">
            <tbody>
              <Tr>
                <RowThTd th="인증코드" isRequired={true}>
                  <InputWrapper>
                    <Input id="authCode" placeholder="인증 코드를 입력해주세요" value={authCode} onChange={onChangeAuthCode} maxLength={10} />
                    <small>{authCode.length} / 10</small>
                  </InputWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="기관명" isRequired={true}>
                  <InputWrapper>
                    <Input id="companyName" placeholder="기관명을 입력해주세요" value={companyName} onChange={onChangeCompanyName} maxLength={30} />
                    <small>{companyName.length} / 30</small>
                  </InputWrapper>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="사진">
                  <FileWrapper>
                    <ProfileUpload id="photo" file={file} setFile={setFile} isCode deletePhoto={deletePhoto} />
                    <div className="desc">
                      <p>* 기관 로고 이미지는 210 X 60px에 최적화</p>
                      <p>* 한 장당 10MB 이하, 최대 1장 / jpg, jpeg, png </p>
                    </div>
                  </FileWrapper>
                </RowThTd>
              </Tr>
            </tbody>
          </Table>
          <CancelConfirmBtn onClickCancel={onClickCancel} confirmText="확인" isDisabled={isDisabled} />
        </form>
      </Layout>
      {isCancelModalVisible && (
        <Modal isVisible={isCancelModalVisible}>
          <AlertModalContent onClickCancel={onClickCancelModal} onClickSave={onClickSaveModal} confrimText="확인">
            <h2>정말 취소하시겠습니까?</h2>
            <p>
              저장전 취소 시 입력한 데이터가 <br />
              모두 삭제됩니다.
            </p>
            <p>그래도 취소하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default CertificationRegister;
