import { useRecoilValue } from "recoil";
import { recoilKeywordTabKeySelector } from "states/recoilKeywordTabKeyState";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ToggleBtn from "../../ToggleBtn";

import EditLink from "../../EditLink";
import DeleteBtn from "../../DeleteBtn";
import DragHandle from "../DragHandle";
import { FlexWrapper, ImageTd, BtnTd } from "./styles";
import { MockImage } from "pages/resource/benchmarking/styles";
import { keywordTypes, subValue } from "utils/changeValue";

const SortableRow = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });

  const { id, tempSave, type, keyword, usage, isShowing, createdAt, keywordImage, name, serviceName, joinDate, keywordType, useCount, exposure, memberName } = props.data;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // 삭제 버튼
  const onClickDelete = () => props.onClickDel(id);

  // td
  const currentKeywordTab = useRecoilValue(recoilKeywordTabKeySelector);

  return (
    <tr ref={setNodeRef} style={style}>
      {currentKeywordTab.key === "ETC" ? (
        ""
      ) : (
        <td>
          <FlexWrapper>
            <DragHandle {...attributes} {...listeners} isDragging={isDragging} />
            <p> {tempSave ? "임시저장" : "등록"}</p>
          </FlexWrapper>
        </td>
      )}

      <td>
        {currentKeywordTab.key === "ETC" ? (
          <FlexWrapper>
            <DragHandle {...attributes} {...listeners} isDragging={isDragging} />
            <p>{subValue(keywordTypes, keywordType)}</p>
          </FlexWrapper>
        ) : (
          <FlexWrapper>
            <p>{subValue(keywordTypes, keywordType)}</p>
          </FlexWrapper>
        )}
      </td>
      <td>{name}</td>
      {currentKeywordTab.key === "MAIN_POINT" && <td>{serviceName}</td>}
      {currentKeywordTab.key === "DESIGN_CONCEPT" && (
        <td>
          <ImageTd isSmall>
            <img src={keywordImage} alt="keyword image" />
          </ImageTd>
        </td>
      )}

      {currentKeywordTab.key === "MAIN_POINT" && (
        <td>
          <MockImage>
            <img src={keywordImage} alt="keyword image" />
          </MockImage>
        </td>
      )}

      <td>{currentKeywordTab.key === "ETC" ? memberName : useCount}</td>
      {currentKeywordTab.key === "ETC" ? (
        ""
      ) : (
        <td>
          <ToggleBtn id={id} onToggle={props.onToggle} showing={exposure} disabled />
        </td>
      )}

      <td>
        <BtnTd>
          <p>{joinDate}</p>
          <FlexWrapper>
            {currentKeywordTab.key === "ETC" ? "" : <EditLink to={`/resource/keyword/edit/${id}`} />}
            <DeleteBtn
              onClick={() => {
                onClickDelete(id);
              }}
            />
          </FlexWrapper>
        </BtnTd>
      </td>
    </tr>
  );
};

export default SortableRow;
