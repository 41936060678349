import { useState, useEffect } from "react";
import Layout from "layouts/Layout";
import CustomBreadcrumb from "components/common/Breadcrumb";
import CustomSelect from "components/common/Select";
import Input from "components/common/Input";
import FileUpload from "components/common/FileUpload";
import Tr from "components/common/RowTable/Tr";
import RowThTd from "components/common/RowTable/RowThTd";
import CancelConfirmBtn from "components/common/CancelConfirmBtn";
import { PageHeading, SubHeading, Table, ImageHeading, TempSaveBtn, BtnWrapper, HelperText } from "./styles";
import { keywordTypeOption } from "utils/data";
import ToggleBtn from "components/common/ToggleBtn";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { addKeyword, editKeyword } from "api/resource/keyword";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";

const KeywordRegister = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: keywordData,
    error: keywordDataError,
    mutate,
  } = useSWR(params.id ? `/api/v1/admin/projects/keywords/${params.id}` : null, (url) => fetcherPrivate(url), {
    revalidateOnFocus: false,
  });

  const breadcrumbItem = [
    {
      name: "리소스 관리",
    },
    {
      name: "키워드 관리",
    },
    {
      name: "키워드 등록",
    },
  ];

  // 키워드 구분 - 키워드 유형
  const [keywordType, setKeywordType] = useState();
  const onChangeKeywordType = (value) => setKeywordType(value);

  // 키워드 - 키워드 인풋
  const [keywordInput, setKeywordInput] = useState("");
  const onChangeKeywordInput = (e) => setKeywordInput(e.target.value);
  const onClickInputDelete = () => setKeywordInput("");

  // 키워드 - 파일 업로드
  const [file, setFile] = useState();

  // 키워드 - 서비스명
  const [serviceName, setServiceName] = useState("");
  const onChangeServiceName = (e) => setServiceName(e.target.value);
  const onClickServiceInputDelete = () => setServiceName("");

  //키워드 -  노출
  const [toggled, setToggled] = useState(false);
  const onToggleShow = (id, toggled) => setToggled(toggled);

  // 저장 버튼 - 저장 모달
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const onCancelSaveModal = () => setIsSaveModalVisible(false);

  //상태
  const [status, setStatus] = useState(false);

  //등록인
  const [joinDate, setJoinDate] = useState(false);

  useEffect(() => {
    if (keywordData) {
      const { exposure, name, keywordType, serviceName, tempSave, joinDate } = keywordData.result;
      onChangeKeywordType(keywordType);
      setKeywordInput(name);
      setServiceName(serviceName);
      setToggled(exposure);
      setStatus(tempSave);
      setJoinDate(joinDate);
    }
  }, [keywordData]);

  // 취소 버튼 - 취소 모달
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const onClickCancel = () => {
    setIsCancelModalVisible(true);
  };

  // 취소 alert 모달 안 버튼 클릭 이벤트
  const onClickCancelModal = () => {
    navigate(`/resource/keyword?page=1&size=20&direction=DESC&keywordType=${keywordType}`);
    setIsCancelModalVisible(false);
  };

  const onClickSaveModal = () => {
    setIsCancelModalVisible(false);
    navigate(`/resource/keyword?page=1&size=20&direction=DESC&keywordType=${keywordType}`);
  };

  const isEditPage = Boolean(params && keywordData);
  const isFileDisabled = keywordType === "BUSINESS" || keywordType === "PRODUCT";
  const fetchData = async (tempSave = false) => {
    const data = {
      keywordType, //dropdown 값으로 변경
      name: keywordInput,
      serviceName,
      exposure: toggled,
      tempSave,
    };

    const formData = new FormData();

    const req = new Blob([JSON.stringify(data)], { type: "application/json" });

    formData.append("req", req);

    if (file && !isFileDisabled) {
      formData.append("keywordImage", file);
    }

    try {
      // patch와 post 분기 처리 필요

      const res = isEditPage ? await editKeyword(params?.id, formData) : await addKeyword(formData);

      if (res.status === 200) {
        navigate(`/resource/keyword?page=1&size=20&direction=DESC&keywordType=${keywordType}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 임시 저장 버튼
  const onClickTempSave = (e) => {
    fetchData(true);
  };

  const onConfirmSaveModal = async () => {
    await fetchData(false);
    setIsSaveModalVisible(false);
  };

  const onSubmit = async (e, tempSave = false) => {
    e.preventDefault();

    if (tempSave === false) {
      return setIsSaveModalVisible(true);
    } else {
      fetchData(tempSave);
    }
  };
  // imgSrc

  useEffect(() => {
    console.log(keywordType);
  }, [keywordType]);
  return (
    <>
      <Layout defaultOpenKeys={["sub2"]} defaultMenu={["5"]}>
        <CustomBreadcrumb item={breadcrumbItem} />
        <PageHeading>키워드 {params?.id ? "편집" : "등록"}</PageHeading>
        <SubHeading>키워드 정보</SubHeading>
        <form onSubmit={onSubmit}>
          <Table border="0">
            <tbody>
              {isEditPage && (
                <Tr>
                  <RowThTd th="상태">
                    <div>{status ? "임시저장" : "등록"}</div>
                  </RowThTd>
                </Tr>
              )}

              <Tr>
                <RowThTd th="구분">
                  <CustomSelect optionList={keywordTypeOption} placeholder="키워드 유형 선택" onChange={onChangeKeywordType} value={keywordType} disabled={params?.id} />
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="키워드">
                  <Input
                    placeholder="키워드를 입력하세요."
                    maxLength="8"
                    id="resource-keyword"
                    value={keywordInput}
                    onChange={onChangeKeywordInput}
                    hasDelBtn
                    onClickInputDelete={onClickInputDelete}
                  />
                  <ImageHeading>이미지</ImageHeading>
                  <FileUpload id="keyword-file" file={file} setFile={setFile} imgSrc={keywordData?.result?.keywordImage} disabled={isFileDisabled} keywordType={keywordType} />
                  <div>
                    <HelperText>* 한 장당 10MB 이하, 최대 1장 / jpg(jpeg),png, gif</HelperText>
                    <HelperText>* 비즈니스, 프로덕트 키워드는 이미지를 삽입할 수 없습니다.</HelperText>
                  </div>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="서비스 명">
                  <Input
                    placeholder="서비스 명을 입력하세요."
                    id="service-name"
                    value={serviceName}
                    maxLength="8"
                    onChange={onChangeServiceName}
                    hasDelBtn
                    onClickInputDelete={onClickServiceInputDelete}
                    disabled={isFileDisabled}
                  />
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="노출">
                  <ToggleBtn id="keyword-show" onToggle={onToggleShow} isBig showing={toggled} />
                  <HelperText>* 노출하지 않으면 사용자에게 표시되지 않습니다.</HelperText>
                </RowThTd>
              </Tr>
              <Tr>
                <RowThTd th="등록일">
                  <div>{joinDate}</div>
                </RowThTd>
              </Tr>
            </tbody>
          </Table>
          <BtnWrapper>
            <TempSaveBtn type="button" onClick={(e) => onSubmit(e, true)}>
              임시 저장
            </TempSaveBtn>
            <div>
              <CancelConfirmBtn onClickCancel={onClickCancel} confirmText={isEditPage ? "수정" : "저장"} />
            </div>
          </BtnWrapper>
        </form>
      </Layout>
      {isCancelModalVisible && (
        <Modal isVisible={isCancelModalVisible}>
          <AlertModalContent onClickCancel={onClickCancelModal} onClickSave={onClickSaveModal} confrimText={"확인"}>
            <h2>정말 취소하시겠습니까?</h2>
            <p>
              저장 전 취소 시 입력한 데이터가 <br />
              모두 삭제됩니다.
            </p>
            <p>그래도 취소하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}

      {isSaveModalVisible && (
        <Modal isVisible={isSaveModalVisible}>
          <AlertModalContent onClickCancel={onCancelSaveModal} onClickSave={onConfirmSaveModal}>
            <h2>키워드를 등록하시겠습니까?</h2>
            <p>
              대표님들에게 새로운 키워드를 <br />
              제공할 준비를 마쳤습니다.
            </p>
            <p>지금 바로 제공하시겠습니까?</p>
          </AlertModalContent>
        </Modal>
      )}
    </>
  );
};

export default KeywordRegister;
