import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useRecoilState } from "recoil";
import { recoilExpertState } from "states/recoilExpertState";
import { recoilExpertTabKeyState } from "states/recoilExpertTabKeyState";
import Layout from "layouts/Layout";
import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import Pagination from "components/common/Pagination";
import { PageHeading, RegisterLink, TabBtnList, TabBtn, ResultWrapper, FlexWrapper, BtnTd } from "./styles";
import { qsParses, qsStringify } from "utils/queryHandler";
import DeleteBtn from "components/common/DeleteBtn";
import EditLink from "components/common/EditLink";
import Modal from "components/common/Modal";
import AlertModalContent from "components/common/AlertModalContent";
import { deleteAdmin } from "api/resource/admin";

const Admin = () => {
  // 검색 선택

  // TODO : 백엔드 연결 시 사용
  // 상세보기 고고
  let navigate = useNavigate();
  const role = window.localStorage.getItem("superAdmin");

  //-------------------------------

  // 현재 tab 관리
  const [key, setKey] = useRecoilState(recoilExpertTabKeyState);
  const [currentTab, setCurrentTab] = useState(key.key);

  // 현재 페이지 관리
  const [currentPage, setCurrentPage] = useState(1);

  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  // TODO: 프로젝트 진행 단계 params 빠짐
  const { search } = useLocation();

  const {
    data: adminList,
    error: adminListError,
    mutate,
  } = useSWR(`/api/v1/super/admins?page=${currentPage}`, (url) =>
    fetcherPrivate(url, { params: search ? { ...qsParses(search), expertType: currentTab } : { page: 1, size: 20, direction: "DESC", experType: currentTab } }, { revalidateOnFocus: false })
  );

  useEffect(() => {
    mutate();
  }, [search, currentTab]);

  // 삭제 버튼 클릭 시 나타나는 alert 모달
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const [targetDelId, setTargetDelId] = useState("");
  const [targetDelEmail, setTargetDelEmail] = useState("");

  const onClickDelBtn = (id, email) => {
    setIsDelModalVisible(true);
    setTargetDelEmail(email);
    setTargetDelId(id);
  };

  // 삭제 alert 모달의 취소, 확인
  const onClickDelCancel = () => {
    setIsDelModalVisible(false);
  };

  const onClickDelConfirm = async () => {
    setIsDelModalVisible(false);
    try {
      const res = await deleteAdmin(targetDelId);
      if (res) {
        await mutate();
        setIsDelModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout defaultMenu={["10"]}>
      <PageHeading>관리자 계정 관리</PageHeading>
      <div>
        <RegisterLink to="/admin/register">계정 생성</RegisterLink>
        {adminList?.result && (
          <>
            <ResultWrapper>
              <h4>
                검색 결과 총 <strong>{adminList?.result?.totalElements ?? 0}</strong>명
              </h4>
              {/* TODO */}
            </ResultWrapper>

            <Table>
              <Thead>
                <tr>
                  <th>이메일</th>
                  <th style={{ width: "200px" }}>등록일</th>
                </tr>
              </Thead>
              <Tbody>
                {adminList?.result?.content?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                    <td>
                      <BtnTd>
                        <p>{item.joinDate}</p>
                        <FlexWrapper>
                          <EditLink to={`/admin/edit/${item.uid}`} />
                          <DeleteBtn
                            onClick={() => {
                              onClickDelBtn(item.uid, item.email);
                            }}
                          />
                        </FlexWrapper>
                      </BtnTd>
                    </td>
                  </tr>
                ))}
              </Tbody>
            </Table>
            {isDelModalVisible && (
              <Modal isVisible={isDelModalVisible}>
                <AlertModalContent onClickCancel={onClickDelCancel} onClickSave={onClickDelConfirm}>
                  <h2>{targetDelEmail}</h2>
                  <p>관리자 계정을 삭제하시겠습니까?</p>
                  <br />
                  <br />
                </AlertModalContent>
              </Modal>
            )}
            {adminList?.result ? <Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={adminList?.result?.totalElements} pageSize={20} /> : null}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Admin;
