import mock_bench_icon from "assets/mock_bench_icon.svg";
import avatar_manager from "assets/avatar_manager.svg";
import mock_manager_img from "assets/mock_manager_img.svg";
import mock_keyword_image from "assets/mock_keyword_image.png";

// 홈 - 라인 그래프
export const lineMockData = [
  {
    id: "user",
    color: "hsla(107, 53%, 46%, 1)",
    data: [
      {
        x: "JAN",
        y: 124,
      },
      {
        x: "FEB",
        y: 29,
      },
      {
        x: "MAR",
        y: 283,
      },
      {
        x: "APR",
        y: 77,
      },
      {
        x: "MAY",
        y: 238,
      },
      {
        x: "JUN",
        y: 70,
      },
      {
        x: "JUL",
        y: 235,
      },
      {
        x: "AUG",
        y: 277,
      },
      {
        x: "SEP",
        y: 0,
      },
      {
        x: "OCT",
        y: 284,
      },
      {
        x: "NOV",
        y: 191,
      },
      {
        x: "DEC",
        y: 108,
      },
    ],
  },
];

export const bubbleMockData = {
  name: "benchmarking",
  children: [
    {
      name: "이커머스",
      amount: 5,
    },
    {
      name: "구독 서비스",
      amount: 10,
    },
    {
      name: "SNS",
      amount: 50,
    },
    {
      name: "중개 플랫폼",
      amount: 15,
    },
    {
      name: "여행",
      amount: 100,
    },
    {
      name: "반려동물",
      amount: 150,
    },
    {
      name: "커피필요해",
      amount: 555,
    },
  ],
};

// 벤치마킹 리소스 관리
export const benchMockData = [
  {
    id: 0,
    name: "마켓컬리",
    icon: mock_bench_icon,
    type: "admin",
    isToggled: true,
    createdAt: "2022.10.01",
  },
  {
    id: 1,
    name: "마켓컬리",
    icon: mock_bench_icon,
    type: "user",
    isToggled: false,
    createdAt: "2022.09.23",
  },
  {
    id: 2,
    name: "마켓컬리",
    icon: mock_bench_icon,
    type: "user",
    isToggled: true,
    createdAt: "2021.01.11",
  },
  {
    id: 3,
    name: "마켓컬리",
    icon: mock_bench_icon,
    type: "admin",
    isToggled: true,
    createdAt: "2020.01.11",
  },
  {
    id: 4,
    name: "마켓컬리",
    icon: mock_bench_icon,
    type: "admin",
    isToggled: false,
    createdAt: "2019.01.11",
  },
  {
    id: 5,
    name: "마켓컬리",
    icon: mock_bench_icon,
    type: "user",
    isToggled: false,
    createdAt: "2018.01.11",
  },
];

// 벤치마킹 키워드
export const benchmarkingKeywordsMockData = [
  {
    id: 0,
    text: "구독 플랫폼",
  },
  {
    id: 1,
    text: "구매 지원",
  },
  {
    id: 2,
    text: "구매 촉진",
  },
  {
    id: 3,
    text: "나",
  },
  {
    id: 4,
    text: "나다라",
  },
  {
    id: 5,
    text: "다라마",
  },
  {
    id: 6,
    text: "다다다",
  },
  {
    id: 7,
    text: "a",
  },
];

// 키워드 관리
export const resourceKeywordMockData = [
  {
    id: "string0",
    type: "비즈니스0",
    keyword: "구독 플랫폼",
    usage: "100,000,000",
    isShowing: true,
    createdAt: "2022.01.11",
    img: mock_keyword_image,
  },
  {
    id: "string1",
    type: "비즈니스1",
    keyword: "구독 플랫폼",
    usage: "100,000,000",
    isShowing: true,
    createdAt: "2022.01.11",
    img: mock_keyword_image,
  },
  {
    id: "string2",
    type: "비즈니스2",
    keyword: "구독 플랫폼",
    usage: "100,000,000",
    isShowing: true,
    createdAt: "2022.01.11",
    img: mock_keyword_image,
  },
  {
    id: "string3",
    type: "비즈니스3",
    keyword: "구독 플랫폼",
    usage: "100,000,000",
    isShowing: true,
    createdAt: "2022.01.11",
    img: mock_keyword_image,
  },
  {
    id: "string4",
    type: "비즈니스4",
    keyword: "구독 플랫폼",
    usage: "100,000,000",
    isShowing: true,
    createdAt: "2022.01.11",
    img: mock_keyword_image,
  },
  {
    id: "string5",
    type: "비즈니스5",
    keyword: "구독 플랫폼",
    usage: "100,000,000",
    isShowing: true,
    createdAt: "2022.01.11",
    img: mock_keyword_image,
  },
];

// 템플릿
export const templateMockData = [
  {
    id: 0,
    type: "정보 흐름형0",
    name: "퍼블리",
    description: "전문적 콘텐츠 제작과 유료 이용을 지원하는 오픈 소스 플랫폼",
    img: mock_keyword_image,
    isShowing: true,
    createdAt: "2022.01.11",
  },
  {
    id: 1,
    type: "정보 흐름형1",
    name: "퍼블리",
    description: "전문적 콘텐츠 제작과 유료 이용을 지원하는 오픈 소스 플랫폼",
    img: mock_keyword_image,
    isShowing: true,
    createdAt: "2022.01.11",
  },
  {
    id: 2,
    type: "정보 흐름형2",
    name: "퍼블리",
    description: "전문적 콘텐츠 제작과 유료 이용을 지원하는 오픈 소스 플랫폼",
    img: mock_keyword_image,
    isShowing: true,
    createdAt: "2022.01.11",
  },
  {
    id: 3,
    type: "정보 흐름형3",
    name: "퍼블리",
    description: "전문적 콘텐츠 제작과 유료 이용을 지원하는 오픈 소스 플랫폼",
    img: mock_keyword_image,
    isShowing: true,
    createdAt: "2022.01.11",
  },
  {
    id: 4,
    type: "정보 흐름형4",
    name: "퍼블리",
    description: "전문적 콘텐츠 제작과 유료 이용을 지원하는 오픈 소스 플랫폼",
    img: mock_keyword_image,
    isShowing: true,
    createdAt: "2022.01.11",
  },
];
// 회원 관리
export const userMockData = [
  {
    id: 0,
    email: "emailemail@olivestonelab.com",
    userName: "김대표",
    companyName: "ABC 스타트업",
    phone: "012-345-6789",
    plan: "무료",
    createdAt: "22.01.11. 00:00:00",
  },
  {
    id: 1,
    email: "emailemail@olivestonelab.com",
    userName: "김대표",
    companyName: "ABC 스타트업",
    phone: "012-345-6789",
    plan: "무료",
    createdAt: "22.01.11. 00:00:00",
  },
  {
    id: 2,
    email: "emailemail@olivestonelab.com",
    userName: "김대표",
    companyName: "ABC 스타트업",
    phone: "012-345-6789",
    plan: "무료",
    createdAt: "22.01.11. 00:00:00",
  },
  {
    id: 3,
    email: "emailemail@olivestonelab.com",
    userName: "김대표",
    companyName: "ABC 스타트업",
    phone: "012-345-6789",
    plan: "무료",
    createdAt: "22.01.11. 00:00:00",
  },
  {
    id: 4,
    email: "emailemail@olivestonelab.com",
    userName: "김대표",
    companyName: "ABC 스타트업",
    phone: "012-345-6789",
    plan: "무료",
    createdAt: "22.01.11. 00:00:00",
  },
];

export const companyMockData = [
  {
    id: 0,
    companyName: "ABC 스타트업",
    companyType: "스타트업",
    investLevel: "Seed",
    mainInvestor: "Olive VC",
    businessArea: "레저/여행",
    teamSize: "5인 이하",
  },
];

// 회원 관리 상세
export const userProjectArr = [
  {
    id: 0,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    version: 1,
    userName: "김대표",
    companyName: "ABC 스타트업",
    progress: "분석",
    experts: [],
    benchmarking: 3,
    startDate: "2022.06.17",
    endDate: "2022.06.30",
  },
  {
    id: 1,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    version: 1,
    userName: "김대표",
    companyName: "ABC 스타트업",
    progress: "분석",
    experts: [],
    benchmarking: 3,
    startDate: "2022.06.17",
    endDate: "2022.06.30",
  },
  {
    id: 2,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    version: 1,
    userName: "김대표",
    companyName: "ABC 스타트업",
    progress: "분석",
    experts: [],
    benchmarking: 3,
    startDate: "2022.06.17",
    endDate: "2022.06.30",
  },
  {
    id: 3,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    version: 1,
    userName: "김대표",
    companyName: "ABC 스타트업",
    progress: "분석",
    experts: [],
    benchmarking: 3,
    startDate: "2022.06.17",
    endDate: "2022.06.30",
  },
  {
    id: 4,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    version: 1,
    userName: "김대표",
    companyName: "ABC 스타트업",
    progress: "분석",
    experts: [],
    benchmarking: 3,
    startDate: "2022.06.17",
    endDate: "2022.06.30",
  },
  {
    id: 5,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    version: 1,
    userName: "김대표",
    companyName: "ABC 스타트업",
    progress: "분석",
    experts: [],
    benchmarking: 3,
    startDate: "2022.06.17",
    endDate: "2022.06.30",
  },
];

// 전문가 관리 > 각 전문가 프로젝트 데이터
export const expertProjectArr = [
  {
    id: 0,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    version: 1,
    userName: "김대표",
    companyName: "ABC 스타트업",
    startDate: "2022.06.17",
    endDate: "",
  },
  {
    id: 1,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "stopped",
    version: 1,
    userName: "냥표",
    companyName: "ABC 스타트업",
    startDate: "2022.06.17",
    endDate: "2022.09.11",
  },
  {
    id: 2,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "completed",
    version: 1,
    userName: "대대뎃대표",
    companyName: "ABC 스타트업",
    startDate: "2022.06.17",
    endDate: "2022.10.10",
  },
];

// 전문가 관리 > 전문가 전체 데이터
export const managerArr = [
  {
    id: 0,
    email: "olivetreeeeee@stone.com",
    image: mock_manager_img,
    type: "manager",
    name: "김땡부",
    nickname: "oooh",
    phone: "010-123-4567",
    projectTotal: 5,
    createdAt: "2022.01.11",
    intro: "안녕하세요",
    projects: expertProjectArr,
  },
  {
    id: 4,
    email: "olive@stone.com",
    image: mock_manager_img,
    type: "manager",
    name: "길고길고긴이름이있을수있죠",
    nickname: "oooh",
    phone: "010-123-4567",
    projectTotal: 5,
    createdAt: "2022.01.11",
    intro: "짠!",
    projects: expertProjectArr,
  },
];
export const designerArr = [
  {
    id: 1,
    email: "olive@stone.com",
    image: mock_manager_img,
    type: "designer",
    name: "땡부킴",
    nickname: "oooh",
    phone: "010-123-4567",
    projectTotal: 5,
    createdAt: "2022.01.11",
    intro: "-",
    projects: expertProjectArr,
  },
  {
    id: 2,
    email: "oliveeeeeeee@stone.com",
    image: mock_manager_img,
    type: "designer",
    name: "짧",
    nickname: "s",
    phone: "010-123-4567",
    projectTotal: 5,
    createdAt: "2022.01.11",
    intro: "안녕하세요",
    projects: expertProjectArr,
  },
];
export const mentorArr = [
  {
    id: 3,
    email: "oliveolive@stone.com",
    image: mock_manager_img,
    type: "mentor",
    name: "길고길고긴이름도있을수있죠",
    nickname: "oooh",
    phone: "010-123-4567",
    project: 5,
    createdAt: "2022.01.11",
    intro: "",
    projects: expertProjectArr,
  },
];

// 전문가 관리 > 전문가 상세. 프로젝트 mock data

export const projectMockData = {
  id: 0,
  version: 1,
  projectName: "어린이 교구 구독 플랫폼 아이랜드",
  userName: "김대표",
  companyName: "ABC 스타트업",
  businessType: "구독 플랫폼",
  progress: "분석",
  type: "Mobile",
  productType: "교육, 유아",
  startAt: "22.05.01. 00:00:00",
  endAt: "-",
  expert: [
    {
      id: 0,
      image: avatar_manager,
      name: "김올브",
      email: "email@email.com",
      type: "manager",
    },
    {
      id: 1,
      image: avatar_manager,
      name: "올뿌킴",
      email: "design@email.com",
      type: "designer",
    },
  ],
};

// 전문가 관리 > 전문가 상세. 프로젝트 메칭 mock data

export const projectMatchingMockData = [
  {
    id: 0,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    userName: "김대표",
    companyName: "ABC 스타트업",
    version: 1,
    startAt: "22.05.01. 00:00:00",
    endAt: "22.05.01. 00:00:00",
  },
  {
    id: 1,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    userName: "김대표",
    companyName: "ABC 스타트업업업업",
    version: 1,
    startAt: "22.05.01. 00:00:00",
    endAt: "22.05.01. 00:00:00",
  },
  {
    id: 2,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    userName: "김대표",
    companyName: "ABC 스타트업",
    version: 1,
    startAt: "22.05.01. 00:00:00",
    endAt: "22.05.01. 00:00:00",
  },
  {
    id: 3,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    userName: "김대표",
    companyName: "ABC 스타트업",
    version: 1,
    startAt: "22.05.01. 00:00:00",
    endAt: "-",
  },
  {
    id: 4,
    projectName: "어린이 교구 구독 플랫폼 아이랜드",
    status: "ongoing",
    userName: "김대표",
    companyName: "ABC 스타트업",
    version: 1,
    startAt: "22.05.01. 00:00:00",
    endAt: "22.05.01. 00:00:00",
  },
  {
    id: 5,
    projectName: "어린이",
    status: "ongoing",
    userName: "김대표",
    companyName: "ABC 스타트업",
    version: 1,
    startAt: "22.05.01. 00:00:00",
    endAt: "-",
  },
];
