import { useRef, useEffect } from "react";
import { Tooltip } from "./styles";
const TableSortTooltip = ({ isTooltipVisible, setIsTooltipVisible, children }) => {
  const timer = useRef(null);

  const hideTooltip = () => {
    timer.current = setTimeout(() => {
      setIsTooltipVisible(false);
    }, 2000);
  };

  const triggerHideTooltip = () => {
    if (!isTooltipVisible) return;
    if (isTooltipVisible) hideTooltip();
  };

  useEffect(() => {
    triggerHideTooltip();
    return () => {
      clearTimeout(timer.current);
    };
  }, [isTooltipVisible]);

  return <Tooltip isVisible={isTooltipVisible}>{children}</Tooltip>;
};

export default TableSortTooltip;
