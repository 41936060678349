import { useState, useEffect } from "react";
import moment from "moment";
import Layout from "layouts/Layout";
import SearchBox from "components/common/SearchBox";
import ExcelBtn from "components/common/ExcelBtn";
import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import EditLink from "components/common/EditLink";
import DetailBtn from "components/common/DetailBtn";
import Pagination from "components/common/Pagination";
import TableSortTooltip from "components/common/TableSortTooltip";
import { EditOutlined, DownOutlined } from "@ant-design/icons";
import { projectSearchDateType, projectSearchOption } from "utils/data";
import { benchSearchOption } from "utils/data";
import { getSortingCriteria } from "utils/getSortingCriteria";
import { PageHeading, ResultWrapper, SortBtnWrapper } from "./styles";
import { utils, writeFileXLSX } from "xlsx";
import { Box } from "@material-ui/core";
// TODO - delete
import { userProjectArr } from "utils/mockData";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useLocation, useNavigate } from "react-router-dom";
import { qsParses } from "utils/queryHandler";
//import ResourceModal from "components/common/project/ResourceModal";
import { ResourceDialog } from "components/common/ResourceModal/ResourceDialog";

const List = () => {
  const base_url = window.location.origin;
  const url = "http://" + window.location.host.split(":")[0];

  const { search } = useLocation();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [currentId, setCurrentId] = useState("");
  const [resultData, setResultData] = useState([]);
  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const role = window.localStorage.getItem("superAdmin");

  // const {
  //   data: projectLists,
  //   error: projectListsError,
  //   mutate,
  // } = useSWR(`/api/v1/admin/experts?page=${currentPage}`, (url) => fetcherPrivate(url, { params: search ? { ...qsParses(search) } : { page: 1, size: 20, direction: "DESC" } }));

  const {
    data: projectLists,
    error: projectListsError,
    mutate,
  } = useSWR(`/api/v1/admin/projects?page=${currentPage}`, (url) =>
    fetcherPrivate(url, { params: search ? { ...qsParses(search) } : { page: 1, size: 20, direction: "ASC" } }, { revalidateOnFocus: false })
  );

  let navigate = useNavigate();

  useEffect(() => {
    projectLists && setResultData(projectLists.result.content);
  }, [projectLists]);

  useEffect(() => {
    if (search) {
      mutate();

      const { page } = qsParses(search);

      if (page) setCurrentPage(+page);
    }
  }, [search]);

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(userProjectArr);
  }, []);

  // 엑셀 버튼 클릭
  const onClickExcel = async () => {
    try {
      let parseSearch = qsParses(search);

      delete parseSearch.size;
      delete parseSearch.page;

      const totalCount = projectLists?.result?.totalElements;

      // 엑셀 다운로드 api 작동하지 않아 기본 리스트 api 사용
      const res = await fetcherPrivate(`/api/v1/admin/projects?page=1`, {
        params: parseSearch ? { ...parseSearch, projectSearchDateType: "START", size: totalCount } : { direction: "ASC", size: totalCount, projectSearchDateType: "START" },
      });

      if (res?.result?.content?.length > 0) {
        const newList = res.result.content.map((item) => {
          const status = item.projectStatusType === "PROCEEDING" ? "진행 중" : item.projectStatusType === "STOP" ? "진행 중단" : "진행 완료";
          return {
            name: item.name,
            status,
            version: item.version,
            memberName: item.memberName,
            companyName: item.companyName,
            step: item.projectStepType,
            expertManagerCount: item.expertManagerCount,
            expertMentorCount: item.expertMentorCount,
            expertDesignerCount: item.expertDesignerCount,
            benchmarkingCount: item.benchmarkingCount,
            projectStartDate: item.projectStartDate,
            projectEndDate: item.projectEndDate,
          };
        });
        const ws = utils.json_to_sheet(newList);
        const wb = utils.book_new();
        utils.sheet_add_aoa(ws, [["프로젝트 명", "상태", "버전", "회원 명", "기업 명", "진행 단계", "매니저 수", "멘토 수", "디자이너 수", "벤치마킹 리소스 개수", "시작일", "종료일"]], {
          origin: "A1",
        });
        utils.book_append_sheet(wb, ws, "ProjectList");
        writeFileXLSX(wb, "ProjectList.xlsx");
      }
    } catch (e) {
      console.log(e);
      window.alert("엑셀 다운로드에 실패했습니다. 개발팀에 문의해주세요!");
    }
  };

  // 상세보기 버튼 클릭
  const onClickDetailBtn = (id) => {
    if (url === "http://toolkit-admin.i2bcampus.com") {
      window.open(`https://toolkit.i2bcampus.com/ViewModeInit?viewMode=true&id=${id}`);
    } else if (url === "http://swrnd.olivestonelab.com") {
      window.open(`http://dev-toolkit.i2bcampus.com/ViewModeInit?viewMode=true&id=${id}`);
    } else {
      window.open(`http://localhost:3001/ViewModeInit?viewMode=true&id=${id}`);
    }
  };

  const onClickdDialogBtn = (id) => {
    setIsResourceModal(true);
    setCurrentId(id);
  };

  const [isResourceModal, setIsResourceModal] = useState(false);
  const onClickCloseMatchingModal = () => setIsResourceModal(false);

  // 테이블 - 등록 상태별 정렬
  const statusSortArr = ["default", "PROCEEDING", "COMPLETION", "STOP"];
  const [statusSortType, setStatusSortType] = useState("default");
  const [isStatusTooltipVisible, setIsStatusTooltipVisible] = useState(false);
  const onClickStatusTypeHead = () => {
    const nextSortType = getSortingCriteria(statusSortType, statusSortArr);
    setStatusSortType(nextSortType);
    setIsStatusTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };
  useEffect(() => {
    if (projectLists) {
      if (statusSortType === "default") {
        setResultData(projectLists.result.content);
      } else {
        const result = projectLists.result.content.filter((person) => person.projectStatusType.toString().includes(statusSortType.toLocaleUpperCase()));
        setResultData(result);
      }
    }
  }, [statusSortType]);

  // 테이블 - 등록 버젼 정렬
  const versionSortArr = ["default", "low", "high"];
  const [versionSortType, setVersionSortType] = useState("default");
  const [isVersionTooltipVisible, setIsVersionTooltipVisible] = useState(false);
  const onClickVersionTypeHead = () => {
    const nextSortType = getSortingCriteria(versionSortType, versionSortArr);
    setVersionSortType(nextSortType);
    setIsVersionTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };
  useEffect(() => {
    if (projectLists) {
      if (versionSortType === "default") {
        let temp = [...projectLists.result.content];
        let result = temp.sort((a, b) => a.version - b.version);
        setResultData(result);
      } else if (versionSortType === "low") {
        let temp = [...resultData];
        let result = temp.sort((a, b) => a.version - b.version);
        setResultData(result);
      } else {
        let temp = [...resultData];
        let result = temp.sort((a, b) => b.version - a.version);
        setResultData(result);
      }
    }
  }, [versionSortType]);

  // 테이블 - 등록일 순 정렬
  const dateSortArr = ["default", "latest", "oldest"];
  const [dateSortType, setDateSortType] = useState("default");
  const [isDateTooltipVisible, setIsDateTooltipVisible] = useState(false);
  const onClickDateHead = () => {
    const nextSortType = getSortingCriteria(dateSortType, dateSortArr);
    setDateSortType(nextSortType);
    setIsDateTooltipVisible(true);
    // TODO - 서버에 정렬 기준 적용한 데이터 요청
  };

  useEffect(() => {
    if (projectLists) {
      if (dateSortType === "default") {
        let temp = [...projectLists.result.content];
        let result = temp.sort((a, b) => new Date(b.projectStartDate) - new Date(a.projectStartDate));
        setResultData(result);
      } else if (dateSortType === "latest") {
        let temp = [...resultData];
        let result = temp.sort((a, b) => new Date(b.projectEndDate) - new Date(a.projectEndDate));
        setResultData(result);
      } else {
        let temp = [...resultData];
        let result = temp.sort((a, b) => new Date(a.projectEndDate) - new Date(b.projectEndDate));
        setResultData(result);
      }
    }
  }, [dateSortType]);

  return (
    <Layout defaultOpenKeys={["sub1"]} defaultMenu={["2"]}>
      <PageHeading>프로젝트 목록</PageHeading>

      <SearchBox optionList={projectSearchOption} page={currentPage} isTitle={false} moreOption={projectSearchDateType} />
      {projectLists && (
        <>
          <ResultWrapper>
            <h4>
              검색 결과 총 <strong>{projectLists?.result?.totalElements ?? 0}</strong>개
            </h4>
            <ExcelBtn onClick={onClickExcel} disabled={projectLists?.result?.totalElements === 0} />
          </ResultWrapper>

          <Table>
            <Thead>
              <tr>
                <th>프로젝트 명</th>
                <th style={{ width: "90px" }}>
                  <SortBtnWrapper>
                    <button type="button" onClick={onClickStatusTypeHead}>
                      상태&nbsp;&nbsp;
                      <DownOutlined style={{ fontSize: "10px" }} />
                    </button>
                    <TableSortTooltip isTooltipVisible={isStatusTooltipVisible} setIsTooltipVisible={setIsStatusTooltipVisible}>
                      {statusSortType === "PROCEEDING" ? "진행 중" : statusSortType === "COMPLETION" ? "진행 완료" : statusSortType === "STOP" ? "진행 중단" : "초기화"}
                    </TableSortTooltip>
                  </SortBtnWrapper>
                </th>
                <th style={{ width: "80px" }}>
                  <SortBtnWrapper>
                    <button type="button" onClick={onClickVersionTypeHead}>
                      버전&nbsp;&nbsp;
                      <DownOutlined style={{ fontSize: "10px" }} />
                    </button>
                    <TableSortTooltip isTooltipVisible={isVersionTooltipVisible} setIsTooltipVisible={setIsVersionTooltipVisible}>
                      {versionSortType === "low" ? "낮은 순" : versionSortType === "high" ? "높은 순" : "초기화"}
                    </TableSortTooltip>
                  </SortBtnWrapper>
                </th>
                <th style={{ width: "90px" }}>회원 명</th>
                <th>기업 명</th>
                <th style={{ width: "140px" }}>기관 명</th>
                <th>진행 단계</th>
                <th>전문가</th>
                <th style={{ width: "100px" }}>벤치마킹 리소스</th>
                <th style={{ width: "120px" }}>
                  <SortBtnWrapper>
                    <button type="button" onClick={onClickDateHead}>
                      프로젝트 시작일/종료일
                      <DownOutlined style={{ marginLeft: "2px", fontSize: "10px" }} />
                    </button>
                    <TableSortTooltip isTooltipVisible={isDateTooltipVisible} setIsTooltipVisible={setIsDateTooltipVisible}>
                      {dateSortType === "latest" ? "최신순" : dateSortType === "oldest" ? "지난 순" : "초기화"}
                    </TableSortTooltip>
                  </SortBtnWrapper>
                </th>
              </tr>
            </Thead>
            <Tbody>
              {resultData &&
                resultData.map((project) => (
                  <tr key={project.id}>
                    <td>{project.name}</td>
                    <td>{project.projectStatusType == "STOP" ? "진행 중단" : project.projectStatusType == "COMPLETION" ? "진행 완료" : "진행 중"}</td>
                    <td>{project.version}</td>
                    <td>{project.memberName}</td>
                    <td>{project.companyName}</td>
                    <td>{project.authCodeCompanyName}</td>

                    {/* 분석 : ANALYSIS / 컨셉 : CONCEPT / 제작 : PRODUCE / 테스트 : PROJECT_TEST / 리뷰 : REVIEW  */}
                    <td>
                      {project.projectStepType == "ANALYSIS"
                        ? "분석"
                        : project.projectStepType == "CONCEPT"
                        ? "컨셉"
                        : project.projectStepType == "PRODUCE"
                        ? "제작"
                        : project.projectStepType == "PROJECT_TEST"
                        ? "테스트"
                        : project.projectStepType == "REVIEW"
                        ? "리뷰"
                        : ""}
                    </td>
                    <td>
                      매니저 {project.expertManagerCount}, 디자이너 {project.expertDesignerCount}, 멘토 {project.expertMentorCount}
                    </td>

                    <td className="with-btn">
                      <div>
                        <p> {project.benchmarking}</p>
                        {/* TODO : 링크 수정 */}
                        <button type="button" onClick={() => onClickdDialogBtn(project.id)}>
                          {role === "true" ? <EditOutlined style={{ fontSize: "14px" }} /> : ""}
                        </button>
                      </div>
                    </td>
                    <td className="with-btn">
                      <div>
                        <div>
                          <p>{project.projectStartDate}</p>
                          <p>{project.projectEndDate}</p>
                        </div>
                        {role === "true" ? <DetailBtn onClick={() => onClickDetailBtn(project.id)} /> : ""}
                      </div>
                    </td>
                  </tr>
                ))}
            </Tbody>
          </Table>
        </>
      )}
      {isResourceModal && <ResourceDialog setResourceDialogState={setIsResourceModal} resourceDialogState={isResourceModal} currentId={currentId} />}
      {projectLists?.result ? <Pagination defaultCurrent={1} current={currentPage} onChange={onChangeCurrentPage} total={projectLists?.result?.totalElements} pageSize={20} /> : null}
    </Layout>
  );
};

export default List;
