import styled from "styled-components";
import { fontStyle12 } from "styles/mixins";

export const Button = styled.button`
  ${fontStyle12};
  font-weight: 500;

  :hover {
    color: ${(props) => props.theme.black};
  }
`;
