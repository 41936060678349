import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import useSWR from "swr";
import { fetcherPrivate } from "utils/fetcher";
import { useRecoilState } from "recoil";
import { recoilExpertState } from "states/recoilExpertState";
import { recoilExpertTabKeyState } from "states/recoilExpertTabKeyState";
import Layout from "layouts/Layout";
import SearchBox from "components/common/SearchBox";
import Table from "components/common/BasicTable/Table";
import Thead from "components/common/BasicTable/Thead";
import Tbody from "components/common/BasicTable/Tbody";
import ExcelBtn from "components/common/ExcelBtn";
import Pagination from "components/common/Pagination";
import DetailLink from "components/common/DetailLink";
import { expertSearchOption, expertTypeOption } from "utils/data";
import {
  PageHeading,
  RegisterLink,
  TabBtnList,
  TabBtn,
  ResultWrapper,
  FlexWrapper,
} from "./styles";
import { qsParses, qsStringify } from "utils/queryHandler";
import { ImageTd } from "components/common/SortableTable/SortableRow/styles";
import { utils, writeFileXLSX } from "xlsx";

import Designer from "assets/ic_default_disigner_big.jpg";
import Manager from "assets/ic_default_manager_big.jpg";
import Mentor from "assets/ic_default_mento_big.jpg";

const Expert = () => {
  // 검색 선택

  // TODO : 백엔드 연결 시 사용
  // 상세보기 고고
  const [selectedExpert, setSelectedExpert] = useRecoilState(recoilExpertState);
  let navigate = useNavigate();
  const role = window.localStorage.getItem("superAdmin");
  // 상세보기 버튼 클릭
  const onClickDetailBtn = (id) => {
    setSelectedExpert((prev) => {
      const oldState = { ...prev };
      oldState.id = id;
      return { ...oldState };
    });
    navigate(`/expert/detail/${id}`);
  };

  //-------------------------------

  // 현재 tab 관리
  const [key, setKey] = useRecoilState(recoilExpertTabKeyState);
  const [currentTab, setCurrentTab] = useState(key.key);
  const onClickTab = (key) => {
    setCurrentTab(key);
    setCurrentPage(1);

    setKey((prev) => {
      const prevState = { ...prev };
      prevState.key = key;
      return { ...prevState };
    });

    navigate(
      `?${qsStringify(
        {
          ...qsParses(search),
          expertType: key,
          name: null,
          nick: null,
          phone: null,
          allSearch: null,
          sDate: null,
          eDate: null,
        },
        { skipNulls: true, strictNullHandling: true }
      )}`
    );
  };

  // 현재 페이지 관리
  const [currentPage, setCurrentPage] = useState(1);

  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  // TODO: 프로젝트 진행 단계 params 빠짐
  const { search } = useLocation();

  const {
    data: expertList,
    error: expertListError,
    mutate,
  } = useSWR(`/api/v1/admin/experts?page=${currentPage}`, (url) =>
    fetcherPrivate(
      url,
      {
        params: search
          ? { ...qsParses(search), expertType: currentTab }
          : { page: 1, size: 20, direction: "DESC", experType: currentTab },
      },
      { revalidateOnFocus: false }
    )
  );

  console.log(expertList, "리스트");
  useEffect(() => {
    mutate();
  }, [search, currentTab]);

  // 엑셀 버튼 클릭

  const onClickExcel = async () => {
    try {
      let parseSearch = qsParses(search);

      delete parseSearch.size;
      delete parseSearch.page;

      // 엑셀 다운로드 api 작동하지 않아 기본 리스트 api 사용
      const totalCount = expertList?.result?.totalElements;
      // const res = await fetcherPrivate(`/api/v1/admin/experts?expertType=${currentTab}&page=1&size=${expertList?.result.totalElements}&direction=DESC`);
      const res = await fetcherPrivate(
        `/api/v1/admin/experts?page=1&size=${totalCount}`,
        {
          params: parseSearch ? { ...parseSearch } : { direction: "ASC" },
        }
      );
      if (res?.result?.content?.length > 0) {
        const newList = res.result.content.map((item) => {
          const expertType =
            item.expertType === "DESIGNER"
              ? "디자이너"
              : item.expertType === "MENTOR"
              ? "멘토"
              : "매니저";
          return {
            expertType,
            email: item.email,
            name: item.name,
            nick: item.nick,
            phone: item.phone,
            participateProjectCount: item.participateProjectCount,
            joinDate: item.joinDate,
          };
        });
        const ws = utils.json_to_sheet(newList);
        const wb = utils.book_new();
        utils.sheet_add_aoa(
          ws,
          [
            [
              "전문가 유형",
              "이메일",
              "이름",
              "닉네임",
              "연락처",
              "프로젝트 진행",
              "등록일",
            ],
          ],
          {
            origin: "A1",
          }
        );
        utils.book_append_sheet(wb, ws, `${currentTab}_LIST`);
        writeFileXLSX(wb, `${currentTab}_LIST.xlsx`);
      }
    } catch (e) {
      console.log(e);
      window.alert("엑셀 다운로드에 실패했습니다. 개발팀에 문의해주세요!");
    }
  };

  return (
    <Layout defaultMenu={["8"]}>
      <PageHeading>전문가 관리</PageHeading>
      <TabBtnList>
        {expertTypeOption.map((item, i) => (
          <li key={i}>
            <TabBtn
              type="button"
              onClick={() => onClickTab(item.value)}
              isCurrent={currentTab === item.value}>
              {item.text}
            </TabBtn>
          </li>
        ))}
      </TabBtnList>

      <div>
        {role === "true" ? (
          <RegisterLink to="/expert/register">등록</RegisterLink>
        ) : (
          <div style={{ marginTop: "40px" }}></div>
        )}
        <SearchBox optionList={expertSearchOption} page={currentPage} />
        {expertList?.result && (
          <>
            <ResultWrapper>
              <h4>
                검색 결과 총{" "}
                <strong>{expertList?.result?.totalElements ?? 0}</strong>명
              </h4>
              {/* TODO */}
              <ExcelBtn
                onClick={onClickExcel}
                name="전문가 목록"
                disabled={expertList?.result?.totalElements === 0}
              />
            </ResultWrapper>

            <Table>
              <Thead>
                <tr>
                  <th style={{ width: "220px" }}>이메일</th>
                  <th style={{ width: "120px" }}>사진</th>
                  <th style={{ width: "120px" }}>이름</th>
                  <th style={{ width: "100px" }}>닉네임</th>
                  <th>연락처</th>
                  <th style={{ width: "120px" }}>프로젝트 진행</th>
                  <th style={{ width: "140px" }}>등록일</th>
                </tr>
              </Thead>
              <Tbody>
                {expertList?.result?.content?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                    <td>
                      <ImageTd>
                        <img
                          src={
                            item.expertHeadshot == null
                              ? item.expertType === "DESIGNER"
                                ? Designer
                                : item.expertType === "MANAGER"
                                ? Manager
                                : Mentor
                              : item.expertHeadshot
                          }
                          alt="expert image"
                        />
                      </ImageTd>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.nick}</td>
                    <td>{item.phone}</td>
                    <td>
                      {item.participateProjectCount
                        ? item.participateProjectCount
                        : 0}
                    </td>
                    <td>
                      <FlexWrapper>
                        <div>{item.joinDate}</div>
                        {/* TODO : 추후에 버튼 사용 */}
                        {/* <DetailBtn onClick={() => onClickDetailBtn(item.id)} /> */}
                        <DetailLink
                          to={`/expert/detail/${item.uid}`}
                          state={item}
                        />
                      </FlexWrapper>
                    </td>
                  </tr>
                ))}
              </Tbody>
            </Table>

            {expertList?.result ? (
              <Pagination
                defaultCurrent={1}
                current={currentPage}
                onChange={onChangeCurrentPage}
                total={expertList?.result?.totalElements}
                pageSize={20}
              />
            ) : null}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Expert;
