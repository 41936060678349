import { StyledModal } from "./styles";

const Modal = ({ isVisible, children }) => {
  return (
    <StyledModal open={isVisible}>
      <div>{children}</div>
    </StyledModal>
  );
};

export default Modal;
